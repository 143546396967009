import {Typography} from "@/components/base/Typography";
import {useFormContext} from "react-hook-form";
import fp from "lodash/fp";
import {dataPickerDisplayFieldNameCreator} from "@/components/form/Select/utils";
import {Calendar} from "@/components/form/DatePicker/Calendar";
import React, {RefObject, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {DateTime} from "luxon";

import styles from './styles.module.scss';
import clsx, {ClassValue} from "clsx";
import {CalendarBlank} from "@/components/icons/CalendarBlank";
import {autoUpdate, flip, FloatingPortal, offset, shift, useFloating} from "@floating-ui/react";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import {dropdownFloatingPortalKey} from "@/utils/common/dropdown";
import i18next from "i18next";

interface IDatePickerProps {
    name: string
    label?: React.ReactNode
    required?: boolean
    disabled?: boolean
    customErrorPath?: string
    placeholder?: string
    maxDate?: DateTime
    minDate?: DateTime
    className?: ClassValue
}

export const DatePicker = (
    {
        name,
        label,
        required = false,
        placeholder = 'Укажите дату',
        disabled = false,
        customErrorPath,
        maxDate,
        minDate,
        className,
    }: IDatePickerProps,
) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const displayFieldName = useMemo(() => dataPickerDisplayFieldNameCreator(name), [name]);

    const displayNameRef = useRef<HTMLInputElement>(null);
    // const containerInputArea = useRef<HTMLDivElement>(null);
    // const dropContainerRef = useRef<HTMLDivElement>(null);

    const {formState, register, watch, setValue, getValues} = useFormContext();

    const inputReg = register(displayFieldName);

    useEffect(() => {
        if (getValues(displayFieldName) === '') {
            setValue(displayFieldName, getValues(name)?.toLocaleDateString(i18next.language))
        }
    }, [
        displayFieldName,
        getValues,
        name,
        setValue,
    ]);

    useEffect(() => {
        setValue(displayFieldName, getValues(name)?.toLocaleDateString(i18next.language))
    }, [
        displayFieldName,
        getValues,
        name,
        setValue,
    ]);

    useEffect(() => {
        return watch((values, {name: internalName}) => {
            if (internalName === name) {
                const value = fp.get(name, values) as Date;

                setValue(displayFieldName, value?.toLocaleDateString(i18next.language))
            }
        }).unsubscribe
    }, [
        displayFieldName,
        name,
        setValue,
        watch,
    ]);

    const registerDF = (ref: HTMLInputElement | null) => {
        inputReg.ref(ref)
        displayNameRef.current = ref;
    }

    const changeShowCalendar = useCallback((v: boolean) => {
        if (!disabled) {
            setShowCalendar(v)
        }
    }, [disabled])

    const open = () => changeShowCalendar(true);
    const close = () => changeShowCalendar(false);

    const error = fp.get(`errors.${customErrorPath || name}.message`, formState) as string;

    const {refs, floatingStyles} = useFloating<any>({
        open: showCalendar,
        onOpenChange: setShowCalendar,
        middleware: [
            offset(4),
            flip(),
            shift(),
        ],
        whileElementsMounted: autoUpdate,
        placement: 'bottom-start',
    });

    useOnClickOutside(
        () => changeShowCalendar(false),
        refs.floating,
        refs.reference as RefObject<HTMLElement>,
    )

    return (
        <div className={clsx(className, 'd-flex flex-column gap-2')}>
            {(label || required) && <Typography type='SmallTextRegular'>
                {label}
                {required && <Typography color='red-primary' as='span'>*</Typography>}
            </Typography>}
            <Typography
                onClick={open}
                type='SmallTextRegular'
                className={clsx(
                    'd-flex w-100 align-items-center',
                    styles.inputContainer,
                    showCalendar && styles.inputContainerOpen,
                )}
                ref={refs.setReference}
            >
                <input
                    {...inputReg}
                    ref={registerDF}
                    onFocus={open}
                    type="text"
                    readOnly
                    placeholder={placeholder}
                    className={clsx('w-100', styles.displayInput)}
                />
                <Typography as='span' color='inherit'>
                    <CalendarBlank/>
                </Typography>
            </Typography>
            {error && (
                <Typography type='SmallLabel' color='red-primary' as='span'>
                    {error}
                </Typography>
            )}
            <FloatingPortal id={dropdownFloatingPortalKey}>
                {showCalendar && (
                    <div
                        style={floatingStyles}
                        className={clsx(styles.dropContainer, 'd-flex')}
                        ref={refs.setFloating}
                    >
                        <Calendar name={name} close={close} maxDate={maxDate} minDate={minDate}/>
                    </div>
                )}
            </FloatingPortal>
        </div>
    )
}