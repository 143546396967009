import {FormProvider, useForm} from "react-hook-form";
import {logger} from "@/utils/logger";
import {Select} from "@/components/form/Select";
import {Input} from "@/components/form/Input";
import {DatePicker} from "@/components/form/DatePicker";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {Button} from "@/components/base/Button";
import {business} from "@/business";
import {Typography} from "@/components/base/Typography";
import {Highlighter} from "@/components/composite/Highlighter";
import {yup} from "@/utils/yup/common";
import i18next from "i18next";
import {yupResolver} from "@hookform/resolvers/yup";
import {useEffect} from "react";
import {asyncExecMetaSelector} from "@/business/common/selectors";
import fp from "lodash/fp";
import {useSubscribeError} from "@/utils/forms/hooks";
import {LoadingStatus} from "@/core/store/utils/sagas";


const schemaValidate = yup.object().shape({
    source_title: yup.string(),
    date_begin: yup.date()
        .required(() => i18next.t('common.errors.yup.common.incorrect')),
    shop_id: yup.string()
        .required(() => i18next.t('common.errors.yup.common.incorrect')),
    password: yup.string()
        .required(() => i18next.t('common.errors.yup.common.incorrect')),
    saldo: yup.number()
        .min(0, () => i18next.t('common.errors.yup.common.incorrect')),
    processing_begin_date: yup.date()
        .required(() => i18next.t('common.errors.yup.common.incorrect')),
})

export const OzonAuth = () => {
    const dp = useAppDispatch();

    const tokenCheckMeta = useAppSelector(
        asyncExecMetaSelector('sources/ozonCheckToken'),
        fp.isEqual,
    )

    const methods = useForm({
        resolver: yupResolver(schemaValidate),
        mode: 'onChange',
    });

    useSubscribeError(methods, 'password', tokenCheckMeta.message)

    useEffect(() => methods.watch((data, {name}) => {
        if (name === 'password' || name === 'shop_id') {
            if (!methods.getFieldState('shop_id').invalid && !methods.getFieldState('password').invalid) {
                dp(business.sources.actions.ozonCheckToken({
                    id: data.shop_id as string,
                    token: data.password as string,
                }))
            }
        }
    }).unsubscribe, [dp, methods]);

    const submit = methods.handleSubmit((d) => {
        logger.info(d);
    })

    return (
        <FormProvider {...methods}>
            <form onSubmit={submit} className='vstack gap-32'>
                <div className='vstack gap-24'>
                    <Select name='' label='С какого года учитывать операции'/>
                    <Input name='source_title' label='Название магазина'/>
                    <Input name='shop_id' label='ID магазина' required/>
                    <Input name='password' label='Ключ (токен подключения)' required/>
                    <DatePicker name='date_begin' label='Дата начала загрузки отчетов о реализации' required/>
                    <Input name='saldo' label='Сальдо'/>
                </div>
                <Typography color='base-additional-grey'>
                    <Highlighter
                        text='ID клиента и ключ для подключения через API необходимо сгенерировать в ЛК Ozon. [Инструкция →](/404)'/>
                </Typography>
                <div className='d-flex flex-column flex-md-row gap-8 gap-md-16'>
                    <Button
                        type='Secondary'
                        className='w-100'
                        onClick={() => dp(business.sources.actions.prevStep())}
                    >
                        ← Назад
                    </Button>
                    <Button className='w-100' htmlType='submit' disabled={tokenCheckMeta.status !== LoadingStatus.Loaded || !methods.formState.isValid}>
                        Подключить
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}