import {call, delay, Effect, put, select, takeEvery} from "redux-saga/effects";
import {sagaActions} from "@/business/common/inits";
import {slice} from "@/business/common/slice";
import {v4} from "uuid";
import {UUID} from "node:crypto";
import {DateTime} from "luxon";
import {RootState} from "@/core/store";
import {closeModal} from "@/utils/common/modals";


function selectIsNotificationsExists(id?: UUID) {
    return select(
        (state: RootState) => id ? id in state.common.notifications : false,
    )
}

function* sendNotification(action: ReturnType<typeof sagaActions.sendNotification>): Generator<Effect> {
    const {id, ...notify} = action.payload;

    const idIternal = id || v4() as UUID
    const expire = notify?.expire || DateTime.now().plus({second: notify?.closeable ? 3600 : 10}).toUnixInteger()

    yield put(slice.actions.pushNotification(
        {
            id: idIternal,
            notify: {
                text: notify?.text || '',
                type: notify?.type,
                closeable: notify?.closeable,
                expire,
            },
        },
    ))


    while (DateTime.fromSeconds(expire) > DateTime.now()
        && (yield selectIsNotificationsExists(idIternal))) {
        yield delay(499);
    }

    yield put(slice.actions.closeNotification(idIternal))
}

function* closeTwoModals() {
    yield call(closeModal);
    yield call(closeModal);
}

export const sagas = [
    takeEvery(
        sagaActions.sendNotification.type,
        sendNotification,
    ),
    takeEvery(
        sagaActions.closeTwoModals.type,
        closeTwoModals,
    ),
];