import {useEffect, useMemo, useRef} from "react";
import {parseQueryProps, useSafeCloseModal, useToModalRedirect} from "@/utils/common/modals";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {Button} from "@/components/base/Button";
import {X} from "@/components/icons/X";
import commonStyles from "@/components/wrappers/Modals/components/common.module.scss";
import styles from "./styles.module.scss";
import clsx from "clsx";
import {Typography} from "@/components/base/Typography";
import {BannerItem, FEBannerType} from "@/components/wrappers/DashboardBase/components/BannerList/BannerItem";
import {IModalProps} from "@/components/wrappers/Modals/consts";
import {Input} from "@/components/form/Input";
import {FormProvider, useForm} from "react-hook-form";
import {RecipientDetails} from "@/components/wrappers/Modals/components/CreatePaymentOrder/components/RecipientDetails";
import {business} from "@/business";
import {Select} from "@/components/form/Select";
import fp from "lodash/fp";
import {
    AccountSelectItem,
} from "@/components/wrappers/Modals/components/CreatePaymentOrder/components/AccountSelectItem";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import i18next from "i18next";
import {defaultMoney} from "@/utils/yup/money";

const infoTemplate = () => 'Независимо от того, какой налог или взнос вы оплатили, оплата зачтется в погашение обязательств ИП или физлица с наиболее ранним сроком уплаты' +
    `\n[Подробнее →](${window.location.pathname + window.location.search}&modal=ENSAnalyzeRequest)`


const schemaValidate = yup.object().shape({
    account_number: yup.string().required(() => i18next.t('common.errors.yup.common.required')).default(''),
    amount: defaultMoney,
    purpose: yup.string()
        .required(() => i18next.t('common.errors.yup.common.required'))
        .default(''),
    __meta: yup.mixed(),
})

type FormType = yup.InferType<typeof schemaValidate>;

export const CreatePaymentOrder = (
    {
        currentId,
    }: IModalProps) => {
    const contentRef = useRef(null);
    const close = useSafeCloseModal(currentId);
    const open = useToModalRedirect();

    const infoTemplateDisplay = useMemo(infoTemplate, [window.location.search])

    const accounts = useAppSelector(
        (s) => s.users.debtAccounts,
        fp.isEqual,
    )

    useOnClickOutside(
        close,
        contentRef,
    )

    const methods = useForm<FormType>({
        mode: 'onChange',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schemaValidate),
        defaultValues: schemaValidate.getDefault(),
    });

    useEffect(() => {
        const queryProps = parseQueryProps(currentId)

        if (queryProps?.amount) methods.setValue('amount', queryProps.amount as number, {shouldValidate: true});
    }, [currentId, methods]);

    const dp = useAppDispatch();

    useEffect(() => {
        dp(business.users.actions.getDebtBankAccounts())
    }, [dp]);

    useEffect(() => {
        const mainAccount = fp.find('is_main', accounts)

        if (mainAccount?.sub_name) methods.setValue('account_number', mainAccount.sub_name, {shouldValidate: true});
    }, [accounts, methods]);

    const submit = (data: FormType) => {
        const pureData = fp.omit(['__meta'], data)

        dp(business.taxes.actions.generatePaymentOrder(pureData));
    }

    return (
        <div className='position-absolute'>
            <div className={clsx(commonStyles.overlay, 'position-relative p-md-0 justify-content-md-end')}>
                <div
                    className={clsx(
                        commonStyles.content,
                        styles.content,
                        'h-100 d-md-inline-flex',
                    )}
                    ref={contentRef}
                >
                    <Button
                        type='TextButton'
                        className={clsx(commonStyles.closeBtn, 'd-md-none')}
                        onClick={close}
                    >
                        <X/>
                    </Button>
                    <FormProvider {...methods}>
                        <form className='d-flex flex-column gap-24' onSubmit={methods.handleSubmit(submit)}>
                            <Typography type='LargeTextSemibold'>
                                Формирование платежного поручения
                            </Typography>
                            <BannerItem
                                title={'Уплаты налогов и взносов формируют баланс ЕНС'}
                                description={infoTemplateDisplay}
                                type={FEBannerType.info}
                            />
                            <div className='d-flex flex-column gap-24'>
                                <Select
                                    name='account_number'
                                    label='Счет списания'
                                    required
                                    dropdownFooter={() => (
                                        <Typography onClick={open('AddAccount')} type='SmallTextRegular'
                                                    className='py-8 px-12'>
                                            + Добавить новый счет
                                        </Typography>
                                    )}
                                    values={
                                        accounts?.map((i) => (
                                            {value: i.sub_name as string, label: <AccountSelectItem {...i} />}
                                        ))
                                    }
                                />
                                <Input name='purpose' label='Назначение платежа' required/>
                                <Input name='amount' label='Сумма оплаты (₽)' placeholder='Введите' required/>
                                <RecipientDetails/>
                            </div>
                            <Button
                                className='mt-auto'
                                htmlType='submit'
                                disabled={!methods.formState.isValid}
                            >
                                Файл .txt (1с) для загрузки в банк
                            </Button>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    )
}
