export const MinusCircleOutlined12 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" className='d-block'>
        <g clipPath="url(#clip0_6027_103297)">
            <path
                d="M8.15625 5.625H3.84375C3.79219 5.625 3.75 5.66719 3.75 5.71875V6.28125C3.75 6.33281 3.79219 6.375 3.84375 6.375H8.15625C8.20781 6.375 8.25 6.33281 8.25 6.28125V5.71875C8.25 5.66719 8.20781 5.625 8.15625 5.625Z"
                fill='currentColor'/>
            <path
                d="M6 0.75C3.10078 0.75 0.75 3.10078 0.75 6C0.75 8.89922 3.10078 11.25 6 11.25C8.89922 11.25 11.25 8.89922 11.25 6C11.25 3.10078 8.89922 0.75 6 0.75ZM6 10.3594C3.59297 10.3594 1.64062 8.40703 1.64062 6C1.64062 3.59297 3.59297 1.64062 6 1.64062C8.40703 1.64062 10.3594 3.59297 10.3594 6C10.3594 8.40703 8.40703 10.3594 6 10.3594Z"
                fill='currentColor'/>
        </g>
        <defs>
            <clipPath id="clip0_6027_103297">
                <rect width="12" height="12" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)