import {IModalProps} from "@/components/wrappers/Modals/consts";
import {useParseQueryProps, useSafeCloseModal} from "@/utils/common/modals";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import commonStyles from "@/components/wrappers/Modals/components/common.module.scss";
import styles from "@/components/wrappers/Modals/components/ENSAnalyzeRequest/styles.module.scss";
import {Button} from "@/components/base/Button";
import {X} from "@/components/icons/X";
import clsx from "clsx";
import {useRef} from "react";
import {Typography} from "@/components/base/Typography";
import fp from "lodash/fp";
import {business} from "@/business";
import {UUID} from "node:crypto";
import {LoadingStatus} from "@/core/store/utils/sagas";
import {Spin} from "@/components/icons/Spin";

export const RemoveTransaction = (
    {currentId}: IModalProps,
) => {
    const contentRef = useRef(null);
    const close = useSafeCloseModal(currentId);

    const query = useParseQueryProps(currentId);

    const dp = useAppDispatch();

    const status = useAppSelector(
        (s) => s.transactions.removeTransactionMeta.status === LoadingStatus.Loading,
    )

    useOnClickOutside(
        close,
        contentRef,
    )

    const remove = () => {
        if (fp.isString(query.tId)) {
            dp(business.transactions.actions.removeTransaction(query.tId as UUID));
        }
    }

    return (
        <div className='position-absolute'>
            <div className={clsx(commonStyles.overlay, 'position-relative')}>
                <div className={clsx(commonStyles.content, 'align-self-start align-self-md-center', styles.content)}
                     ref={contentRef}>
                    <Button
                        type='TextButton'
                        className={commonStyles.closeBtn}
                        onClick={close}
                    >
                        <X/>
                    </Button>
                    <div className='vstack gap-32'>
                        <Typography type='H4Header'>
                            Удалить операцию?
                        </Typography>
                        <Typography type='NormalTextRegular' color='base-additional-grey'>
                            Удаление операции может повлиять на расчет налога
                        </Typography>
                        <div className='d-flex flex-column flex-md-row gap-16'>
                            <Button className='w-100' type='Secondary' onClick={close} disabled={status}>
                                Отмена
                            </Button>
                            <Button className='w-100' type='Danger' onClick={remove} disabled={status}>
                                {status && <Spin/>}
                                Удалить
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}