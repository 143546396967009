import {Button} from "@/components/base/Button";
import {X} from "@/components/icons/X";
import clsx from "clsx";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import {useEffect, useRef} from "react";
import {useSafeCloseModal} from "@/utils/common/modals";
import {IModalProps} from "@/components/wrappers/Modals/consts";

import {Typography} from "@/components/base/Typography";
import {Input} from "@/components/form/Input";
import {FormProvider, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import commonStyles from "@/components/wrappers/Modals/components/common.module.scss";
import styles from "./styles.module.scss";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {business} from "@/business";
import {displayBankNameBankReference} from "@/utils/data/sources/format";
import fp from "lodash/fp";
import {LoadingStatus} from "@/core/store/utils/sagas";
import {bankAccountNumber, bankBikValidate} from "@/utils/yup/banks";
import {useSubscribeError} from "@/utils/forms/hooks";


const schemaValidate = yup.object().shape({
    bank_bik: bankBikValidate,
    bank_name: yup.string().required().min(1),
    account_number: bankAccountNumber,
})

export const AddAccount = (
    {
        currentId,
    }: IModalProps,
) => {
    const contentRef = useRef(null);
    const close = useSafeCloseModal(currentId);

    const dp = useAppDispatch();

    useEffect(() => {
        return () => {
            dp(business.references.actions.setGetBankInfoByBikMeta({status: LoadingStatus.Never}))
        }
    }, [dp]);

    const bikSearch = useAppSelector(
        (state) => state.references.getBankInfoByBikMeta.message,
    )

    const bankName = useAppSelector(
        (state) => displayBankNameBankReference(state.references.bankInfoByBik),
    )

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(schemaValidate),
    });

    useEffect(() => {
        methods.setValue('bank_name', bankName, {shouldValidate: true});
    }, [bankName, methods]);

    useSubscribeError(methods, 'bank_bik', bikSearch);

    const {invalid: invalidBik} = methods.getFieldState('bank_bik');

    const bik = methods.watch('bank_bik');

    useEffect(() => {
        if (!invalidBik && bik) {
            dp(business.references.actions.getBankInfoByBik(bik));
        } else {
            methods.setValue('bank_name', '', { shouldValidate: true });
        }
    }, [
        bik,
        dp,
        invalidBik,
        methods,
    ]);

    useOnClickOutside(
        close,
        contentRef,
    )

    return (
        <div className='position-absolute'>
            <div className={clsx(commonStyles.overlay, ('position-relative'))}>
                <div className={clsx(commonStyles.content, styles.content, 'align-self-start align-self-md-center')}
                     ref={contentRef}>
                    <Button
                        type='TextButton'
                        className={commonStyles.closeBtn}
                        onClick={close}
                    >
                        <X/>
                    </Button>
                    <div className='d-flex flex-column gap-24 flex-fill'>
                        <div className='d-flex flex-column gap-8'>
                            <Typography
                                type='LargeTextSemibold'
                            >
                                Добавить новый счет
                            </Typography>
                            <Typography
                                color='base-additional-grey'
                                type='SmallTextRegular'
                            >
                                Укажите данные вашего расчетного счета ИП
                            </Typography>
                        </div>
                        <FormProvider {...methods}>
                            <form className='d-flex flex-column gap-24' onSubmit={methods.handleSubmit((data) => dp(
                                business.sources.actions.createAccountLink(fp.omit(['bank_name'], data)),
                            ))}>
                                <Input name='bank_bik' required label='БИК банка' placeholder='Введите'
                                       count={{show: true, max: 9}}/>
                                <Input name='bank_name' customErrorPath='__no.bank_name' required
                                       label='Наименование банка' placeholder='Название банка' disabled/>
                                <Input name='account_number' required label='Номер банковского счета'
                                       placeholder='Введите' count={{show: true, max: 20}}/>
                                <div className='d-flex flex-column flex-md-row flex-fill gap-8'>
                                    <Button htmlType='button' type='Secondary' className='flex-fill d-lg-none'
                                            onClick={close}>
                                        Отменить
                                    </Button>
                                    <Button htmlType='submit' className='flex-fill'
                                            disabled={!methods.formState.isValid}>
                                        Добавить
                                    </Button>
                                </div>
                            </form>
                        </FormProvider>
                    </div>
                </div>
            </div>
        </div>
    )
}
