import {createNamespace} from "@/core/store/actions";
import {IPayloadForAction, LoadingStatus} from "@/core/store/utils/sagas";
import {ReportFormat, TaskResponse} from "@/services/openapi";
import {generateExecId} from "@/business/common/utils/actionPreparer";


export const namespace = 'actions';

const actionCreator = createNamespace(namespace);

export interface State {
	list: {
		entity: TaskResponse | null
		meta: {
			status: LoadingStatus
			errorMessage: string | null
			blockUpTo: number | null
		}
	}
	generateDocMeta: IPayloadForAction & {
		id: number | null
		type: ReportFormat | null
	},
	confirmSubmitReportMeta: IPayloadForAction
}

export const initialState: State = {
	list: {
		entity: null,
		meta: {
			status: LoadingStatus.Never,
			errorMessage: null,
			blockUpTo: null,
		},
	},
	generateDocMeta: {
		status: LoadingStatus.Never,
		id: null,
		type: null,
	},
	confirmSubmitReportMeta: {
		status: LoadingStatus.Never,
	},
}


export const sagaActions = {
	getActionsList: actionCreator('getActionsList'),
	callGetActionListWithCooldown: actionCreator('callGetActionListWithCooldown'),
	callGenerateReport: actionCreator('callGenerateReport', generateExecId<{ id: number }>()),
	downloadReport: actionCreator<{ id: number, type: ReportFormat }>('downloadReport'),
	confirmSubmitReport: actionCreator<{ id: number }>('confirmSubmitReport'),
}