import i18next from "i18next";
import {InferType, Schema} from "yup";

import {yup, yupEnum} from "@/utils/yup/common";
import {computeIsType} from "@/utils/common/functions";

import {RateReasonType, TaxSystemType} from "@/services/openapi";


export const checkReductionAttemptByRate = (tax_system?: TaxSystemType, tax_rate?: number) => {
    const isTaxSys = computeIsType(tax_system, TaxSystemType);

    if (typeof tax_rate !== 'number') return false;

    if (isTaxSys.usnD) return tax_rate < 6;

    if (isTaxSys.usnDR) return tax_rate < 15;

    return false;
}

const checkReductionAttempt = <F extends Schema>(field: F) => field.when(
    ['tax_system', 'tax_rate'],
    {
        is: checkReductionAttemptByRate,
        then: (s) => s.required(() => i18next.t('common.errors.yup.common.required')),
    },
)

const rateReasonPart = yup
    .string()
    .matches(/^(\d|\.|\/|-)+$/, { excludeEmptyString: true, message: () => i18next.t('common.errors.yup.common.incorrect') })
    .min(0, () => i18next.t('common.errors.yup.common.incorrect'))
    .max(4, () => i18next.t('common.errors.yup.common.incorrect'));


export const schemaValidate = yup.object().shape({
    inn: yup.string()
        .required(() => i18next.t('common.errors.yup.common.required'))
        .min(10, () => i18next.t('common.errors.yup.common.incorrect'))
        .max(12, () => i18next.t('common.errors.yup.common.incorrect')),
    start_year: yup
        .number()
        .required(() => i18next.t('common.errors.yup.common.required')),
    tax_system: yupEnum(TaxSystemType)
        .required(() => i18next.t('common.errors.yup.common.required')),
    tax_rate: yup
        .number()
        .when(
            'tax_system',
            {
                is: (v: TaxSystemType) => [TaxSystemType.usnD, TaxSystemType.usnDR].includes(v),
                then: (s) => s.required(() => i18next.t('common.errors.yup.common.required')),
            },
        )
        .when(
            'tax_system',
            {
                is: (v: TaxSystemType) => TaxSystemType.usnD === v,
                then: (s) => s
                    .min(0, () => i18next.t('common.errors.yup.common.incorrect'))
                    .max(6, () => i18next.t('common.errors.yup.common.incorrect')),
            },
        )
        .when(
            'tax_system',
            {
                is: (v: TaxSystemType) => TaxSystemType.usnDR === v,
                then: (s) => s
                    .min(0, () => i18next.t('common.errors.yup.common.incorrect'))
                    .max(15, () => i18next.t('common.errors.yup.common.incorrect')),
            },
        ),
    reason_type: checkReductionAttempt(yupEnum(RateReasonType)),
    rate_reason_0: checkReductionAttempt(rateReasonPart),
    rate_reason_1: rateReasonPart,
    rate_reason_2: rateReasonPart,
})

export type FormType = InferType<typeof schemaValidate>;
