
export const storeSocketGlobal = (socket: WebSocket) => {
    if (window.__sockets)
        window.__sockets.main = socket;
    else
        window.__sockets = {main: socket}
}


export const getMainSocket = () => {
    return window?.__sockets?.main || null;
}