export const Reload12 = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className='d-block'>
        <path
            d="M4.125 4.875H1.875C1.77555 4.875 1.68016 4.83549 1.60983 4.76517C1.53951 4.69484 1.5 4.59945 1.5 4.5V2.25C1.5 2.15055 1.53951 2.05516 1.60983 1.98483C1.68016 1.91451 1.77555 1.875 1.875 1.875C1.97445 1.875 2.06984 1.91451 2.14017 1.98483C2.21049 2.05516 2.25 2.15055 2.25 2.25V3.36797C2.97515 2.55 4.24735 1.5 6 1.5C8.0072 1.5 9.21467 2.68453 9.26533 2.73469C9.3358 2.80493 9.37553 2.90029 9.37567 2.99981C9.37587 3.09932 9.33647 3.19482 9.26627 3.26531C9.196 3.3358 9.10067 3.3755 9.00113 3.37568C8.9016 3.37585 8.80613 3.33649 8.7356 3.26625C8.723 3.25359 7.68373 2.25 6 2.25C4.35937 2.25 3.17672 3.40547 2.59875 4.125H4.125C4.22445 4.125 4.31984 4.16451 4.39017 4.23483C4.46049 4.30516 4.5 4.40055 4.5 4.5C4.5 4.59945 4.46049 4.69484 4.39017 4.76517C4.31984 4.83549 4.22445 4.875 4.125 4.875ZM10.125 7.125H7.875C7.77553 7.125 7.68013 7.16453 7.60987 7.23487C7.53953 7.30513 7.5 7.40053 7.5 7.5C7.5 7.59947 7.53953 7.69487 7.60987 7.76513C7.68013 7.83547 7.77553 7.875 7.875 7.875H9.40127C8.82327 8.59453 7.6406 9.75 6 9.75C4.31625 9.75 3.27703 8.7464 3.26437 8.73373C3.19389 8.66353 3.09838 8.62413 2.99887 8.62433C2.89936 8.62447 2.80399 8.6642 2.73375 8.73467C2.66351 8.8052 2.62415 8.90067 2.62432 9.0002C2.6245 9.09973 2.6642 9.19507 2.73469 9.26533C2.78531 9.31547 3.99281 10.5 6 10.5C7.75267 10.5 9.02487 9.45 9.75 8.632V9.75C9.75 9.84947 9.78953 9.94487 9.85987 10.0151C9.93013 10.0855 10.0255 10.125 10.125 10.125C10.2245 10.125 10.3199 10.0855 10.3901 10.0151C10.4605 9.94487 10.5 9.84947 10.5 9.75V7.5C10.5 7.40053 10.4605 7.30513 10.3901 7.23487C10.3199 7.16453 10.2245 7.125 10.125 7.125Z"
            fill='currentColor'/>
    </svg>
)

export const Reload = () => (
    <svg width="18" height="18"
         viewBox="0 0 18 18" fill="none"
         xmlns="http://www.w3.org/2000/svg"
         className='d-block'
    >
        <path
            d="M6.1875 7.3125H2.8125C2.66332 7.3125 2.52024 7.25324 2.41475 7.14775C2.30926 7.04226 2.25 6.89918 2.25 6.75V3.375C2.25 3.22582 2.30926 3.08274 2.41475 2.97725C2.52024 2.87176 2.66332 2.8125 2.8125 2.8125C2.96168 2.8125 3.10476 2.87176 3.21025 2.97725C3.31574 3.08274 3.375 3.22582 3.375 3.375V5.05195C4.46273 3.825 6.37102 2.25 9 2.25C12.0108 2.25 13.822 4.0268 13.898 4.10203C14.0037 4.20739 14.0633 4.35044 14.0635 4.49971C14.0638 4.64898 14.0047 4.79223 13.8994 4.89797C13.794 5.0037 13.651 5.06325 13.5017 5.06352C13.3524 5.06378 13.2092 5.00474 13.1034 4.89937C13.0845 4.88039 11.5256 3.375 9 3.375C6.53906 3.375 4.76508 5.1082 3.89812 6.1875H6.1875C6.33668 6.1875 6.47976 6.24676 6.58525 6.35225C6.69074 6.45774 6.75 6.60082 6.75 6.75C6.75 6.89918 6.69074 7.04226 6.58525 7.14775C6.47976 7.25324 6.33668 7.3125 6.1875 7.3125ZM15.1875 10.6875H11.8125C11.6633 10.6875 11.5202 10.7468 11.4148 10.8523C11.3093 10.9577 11.25 11.1008 11.25 11.25C11.25 11.3992 11.3093 11.5423 11.4148 11.6477C11.5202 11.7532 11.6633 11.8125 11.8125 11.8125H14.1019C13.2349 12.8918 11.4609 14.625 9 14.625C6.47438 14.625 4.91555 13.1196 4.89656 13.1006C4.79083 12.9953 4.64757 12.9362 4.4983 12.9365C4.34904 12.9367 4.20599 12.9963 4.10063 13.102C3.99526 13.2078 3.93622 13.351 3.93648 13.5003C3.93675 13.6496 3.9963 13.7926 4.10203 13.898C4.17797 13.9732 5.98922 15.75 9 15.75C11.629 15.75 13.5373 14.175 14.625 12.948V14.625C14.625 14.7742 14.6843 14.9173 14.7898 15.0227C14.8952 15.1282 15.0383 15.1875 15.1875 15.1875C15.3367 15.1875 15.4798 15.1282 15.5852 15.0227C15.6907 14.9173 15.75 14.7742 15.75 14.625V11.25C15.75 11.1008 15.6907 10.9577 15.5852 10.8523C15.4798 10.7468 15.3367 10.6875 15.1875 10.6875Z"
            fill='currentColor'/>
    </svg>
)
