import qs from "qs";

const ignoreRegexps = [
	/^\/auth\/.+/gm,
	/^\/$/gm,
	/^\/404$/gm,
]

export const strictKickUnlikeUser = () => {
	const redirectUrl = window.location.pathname

	if (
		ignoreRegexps.every((r) => !r.test(redirectUrl))
	) {
		window.location.href = `/auth/login${qs.stringify(
			{redirectUrl: `${redirectUrl}${window.location.search}`},
			{addQueryPrefix: true},
		)}`
	}
}

