import {LoadingStatus} from "@/core/store/utils/sagas";
import {statusCondition, StatusObject} from "./statusConditions";
import React from "react";

type IStatusConditionProps = {
	status: LoadingStatus
} & StatusObject<React.FC>;

export const StatusCondition = (
	{
		status,
		...onStatus
	}: IStatusConditionProps,
) => {
	const Component = statusCondition(status, onStatus);

	return Component ? <Component/> : null;
};