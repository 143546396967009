import commonStyles from "@/components/wrappers/Modals/components/common.module.scss";
import styles from "@/components/wrappers/Modals/components/ENSAnalyzeRequest/styles.module.scss";
import {Button} from "@/components/base/Button";
import {X} from "@/components/icons/X";
import clsx from "clsx";
import {IModalProps} from "@/components/wrappers/Modals/consts";
import {useParseQueryProps, useSafeCloseModal} from "@/utils/common/modals";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import {useEffect, useRef, useState} from "react";
import fp from "lodash/fp";
import {Typography} from "@/components/base/Typography";
import {
    displayAccountNumber,
    displayDisableDateLabel,
    displayProviderName,
    isManualSource,
} from "@/utils/data/sources/format";
import {logos} from "@/utils/data/sources/cms/logos";
import {Source} from "@/services/openapi";

import innerStyles from './styles.module.scss';
import {FormProvider, useForm} from "react-hook-form";
import {DatePicker} from "@/components/form/DatePicker";
import {LoadingStatus} from "@/core/store/utils/sagas";
import {Spin} from "@/components/icons/Spin";
import {business} from "@/business";
import {DateTime} from "luxon";
import {yup} from "@/utils/yup/common";
import i18next from "i18next";
import {yupResolver} from "@hookform/resolvers/yup";

const schemaValidate = yup.object().shape({
    date: yup.date()
        .required(() => i18next.t('common.errors.yup.common.required')),
})


export const OffSource = (
    {currentId}: IModalProps,
) => {
    const dp = useAppDispatch();
    const contentRef = useRef(null);
    const close = useSafeCloseModal(currentId);
    const query = useParseQueryProps(currentId);

    const entity = useAppSelector(
        (state) => fp.find({id: query?.id}, state.sources.sourceList) as Source | null | undefined,
        fp.isEqual,
    )

    const isLoading = useAppSelector(
        (state) => state.sources.disableSourceMeta.status === LoadingStatus.Loading,
        fp.isEqual,
    )

    useOnClickOutside(
        close,
        contentRef,
    )

    const methods = useForm({resolver: yupResolver(schemaValidate)});

    const submit = methods.handleSubmit((data) => {
        const disable_date = DateTime.fromJSDate(data.date).toSQLDate()

        if (entity && disable_date) {
            dp(business.sources.actions.hideSource({
                source_id: entity.id,
                disable_date,
            }));
        }
    })

    const [expireLoadDate, setDate] = useState(DateTime.now);

    useEffect(() => {
        // fix set locale throttle
        setDate(DateTime.now)
    }, []);

    const submitAuto = () => {
        const disable_date = expireLoadDate.toSQLDate()

        if (entity && disable_date) {
            dp(business.sources.actions.hideSource({
                source_id: entity.id,
                disable_date,
            }));
        }
    }


    const providerCommonName = displayProviderName(entity);
    const isManualSourceValue = isManualSource(entity);

    const logoUrl = logos[`${providerCommonName}`] || '/imgs/thirdParty/logos/placeholder.svg';

    return (
        <div className='position-absolute'>
            <div className={clsx(commonStyles.overlay, 'position-relative')}>
                <div className={clsx(commonStyles.content, 'align-self-start align-self-md-center', styles.content)}
                     ref={contentRef}>
                    <Button
                        type='TextButton'
                        className={commonStyles.closeBtn}
                        onClick={close}
                    >
                        <X/>
                    </Button>
                    <FormProvider {...methods}>
                        <form className='vstack gap-32' onSubmit={submit}>
                            <Typography type='H4Header'>
                                {isManualSourceValue ? 'Скрыть источник' : 'Отключить источник'}
                            </Typography>
                            <div className='vstack gap-16'>
                                <div className='d-flex gap-8 align-items-center'>
                                    {!entity
                                        ? <Spin/>
                                        : <div
                                            className={innerStyles.logo}
                                            style={{backgroundImage: logoUrl ? `url(${logoUrl})` : 'none'}}
                                        />
                                    }
                                    <Typography additionalProps={{title: providerCommonName}}>
                                        {providerCommonName}
                                    </Typography>
                                    <Typography type='SmallTextRegular' color='base-secondary-1'>
                                        {displayAccountNumber(entity, true)}
                                    </Typography>
                                </div>
                                {isManualSourceValue ? (
                                    <>
                                        <Typography>
                                            Источник больше не используется
                                        </Typography>
                                        <DatePicker name='date' label={displayDisableDateLabel(entity)} required/>
                                    </>
                                ) : (
                                    <Typography>
                                        {`Загруженные ранее данные сохранятся. С `}
                                        <Typography color='brand-primary' as='span' type='NormalTextSemibold'>
                                            {expireLoadDate.toLocaleString(DateTime.DATE_SHORT)}
                                        </Typography>
                                        {` операции необходимо вносить вручную`}
                                    </Typography>
                                )}
                            </div>
                            <div className='d-flex flex-column flex-md-row gap-16'>
                                <Button type='Secondary' onClick={close} className='w-100'>
                                    Отмена
                                </Button>
                                <Button
                                    type='Danger'
                                    htmlType='submit'
                                    className='w-100'
                                    disabled={isLoading}
                                    onClick={isManualSourceValue ? undefined : submitAuto}
                                >
                                    {isLoading && <span><Spin/></span>}
                                    {isManualSourceValue ? 'Скрыть источник' : 'Отключить источник'}
                                </Button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    )
}
