import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {initialState, namespace} from './inits'
import {IPayloadForAction} from "@/core/store/utils/sagas";
import {EnsInfo} from "@/services/openapi";

export const slice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		setEnsMeta: (state, action: PayloadAction<IPayloadForAction>) => {
			state.ensInfoMeta = action.payload;
		},
		setEnsInfo: (state, action: PayloadAction<EnsInfo>) => {
			state.ensInfo = action.payload;
		},
		setPaymentOrderMeta: (state, action: PayloadAction<IPayloadForAction>) => {
			state.paymentOrderMeta = action.payload;
		},
		setCreateOperationsMeta: (state, action: PayloadAction<IPayloadForAction>) => {
			state.createOperationsMeta = action.payload;
		},
	},
})
