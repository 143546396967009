import { createNamespace } from "@/core/store/actions";
import {IPayloadForAction, LoadingStatus} from "@/core/store/utils/sagas";
import {CreateTaxPaymentOperation, EnsInfo, GenerateEnsOrderTxt} from "@/services/openapi";

export const namespace = 'taxes';

const actionCreator = createNamespace(namespace);


export interface State {
	ensInfo: EnsInfo | null;
	ensInfoMeta: Partial<IPayloadForAction>
	paymentOrderMeta: Partial<IPayloadForAction>
	createOperationsMeta: Partial<IPayloadForAction>
}

export const initialState: State = {
	ensInfo: null,
	ensInfoMeta: {
		status: LoadingStatus.Never,
	},
	paymentOrderMeta: {
		status: LoadingStatus.Never,
	},
	createOperationsMeta: {
		status: LoadingStatus.Never,
	},

}


export const sagaActions = {
	getEnsInfo: actionCreator('getEnsInfo'),
	generatePaymentOrder: actionCreator<GenerateEnsOrderTxt>('generatePaymentOrder'),
	recalculateTaxes: actionCreator('recalculateTaxes'),
	createOperations: actionCreator<{
		taxes: (Pick<CreateTaxPaymentOperation, | 'amount' | 'doc_number'> & { date: number })[],
		taxPeriod: number,
	}>('createOperation'),
}
