import {UUID} from "node:crypto";
import {PayloadAction} from "@reduxjs/toolkit";

// enum SupportTest {
//     matches,
//     min,
//     max,
//     length,
// }

// interface SourceForm {
//     content: string,
//     validation: {
//         [k: string]: {
//             fieldType: 'string' | 'number',
//             needBackendCheck: boolean,
//             tests: {
//                 type: SupportTest,
//                 param?: string | number,
//                 message: string,
//             }[]
//         };
//     }
// }
//
//
// interface SourceType {
//     id: UUID;
//     name: string;
//     logo: string;
// }
//
// interface SourceTypeType {
//     id: UUID
//     name: string
//     description: string
//     icon: string
//     action: NextPageActionType
//     sources: SourceType[]
// }

// type NextPageActionType = PayloadActionCreator<object, 'string'>

export enum ViewType {
    list = 'list',
    form = 'form',
}

export enum Actions {
    submitInside = 'submitInside',
    twoWay = 'twoWay',
}


export enum SelectStyle {
    row = 'row',
    column = 'column',
}

export enum IconModifier {
    round = 'round',
}


export type EntityIdType = UUID | string;

export interface SelectActionType {
    id: UUID;
    name: string;
    action: PayloadAction<object, string>
    iconModifier?: IconModifier,
    description?: string;
    icon?: string; // иконка вызывается по имени, что есть на фронте
    logo?: string; // адес в интернетике до картинки
}

export interface SelectType {
    id: EntityIdType;
    type: ViewType.list;
    description?: string;
    style: SelectStyle
    actions: SelectActionType[]
}

export interface FormType {
    id: EntityIdType;
    type: ViewType.form;
    description?: string;
    content?: string;
    bottom_actions: Actions
    default_next_button_text: string | null;
    default_next_button_action: PayloadAction<object, string> | null;
    fields_type: Record<string, 'string' | 'number' | 'date'>
    regex_validation: Record<string, { test: RegExp, message: string }>
    backend_validation: string[];
}

export const isFormType = (v: any): v is FormType => v?.type === ViewType.form;
export const isSelectType = (v: any): v is SelectType => v?.type === ViewType.list;


export interface Sources {
    root_id: EntityIdType;
    sources: (SelectType | FormType)[];
}
