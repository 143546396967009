import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {initialState, namespace} from './inits'
import {IPayloadForAction} from "@/core/store/utils/sagas";
import {UserInfoBanner} from "@/services/openapi";


export const slice = createSlice({
	name: namespace,
	initialState,
	selectors: {},
	reducers: {
		setListEntity: (state, action: PayloadAction<UserInfoBanner[]>) => {
			state.list.entity = action.payload
		},
		setListMeta: (state, action: PayloadAction<IPayloadForAction>) => {
			state.list.meta.status = action.payload.status
			state.list.meta.errorMessage = action.payload.message || null
		},
		setUpdatingMeta: (state, action: PayloadAction<IPayloadForAction>) => {
			state.list.updatingMeta[action.payload.key || 'null'] = {
				status: action.payload.status,
				errorMessage: action.payload.message || null,
			}
		},
	},
})