import {RefObject, useEffect} from "react";
import {dropdownFloatingPortalKey} from "@/utils/common/dropdown";

export const useOnClickOutside = <T = Node>(
    callback: (event?: MouseEvent) => void,
    elementRef?: RefObject<T>,
    ...elementRefs: (RefObject<Node> | null)[]
) => {
    useEffect(() => {
        if (elementRef?.current) {
            const handler = (event: MouseEvent) => {
                const floatingEls = document.getElementById(dropdownFloatingPortalKey);

                const arr = [
                    elementRef,
                    ...elementRefs,
                ]

                if (floatingEls !== null) {
                    arr.push({current: floatingEls});
                }

                const containSearch = arr.every((i) => (
                    i?.current && !(i.current as unknown as Node).contains(event.target as Node)
                ))

                if (containSearch) {
                    return callback(event);
                }
            };

            document.addEventListener("mousedown", handler);
            return () => document.removeEventListener("mousedown", handler);
        }
    }, [callback, elementRef, elementRefs]);
};