import fp from "lodash/fp";
import {Dispatch, SetStateAction, useEffect, useLayoutEffect, useMemo, useState} from "react";
import {logger} from "@/utils/logger";

const breakpoints = {
    xs: 0,
    sm: 370,
    md: 1020,
    lg: 1430,
    xl: 1900,
}

export const isBreakpointUp = () =>
    fp.pipe(
        fp.mapValues(fp.gte(window?.innerWidth)),
    )(breakpoints) as Record<keyof typeof breakpoints, boolean>

const setter = <V = ReturnType<typeof isBreakpointUp>>(setV: Dispatch<SetStateAction<V>>) => fp.throttle(100, () => {
    const newVal = isBreakpointUp()

    try {
        setV((prev) => fp.isEqual(prev, newVal) ? prev : newVal as V);
    } catch (e) {
        logger.info('Ожидаемо￣へ￣: ', e);
    }
});

export const useIsBreakpointUp = () => {
    const [map, setMap] = useState<ReturnType<typeof isBreakpointUp> | null>(null);

    useEffect(() => {
        setMap(isBreakpointUp);
    }, []);

    const throttledSet = useMemo(() => setter(setMap), [])

    useLayoutEffect(() => {
        window.addEventListener("resize", throttledSet);

        return () => {
            window.removeEventListener("resize", throttledSet);
        };
    }, [throttledSet]);

    return map;
}