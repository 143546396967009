import {BaseValue, IFullValue, isFullValue} from "@/components/form/Select/types";

export const searchFieldNameCreator = (name: string) => `__meta.selectSearch.${name}`

export const dataPickerDisplayFieldNameCreator = (name: string) => `__meta.displayDate.${name}`

export const computeToFullValues = <
	V extends BaseValue = BaseValue,
	VE = IFullValue<V>,
>(list: VE[] | BaseValue[]) => {
	return list.map((item) => {
		if (isFullValue(item)) {
			return item;
		}

		return {value: item, label: item} as IFullValue<V>;
	}) as IFullValue<V>[]
}

