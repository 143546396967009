import fp from "lodash/fp";


export const truncateString = (source?: string, limit = 24) => {
    return (source?.length || 0) > limit ? `${fp.take(limit, source).join('')}...` : source;
}


export const maskString = (source: string | undefined, mask: string) => {
    if (!source) return mask;

    return `${fp.take(mask.length - source.length, mask).join('')}${source}`
}
