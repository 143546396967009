import commonStyles from "@/components/wrappers/Modals/components/common.module.scss";
import styles from "@/components/wrappers/Modals/components/ENSAnalyzeRequest/styles.module.scss";
import {Button} from "@/components/base/Button";
import {X} from "@/components/icons/X";
import clsx from "clsx";
import {IModalProps} from "@/components/wrappers/Modals/consts";
import {useIsCurrentLastOpened, useParseQueryProps, useSafeCloseModal, useToModalRedirect} from "@/utils/common/modals";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import {useEffect, useRef} from "react";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import fp from "lodash/fp";
import {business} from "@/business";
import {LocalAddSources} from "@/utils/data/sources/cms/local";
import {isFormType, isSelectType} from "@/utils/data/sources/cms/types";
import {List} from "./components/List";
import {Form} from "./components/Form";
import {Typography} from "@/components/base/Typography";
import {Highlighter} from "@/components/composite/Highlighter";
import {computeIsType} from "@/utils/common/functions";
import {LoadingStatus} from "@/core/store/utils/sagas";


export const AddSource = (
    {currentId}: IModalProps,
) => {
    const contentRef = useRef(null);
    const close = useSafeCloseModal(currentId);
    const modalQProps = useParseQueryProps(currentId);
    const open = useToModalRedirect();
    const dp = useAppDispatch();

    const matchEntity = useAppSelector(
        (state) =>
            fp.find({id: fp.last(state.sources.addSourceCtx.history)}, LocalAddSources.sources),
        fp.isEqual,
    )

    const status = useAppSelector(
        (state) => state.transactions.upload1CBankStatement.meta.status,
    )

    const isStatus = computeIsType(status, LoadingStatus);

    const isLastOpened = useIsCurrentLastOpened(currentId);

    const closeCB = (isStatus.Loading && isLastOpened)
        ? open('CloseAttention')
        : close;

    useOnClickOutside(
        closeCB,
        contentRef,
    )

    useEffect(() => {
        dp(business.sources.actions.openRoot(modalQProps?.rootId));

        return () => {
            dp(business.sources.actions.clearAddSourceData());
        }
    }, [dp, modalQProps?.rootId]);

    const isSelect = isSelectType(matchEntity)

    return (
        <div className='position-absolute'>
            <div className={clsx(commonStyles.overlay, 'position-relative')}>
                <div
                    className={clsx(commonStyles.content, styles.content, 'align-self-start align-self-md-center p-md-32')}
                    ref={contentRef}
                >
                    <Button
                        type='TextButton'
                        className={commonStyles.closeBtn}
                        onClick={closeCB}
                    >
                        <X/>
                    </Button>
                    <div className='d-flex flex-column gap-16 gap-md-32 w-100'>
                        <div className={clsx('d-flex flex-column gap-8', matchEntity?.id === 'YaMarketConfirm' && 'd-none')}>
                            <Typography type='LargeTextSemibold' typesWithMedia={['H4Headermd']}>
                                Добавить данные
                            </Typography>
                            {!!matchEntity?.description && (
                                <Typography color='base-additional-grey'>
                                    <Highlighter text={matchEntity.description} />
                                </Typography>
                            )}
                        </div>
                        {isSelect && <List {...matchEntity} />}
                        {isFormType(matchEntity) && <Form {...matchEntity} />}
                    </div>
                </div>
            </div>
        </div>
    )
}