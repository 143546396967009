import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {initialState, namespace} from './inits'
import {IPayloadForAction} from "@/core/store/utils/sagas";
import {CreateAccountResponse, Operation} from "@/services/openapi";
import {mergeWithConcatArray} from "@/utils/common/objects";
import fp from "lodash/fp";

export const slice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        setFilterValue: (
            state,
            action: PayloadAction<Partial<Omit<typeof state.groupedList.filters, 'pagination'>>>,
        ) => {
            state.groupedList.filters = {
                ...state.groupedList.filters,
                ...action.payload,
                pagination: initialState().groupedList.filters.pagination,
            }

            state.groupedList.entities = {};
            state.groupedList.pageCount = Infinity;
        },
        increasePageNum: (state) => {
            state.groupedList.filters.pagination.page_number = state.groupedList.filters.pagination.page_number + 1
        },
        setPageCount: (state, action: PayloadAction<number>) => {
            state.groupedList.pageCount = action.payload
        },
        eraseList: (state) => {
            state.groupedList.entities = {};
            state.groupedList.pageCount = Infinity;
            state.groupedList.filters.pagination.page_number = 1;
        },
        pushTransactionList: (state, action: PayloadAction<Record<string, Operation[]>>) => {
            state.groupedList.entities = mergeWithConcatArray(
                state.groupedList.entities,
                action.payload,
            )
        },
        setTransactionListMeta: (state, action: PayloadAction<IPayloadForAction>) => {
            state.groupedList.meta = {
                ...state.groupedList.meta,
                ...action.payload,
            }
        },
        setUpload1CBankStatementFileMeta: (state, action: PayloadAction<IPayloadForAction>) => {
            state.upload1CBankStatement.meta = action.payload;
        },
        setManualCreateTransactionMeta: (state, action: PayloadAction<IPayloadForAction>) => {
            state.manualCreateTransactionMeta = action.payload;
        },
        setUpload1CBankStatementFileData: (state, action: PayloadAction<CreateAccountResponse>) => {
            state.upload1CBankStatement.data = action.payload;
        },
        clearUpload1CBankStatementFile: (state) => {
            state.upload1CBankStatement = initialState().upload1CBankStatement;
        },
        setDownloadKudirMeta: (state, action: PayloadAction<IPayloadForAction>) => {
            state.downloadKudirMeta = {
                ...state.groupedList.meta,
                ...action.payload,
            }
        },
        setTransactionRemoveMeta: (state, action: PayloadAction<IPayloadForAction>) => {
            state.removeTransactionMeta = action.payload
        },
        removeTransactionFromList: (state, action: PayloadAction<string>) => {
            const keys = Object.entries(state.groupedList.entities)

            const newMap = keys
                .map(([id, arr]) => [id, fp.reject({id: action.payload}, arr)])
                .filter(([, arr]) => arr.length > 0)

            state.groupedList.entities = Object.fromEntries(newMap);
        },
        // updateTypeInList: (state, action: PayloadAction<IUpdateType>) => {
        //     const all = fp.find({id: action.payload.id},fp.flatten(fp.values(state.groupedList.entities)))
        //     if (all) {
        //         all.markup.operation_type = action.payload.newType;
        //     }
        // },
        resetTransactions: (state) => {
            state.groupedList = initialState().groupedList
            state.groupedList.entities = {};
        },
    },
})
