import {UUID} from "node:crypto";

import fp from "lodash/fp";
import React from "react";

export type BaseValue = string | number | UUID;

export interface IFullValue<V extends BaseValue = BaseValue> {
	value: V;
	label?: React.ReactNode;
	disable?: boolean;
}

export const isFullValue = (val: any): val is IFullValue => fp.has('label', val);
