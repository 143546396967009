import {call, Effect, put, select, takeLatest} from "redux-saga/effects";
import {sagaActions} from "./inits";
import {computeIsType, generatorWrap} from "@/utils/common/functions";
import {slice} from "./slice";
import {mixinWithErrorHandler, mixinWithLoadingHandler} from "@/core/store/utils/sagas";
import {RootState} from "@/core/store";
import {
    CreateAccountResponse,
    CreateOperation, createOperationOperationsByHandPost,
    createOperationsFromFileOperationsFilePost,
    deleteOperationOperationsDelete,
    getKudirReportReportsKudirGet,
    getOperationsOperationsPost,
    Operation,
    OperationCategory,
    OperationsResponse,
    OperationType,
    updateOperationOperationsMarkupPut,
} from "@/services/openapi";
import {mixinWithInitAwait} from "@/business/auth/mixins";
import fp from "lodash/fp";
import {closeModal} from "@/utils/common/modals";
import {common} from "@/business/common";
import {NotificationType} from "@/business/common/inits";
import {uuid} from "@/utils/common/uuid";
import {downloadFile} from "@/utils/common/blob";
import {DateTime} from "luxon";
import {getFileFromTmp, removeFileFromTmp} from "@/utils/common/files";
import {taxes} from "@/business/taxes";
import {AxiosError} from "axios";


function* loadTransactions(): Generator<Effect> {
    const prev: RootState['transactions']['groupedList'] = yield select((state: RootState) => state.transactions.groupedList);

    if (prev.filters.pagination.page_number > prev.pageCount) return;

    const resp: OperationsResponse = (yield call(getOperationsOperationsPost, {body: prev.filters})).data;

    const grouped = fp.groupBy('date', resp.operations)

    yield put(slice.actions.setPageCount(resp.pages_count));

    yield put(slice.actions.pushTransactionList(grouped));

    yield put(slice.actions.increasePageNum())
}

function* removeTransaction(action: ReturnType<typeof sagaActions.removeTransaction>): Generator<Effect> {
    yield call(deleteOperationOperationsDelete, {query: {operation_id: action.payload}})

    // yield put(slice.actions.eraseList());
    // yield put(sagaActions.loadTransactions());

    yield put(common.actions.sendNotification({
        text: 'Операция удалена',
        type: NotificationType.Success,
    }))

    yield put(slice.actions.removeTransactionFromList(action.payload))

    yield call(closeModal);
}

function* updateOperationType(action: ReturnType<typeof sagaActions.updateOperationType>): Generator<Effect> {
    const id = uuid.v4();

    yield put(common.actions.sendNotification({
        id,
        text: 'Обновление',
        type: NotificationType.Loading,
    }))

    const operation: Operation = yield select((state: RootState) => fp.find({id: action.payload.id}, fp.flatten(fp.values(state.transactions.groupedList.entities))))

    yield call(updateOperationOperationsMarkupPut, {
        query: {operation_id: action.payload.id}, body: {
            operation_type: action.payload.newType,
            amount: operation.markup.amount,
        },
    })

    yield put(slice.actions.eraseList());
    yield put(sagaActions.loadTransactions());

    yield put(common.actions.updateNotification({
        id, notify: {
            text: 'Тип операции изменен',
            type: NotificationType.Success,
        },
    }))
}

function* downloadKudir(action: ReturnType<typeof sagaActions.downloadKudir>): Generator<Effect> {
    const r = yield call(getKudirReportReportsKudirGet, {
        query: {
            period_type: 0,
            ...action.payload,
        },
        responseType: 'blob',
    })

    const nowDate = DateTime.now().toLocaleString(DateTime.DATE_SHORT);

    downloadFile(r, `КУДиР за ${action.payload.period_year} год от ${nowDate}.${action.payload.kudir_format}`);
}

function* upload1CBankStatementFile(action: ReturnType<typeof sagaActions.upload1CBankStatementFile>): Generator<Effect> {
    const file = getFileFromTmp(action.payload) as File;

    const resp: CreateAccountResponse = (yield call(
        createOperationsFromFileOperationsFilePost,
        {body: {account_file: file}},
    )).data;
    yield put(slice.actions.setUpload1CBankStatementFileData(resp))

    removeFileFromTmp(action.payload);
}


function* manualCreateTransaction(action: ReturnType<typeof sagaActions.manualCreateTransaction>): Generator<Effect> {
    const isOType = computeIsType(action.payload.operationType, OperationType);

    const date = action.payload.date;

    if (isOType._1 || isOType._3) {
        const mapped: CreateOperation = {
            category: isOType._1 ? OperationCategory.debet : OperationCategory.credit,
            operation_type: action.payload.operationType as CreateOperation['operation_type'],
            counterparty_name: action.payload.counterpartyName,
            purpose: action.payload.purpose,
            amount: action.payload.amount,
            date: DateTime.fromSeconds(date).toSQLDate() as string,
            doc_number: action.payload.docNumber,
        }

        try {
            yield call(createOperationOperationsByHandPost, {body: mapped});

            yield put(common.actions.sendNotification({
                text: 'Операция успешно добавлена',
                type: NotificationType.Success,
            }))

            yield call(closeModal);

        } catch (error) {
            if (error instanceof AxiosError) {
                yield put(common.actions.sendNotification({
                    text: error.message,
                    type: NotificationType.Error,
                }))
            }
        }
    }
    if (isOType._2) {
        const taxesArr: ReturnType<typeof taxes.actions.createOperations>['payload']['taxes'] = [{
            amount: action.payload.amount,
            date,
            doc_number: action.payload.docNumber,
        }];

        yield put(taxes.actions.createOperations({taxes: taxesArr, taxPeriod: DateTime.now().year}))
    }
}


export const sagas = [
    takeLatest(sagaActions.loadTransactions.type,
        generatorWrap(
            loadTransactions,
            mixinWithErrorHandler(slice.actions.setTransactionListMeta),
            mixinWithLoadingHandler(slice.actions.setTransactionListMeta),
            mixinWithInitAwait(),
        ),
    ),
    takeLatest(sagaActions.removeTransaction.type,
        generatorWrap(
            removeTransaction,
            mixinWithErrorHandler(slice.actions.setTransactionRemoveMeta),
            mixinWithLoadingHandler(slice.actions.setTransactionRemoveMeta),
        ),
    ),
    takeLatest(sagaActions.updateOperationType.type,
        generatorWrap(
            updateOperationType,
            mixinWithErrorHandler(slice.actions.setTransactionRemoveMeta),
            mixinWithLoadingHandler(slice.actions.setTransactionRemoveMeta),
        ),
    ),
    takeLatest(sagaActions.downloadKudir.type,
        generatorWrap(
            downloadKudir,
            mixinWithErrorHandler(slice.actions.setDownloadKudirMeta),
            mixinWithLoadingHandler(slice.actions.setDownloadKudirMeta),
        ),
    ),
    takeLatest(sagaActions.upload1CBankStatementFile.type,
        generatorWrap(
            upload1CBankStatementFile,
            mixinWithErrorHandler(slice.actions.setUpload1CBankStatementFileMeta),
            mixinWithLoadingHandler(slice.actions.setUpload1CBankStatementFileMeta),
        ),
    ),
    takeLatest(sagaActions.manualCreateTransaction.type,
        generatorWrap(
            manualCreateTransaction,
            mixinWithErrorHandler(slice.actions.setManualCreateTransactionMeta),
            mixinWithLoadingHandler(slice.actions.setManualCreateTransactionMeta),
        ),
    ),
];
