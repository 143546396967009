import {motion} from "motion/react";
import {useFormContext} from "react-hook-form";
import React, {ChangeEventHandler, DragEventHandler, useImperativeHandle, useRef, useState} from "react";
import {Download32} from "@/components/icons/Download";
import {Typography} from "@/components/base/Typography";
import {LoadingStatus} from "@/core/store/utils/sagas";
import clsx, {ClassValue} from "clsx";

import styles from './styles.module.scss';
import {Spin} from "@/components/icons/Spin";
import {X32} from "@/components/icons/X";
import {Check32} from "@/components/icons/Check";
import fp from "lodash/fp";
import {PaperClip14} from "@/components/icons/PaperClip";
import {Trash} from "@/components/icons/Trash";

interface IUploadFileProps {
    name: string
    className?: ClassValue
    multiple?: boolean
    title?: React.ReactNode
    label?: React.ReactNode
    required?: boolean
    showFile?: boolean
    description?: React.ReactNode
    disabled?: boolean
    currentState?: LoadingStatus
}

const icons = {
    [LoadingStatus.Never]: Download32,
    [LoadingStatus.Loading]: () => <Spin size={32}/>,
    [LoadingStatus.Error]: X32,
    [LoadingStatus.Loaded]: Check32,
}

export const UploadFile = (
    {
        name,
        label,
        required,
        showFile = false,
        className,
        title = 'Выберите или перетащите файлы',
        description = 'Загрузите файл в формате .txt',
        multiple = false,
        currentState = LoadingStatus.Never,
        disabled = false,
    }: IUploadFileProps,
) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)

    const [cursorHere, setCursorHereBase] = useState(false);

    const setCursorHere = (v: boolean) => {
        if (!disabled) {
            setCursorHereBase(v);
        }
    }

    const {register, setValue, getValues, watch} = useFormContext();

    const {ref, ...inputProps} = register(name);

    const watchValueRaw = watch(name)

    const watchValue = watchValueRaw instanceof FileList ? Array.from(watchValueRaw) : fp.compact(fp.castArray(watchValueRaw));

    useImperativeHandle(ref, () => inputRef.current)

    const dropFiles: DragEventHandler = (args) => {
        args.preventDefault();
        setCursorHere(false);

        if (disabled)
            return false;

        if (multiple) {
            const oldFiles = getValues(name) || [];

            setValue(name, [...oldFiles, ...args.dataTransfer.files], { shouldTouch: true, shouldValidate: true })
        } else {
            setValue(name, args.dataTransfer.files[0], { shouldTouch: true, shouldValidate: true });
        }

        return false;
    };

    const changeFiles: ChangeEventHandler<HTMLInputElement> = (args) => {
        args.preventDefault();
        setCursorHere(false);

        if (disabled)
            return false;

        if (multiple) {
            setValue(name, Array.from(args.target.files as FileList), { shouldTouch: true, shouldValidate: true, shouldDirty: true });
        } else {
            setValue(name, fp.first(args.target.files), { shouldTouch: true, shouldValidate: true, shouldDirty: true });
        }
    };

    const Icon = icons[currentState];

    const currentStateExt = disabled ? 'Disabled' : currentState

    return (
        <div className='vstack gap-8'>
            {label && <Typography type='SmallTextRegular'>
                {label}
                {required && <Typography color='red-primary' as='span'>*</Typography>}
            </Typography>}
            <motion.div
                ref={containerRef}
                onClick={() => inputRef.current?.click()}
                onDragOver={(e) => {
                    e.preventDefault();
                }}
                onDragEnter={() => setCursorHere(true)}
                onDragLeave={(e) => {
                    if (e.relatedTarget && !containerRef.current?.contains(e.relatedTarget as Node))
                        setCursorHere(false);
                }}
                onDrop={dropFiles}
                className={clsx(
                    className,
                    styles.container,
                    styles[`container${currentStateExt}`],
                    styles[`container${currentStateExt}${cursorHere ? 'ThisHover' : ''}`],
                    'd-flex flex-column align-items-center flex-fill gap-16 py-16',
                )}
            >
                <input
                    {...inputProps}
                    ref={inputRef}
                    type='file'
                    hidden
                    onChange={changeFiles}
                    multiple={multiple}
                    disabled={disabled}
                />
                <div
                    className={styles[`container${currentStateExt}Icon`]}
                >
                    <Icon/>
                </div>
                <div
                    className='d-flex flex-column align-items-center flex-fill gap-4 px-16 text-center'
                >
                    <Typography className={styles[`container${currentStateExt}Title`]}>
                        {title}
                    </Typography>
                    <Typography color={disabled ? 'base-secondary-2' : 'base-secondary-1'} type='SmallTextRegular' className={styles.desc}>
                        {description}
                    </Typography>
                </div>
            </motion.div>
            {(showFile && fp.castArray(watchValue).length > 0) && <div className='d-flex flex-column'>
                {fp.castArray(watchValue).map((v, index) => (
                    <Typography key={index} type='LabelSemibold' className='d-flex gap-8 align-items-center px-4'>
                        <PaperClip14/>
                        <span className='w-100'>
                            {v?.name}
                        </span>
                        <Typography type='Inherit' color='brand-primary' onClick={() => {
                            setValue(
                                name,
                                fp.castArray(getValues(name)).filter((_, in2) => index !== in2),
                                { shouldValidate: true, shouldDirty: true, shouldTouch: true },
                            );
                        }}>
                            <Trash />
                        </Typography>
                    </Typography>
                ))}
            </div>}
        </div>
    )
}
