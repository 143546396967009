import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {initialState, INotification, namespace, State} from './inits'
import {UUID} from "node:crypto";
import {
	IMetaInfoError,
	IMetaInfoSimple,
	initialMetaInfoValue,
	NetworkMetaMapKeysType,
} from "@/business/common/types";

export type NameType = keyof State['counters']

export const slice = createSlice({
	name: namespace,
	initialState,
	selectors: {
		getPrettyCounter: ((sliceState, key: NameType) => {
			const counter = sliceState.counters[key];

			if (counter === null) return null;
			if (counter > 9) return '9+';
			if (counter > 0) return counter;

			return null;
		}),
	},
	reducers: {
		setCounter: (
			state,
			action: PayloadAction<{ name: NameType, newValue?: number }>,
		) => {
			state.counters[action.payload.name] = action.payload.newValue || null
		},
		pushNotification: (state, action: PayloadAction<{ id: UUID, notify: INotification }>) => {
			state.notifications[action.payload.id] = action.payload.notify
		},
		updateNotification: (state, action: PayloadAction<{ id: UUID, notify: Partial<INotification> }>) => {
			state.notifications[action.payload.id] = {
				...state.notifications[action.payload.id],
				...action.payload.notify,
			}
		},
		closeNotification: (state, action: PayloadAction<UUID>) => {
			delete state.notifications[action.payload]
		},
		setAsyncExecuteMeta: (state, action: PayloadAction<{ id: NetworkMetaMapKeysType, data: IMetaInfoSimple | IMetaInfoError }>) => {
			const prevState = state.asyncExecuteMetaMap[action.payload.id];

			if (prevState) {
				state.asyncExecuteMetaMap[action.payload.id] = {
					...prevState,
					...action.payload.data,
				}
			} else {
				state.asyncExecuteMetaMap[action.payload.id] = {
					...initialMetaInfoValue,
					...action.payload.data,
				}
			}
		},
		clearAsyncExecuteMeta: (state, action: PayloadAction<NetworkMetaMapKeysType>) => {
			state.asyncExecuteMetaMap[action.payload] = {
				...initialMetaInfoValue,
			}
		},
	},
})