import {FormProvider, useForm} from "react-hook-form";
import {bankBikValidate} from "@/utils/yup/banks";
import {yupResolver} from "@hookform/resolvers/yup";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {business} from "@/business";
import {LoadingStatus} from "@/core/store/utils/sagas";
import {useEffect} from "react";
import {displayBankNameBankReference} from "@/utils/data/sources/format";
import {Input} from "@/components/form/Input";
import {Button} from "@/components/base/Button";
import {Highlighter} from "@/components/composite/Highlighter";
import {DirectBankRegisterSchemaValidate, DirectBankRegisterSchemaValidateType} from "@/business/sources/inits";
import {Spin} from "@/components/icons/Spin";

export const Bank1CDirect = () => {
    const dp = useAppDispatch();

    useEffect(() => {
        return () => {
            dp(business.references.actions.setGetBankInfoByBikMeta({status: LoadingStatus.Never}))
        }
    }, [dp]);

    const bankName = useAppSelector(
        (state) => displayBankNameBankReference(state.references.bankInfoByBik),
    );

    const loading = useAppSelector(
        (state) => state.sources.createAccountLinkMeta.status === LoadingStatus.Loading,
    )

    const bikSearch = useAppSelector(
        (state) => state.references.getBankInfoByBikMeta.message,
    )

    const methods = useForm({
        resolver: yupResolver(DirectBankRegisterSchemaValidate),
        mode: 'onChange',
    });

    const bik = methods.watch('bank_bik');


    useEffect(() => {
        methods.setValue('bank_name', bankName)
    }, [bankName, methods]);

    useEffect(() => {
        if (bikSearch) {
            methods.setError('bank_bik', {message: bikSearch, type: 'autocomplete'});
        } else {
            methods.clearErrors('bank_bik')
        }
    }, [bikSearch, methods]);


    useEffect(() => {
        return methods.watch(({bank_bik}, {name}) => {
            if (name === 'bank_bik') {
                if (bankBikValidate.isValidSync(bank_bik)) {
                    dp(business.references.actions.getBankInfoByBik(bank_bik))
                } else {
                    methods.setValue('bank_name', '')
                }
            }
        }).unsubscribe
    }, [bik, dp, methods]);

    const onSubmit = (d: DirectBankRegisterSchemaValidateType) =>
        dp(business.sources.actions.createAccountIntegration(d))

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} className='d-flex flex-column gap-24 gap-md-32'>
                <div className='d-flex flex-column gap-24'>
                    <Input name='login' label='Логин' required/>
                    <Input name='password' label='Пароль' required type='password' autoComplete='off'/>
                    <Input name='bank_bik' required label='БИК банка' placeholder='Введите'
                           count={{show: true, max: 9}}/>
                    <Input name='bank_name' customErrorPath='__no.bank_name' required
                           label='Наименование банка' placeholder='Название банка' disabled/>
                    <Input name='account_number' required label='Номер банковского счета'
                           placeholder='Введите' count={{show: true, max: 20}}/>
                </div>
                <Highlighter
                    text='Логин и пароль для подключения через 1С:ДиректБанк необходимо взять в ЛК Банка. [Инструкция →](https://www.google.com/url?q=https://drive.google.com/drive/u/1/folders/1yFkiwQuDDGUrHxINyXWVs8x3wtf_aLw_&sa=D&source=docs&ust=1709622100158746&usg=AOvVaw10cI6RyE9EYj20GIbB9DCu)'
                />
                <div className='d-flex flex-column flex-md-row gap-8 gap-16'>
                    <Button className='w-100' type='Secondary' onClick={() => {
                        dp(business.sources.actions.prevStep())
                    }}>
                        ← Назад
                    </Button>
                    <Button className='w-100' disabled={loading || !methods.formState.isValid} htmlType='submit'>
                        {loading && <Spin />}
                        Подключить
                    </Button>
                </div>
            </form>
        </FormProvider>

    )
}