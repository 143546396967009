export const Info20 = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_3080_91011" style={{"maskType": "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="20"
              height="20">
            <rect width="20" height="20" fill='currentColor'/>
        </mask>
        <g mask="url(#mask0_3080_91011)">
            <path
                d="M10.0009 13.957C10.1781 13.957 10.3265 13.8971 10.4461 13.7773C10.5658 13.6575 10.6256 13.5091 10.6256 13.332V9.79032C10.6256 9.61326 10.5657 9.46482 10.4459 9.34503C10.326 9.22524 10.1775 9.16535 10.0004 9.16535C9.82319 9.16535 9.67478 9.22524 9.55513 9.34503C9.43548 9.46482 9.37565 9.61326 9.37565 9.79032V13.332C9.37565 13.5091 9.43557 13.6575 9.5554 13.7773C9.67525 13.8971 9.82375 13.957 10.0009 13.957ZM10.0006 7.73907C10.1913 7.73907 10.3512 7.67457 10.4802 7.54557C10.6092 7.41657 10.6737 7.25672 10.6737 7.06601C10.6737 6.87532 10.6092 6.71546 10.4802 6.58645C10.3512 6.45745 10.1913 6.39295 10.0006 6.39295C9.80992 6.39295 9.65007 6.45745 9.52107 6.58645C9.39207 6.71546 9.32757 6.87532 9.32757 7.06601C9.32757 7.25672 9.39207 7.41657 9.52107 7.54557C9.65007 7.67457 9.80992 7.73907 10.0006 7.73907ZM10.002 17.9153C8.90708 17.9153 7.87789 17.7075 6.91444 17.292C5.95098 16.8764 5.11292 16.3125 4.40023 15.6001C3.68754 14.8877 3.12332 14.05 2.70759 13.087C2.29185 12.124 2.08398 11.095 2.08398 10.0001C2.08398 8.90513 2.29176 7.87593 2.70732 6.91249C3.12287 5.94903 3.68683 5.11096 4.39919 4.39828C5.11157 3.68559 5.94927 3.12137 6.9123 2.70564C7.87531 2.2899 8.90429 2.08203 9.99923 2.08203C11.0942 2.08203 12.1234 2.28981 13.0868 2.70537C14.0503 3.12092 14.8883 3.68488 15.601 4.39724C16.3137 5.10962 16.8779 5.94732 17.2937 6.91035C17.7094 7.87336 17.9173 8.90234 17.9173 9.99728C17.9173 11.0922 17.7095 12.1214 17.2939 13.0849C16.8784 14.0483 16.3144 14.8864 15.6021 15.5991C14.8897 16.3118 14.052 16.876 13.089 17.2917C12.126 17.7075 11.097 17.9153 10.002 17.9153ZM10.0006 16.6653C11.8617 16.6653 13.4381 16.0195 14.7298 14.7278C16.0215 13.4362 16.6673 11.8598 16.6673 9.99868C16.6673 8.13757 16.0215 6.56118 14.7298 5.26951C13.4381 3.97784 11.8617 3.33201 10.0006 3.33201C8.13952 3.33201 6.56313 3.97784 5.27146 5.26951C3.9798 6.56118 3.33396 8.13757 3.33396 9.99868C3.33396 11.8598 3.9798 13.4362 5.27146 14.7278C6.56313 16.0195 8.13952 16.6653 10.0006 16.6653Z"
                fill='currentColor'/>
        </g>
    </svg>
)

export const Info16 = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='d-block'>
        <mask id="mask0_4222_76016" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="16"
              height="16">
            <rect width="16" height="16" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_4222_76016)">
            <path
                d="M7.99955 11.166C8.14128 11.166 8.26001 11.1181 8.35573 11.0222C8.45145 10.9264 8.49932 10.8077 8.49932 10.666V7.83265C8.49932 7.69099 8.45138 7.57225 8.35552 7.47642C8.25964 7.38058 8.14084 7.33267 7.99912 7.33267C7.85738 7.33267 7.73865 7.38058 7.64293 7.47642C7.54721 7.57225 7.49935 7.69099 7.49935 7.83265V10.666C7.49935 10.8077 7.54728 10.9264 7.64315 11.0222C7.73903 11.1181 7.85783 11.166 7.99955 11.166ZM7.99933 6.19165C8.1519 6.19165 8.27978 6.14005 8.38298 6.03685C8.48618 5.93365 8.53778 5.80577 8.53778 5.6532C8.53778 5.50064 8.48618 5.37276 8.38298 5.26955C8.27978 5.16635 8.1519 5.11475 7.99933 5.11475C7.84677 5.11475 7.71888 5.16635 7.61568 5.26955C7.51248 5.37276 7.46088 5.50064 7.46088 5.6532C7.46088 5.80577 7.51248 5.93365 7.61568 6.03685C7.71888 6.14005 7.84677 6.19165 7.99933 6.19165ZM8.00045 14.3327C7.12449 14.3327 6.30114 14.1664 5.53038 13.834C4.75962 13.5015 4.08916 13.0504 3.51902 12.4805C2.94886 11.9106 2.49749 11.2404 2.1649 10.47C1.83231 9.69959 1.66602 8.8764 1.66602 8.00045C1.66602 7.12449 1.83224 6.30114 2.16468 5.53038C2.49713 4.75962 2.94829 4.08916 3.51818 3.51902C4.08808 2.94886 4.75824 2.49749 5.52867 2.1649C6.29908 1.83231 7.12226 1.66602 7.99822 1.66602C8.87417 1.66602 9.69753 1.83224 10.4683 2.16468C11.239 2.49713 11.9095 2.94829 12.4796 3.51818C13.0498 4.08808 13.5012 4.75824 13.8338 5.52867C14.1664 6.29908 14.3327 7.12226 14.3327 7.99822C14.3327 8.87417 14.1664 9.69753 13.834 10.4683C13.5015 11.239 13.0504 11.9095 12.4805 12.4796C11.9106 13.0498 11.2404 13.5012 10.47 13.8338C9.69959 14.1664 8.8764 14.3327 8.00045 14.3327ZM7.99933 13.3327C9.48822 13.3327 10.7493 12.816 11.7827 11.7827C12.816 10.7493 13.3327 9.48822 13.3327 7.99933C13.3327 6.51044 12.816 5.24933 11.7827 4.216C10.7493 3.18267 9.48822 2.666 7.99933 2.666C6.51044 2.666 5.24933 3.18267 4.216 4.216C3.18267 5.24933 2.666 6.51044 2.666 7.99933C2.666 9.48822 3.18267 10.7493 4.216 11.7827C5.24933 12.816 6.51044 13.3327 7.99933 13.3327Z"
                fill='currentColor'/>
        </g>
    </svg>
)

export const Info = (
    {color = 'currentColor'}: { color?: string },
) => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className='d-block'>
        <mask id="mask0_1682_42756" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="14"
              height="14">
            <rect width="14" height="14" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_1682_42756)">
            <path
                d="M7.00034 9.77048C7.12435 9.77048 7.22824 9.72855 7.312 9.6447C7.39576 9.56085 7.43763 9.45695 7.43763 9.33299V6.85381C7.43763 6.72986 7.39569 6.62596 7.31181 6.54211C7.22792 6.45825 7.12397 6.41633 6.99996 6.41633C6.87594 6.41633 6.77206 6.45825 6.6883 6.54211C6.60454 6.62596 6.56266 6.72986 6.56266 6.85381V9.33299C6.56266 9.45695 6.6046 9.56085 6.68849 9.6447C6.77238 9.72855 6.87633 9.77048 7.00034 9.77048ZM7.00015 5.41794C7.13364 5.41794 7.24554 5.37279 7.33584 5.28249C7.42614 5.19219 7.47129 5.08029 7.47129 4.94679C7.47129 4.81331 7.42614 4.70141 7.33584 4.6111C7.24554 4.5208 7.13364 4.47565 7.00015 4.47565C6.86665 4.47565 6.75475 4.5208 6.66445 4.6111C6.57415 4.70141 6.529 4.81331 6.529 4.94679C6.529 5.08029 6.57415 5.19219 6.66445 5.28249C6.75475 5.37279 6.86665 5.41794 7.00015 5.41794ZM7.00112 12.5413C6.23466 12.5413 5.51423 12.3959 4.83982 12.105C4.1654 11.8141 3.57875 11.4193 3.07987 10.9207C2.58099 10.422 2.18603 9.83561 1.89502 9.16149C1.604 8.48738 1.4585 7.7671 1.4585 7.00064C1.4585 6.23418 1.60394 5.51374 1.89483 4.83933C2.18572 4.16491 2.58049 3.57826 3.07914 3.07938C3.5778 2.5805 4.1642 2.18555 4.83832 1.89453C5.51242 1.60352 6.23271 1.45801 6.99917 1.45801C7.76563 1.45801 8.48607 1.60345 9.16048 1.89434C9.8349 2.18523 10.4215 2.58 10.9204 3.07865C11.4193 3.57732 11.8143 4.16371 12.1053 4.83783C12.3963 5.51194 12.5418 6.23222 12.5418 6.99868C12.5418 7.76514 12.3964 8.48558 12.1055 9.15999C11.8146 9.83441 11.4198 10.4211 10.9212 10.9199C10.4225 11.4188 9.8361 11.8138 9.16198 12.1048C8.48787 12.3958 7.76759 12.5413 7.00112 12.5413ZM7.00015 11.6663C8.30293 11.6663 9.4064 11.2142 10.3106 10.3101C11.2147 9.40591 11.6668 8.30244 11.6668 6.99966C11.6668 5.69688 11.2147 4.59341 10.3106 3.68924C9.4064 2.78508 8.30293 2.33299 7.00015 2.33299C5.69737 2.33299 4.5939 2.78508 3.68973 3.68924C2.78556 4.59341 2.33348 5.69688 2.33348 6.99966C2.33348 8.30244 2.78556 9.40591 3.68973 10.3101C4.5939 11.2142 5.69737 11.6663 7.00015 11.6663Z"
                fill={color}/>
        </g>
    </svg>
)