import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import {Settings} from "luxon";

import ru from './translations/ru.json';
import {logger} from "@/utils/logger";


export const resources = {
    ru: {
        translation: ru,
    },
} as const;

i18next
    .use(initReactI18next)
    .init({
        debug: false,
        lng: 'ru',
        resources,
    })
    .then(() => {
        Settings.defaultLocale = i18next.language;
        logger.info('Trying change locale to: ', i18next.language, ', result:', Settings.defaultLocale)
    });

declare module "i18next" {
    interface CustomTypeOptions {
        // defaultNS: typeof defaultNS;
        resources: typeof resources["ru"];
    }
}
