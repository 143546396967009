import styles from './styles.module.scss';
import clsx from "clsx";
import fp from "lodash/fp";
import {useEffect, useMemo, useState} from "react";

interface IStringSkeletonProps {
	type?: 'string' | 'square' | 'circle'
	width?: number | string | 'randomly'
}

const computeWidth = (width?: number | string) => {
	switch (typeof width) {
		case 'string':
			if (width === 'randomly') return `${fp.random(10, 100)}%`

			return width;
		case 'number':
			return `${width}%`
		default:
			return undefined;
	}
}

export const StringSkeleton = (
	{
		width = 'randomly',
		type = 'string',
	}: IStringSkeletonProps,
) => {
	const [isClient, setIsClient] = useState(false);
	useEffect(() => {
		setIsClient(true)
	}, [])

	const computedWidth = useMemo(() => computeWidth(width), [width]);

	if (!isClient) return null;

	return (
		<div
			className={clsx(styles.container, styles[`container${type}`])}
			style={{width: computedWidth}}
		/>
	)
}