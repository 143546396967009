import {IModalProps} from "@/components/wrappers/Modals/consts";
import {useSafeCloseModal} from "@/utils/common/modals";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import commonStyles from "@/components/wrappers/Modals/components/common.module.scss";
import styles from "@/components/wrappers/Modals/components/ENSAnalyzeRequest/styles.module.scss";
import {Button} from "@/components/base/Button";
import {X} from "@/components/icons/X";
import clsx from "clsx";
import {FormProvider, useForm} from "react-hook-form";
import {useRef} from "react";
import { Typography } from "@/components/base/Typography";
import {Input} from "@/components/form/Input";
import {business} from "@/business";
import {asyncExecMetaSelector} from "@/business/common/selectors";
import fp from "lodash/fp";
import {LoadingStatus} from "@/core/store/utils/sagas";


export const GoodbyeService = (
    {currentId}: IModalProps,
) => {
    const contentRef = useRef(null);
    const close = useSafeCloseModal(currentId);

    const dp = useAppDispatch();

    const meta = useAppSelector(
        asyncExecMetaSelector('users/disableSelf'),
        fp.isEqual,
    )

    useOnClickOutside(
        close,
        contentRef,
    )

    const methods = useForm<{ desc: string }>({
        reValidateMode: 'onChange',
    });

    const submit = methods.handleSubmit((data) => {
        dp(business.users.actions.disableSelf(data.desc))
    })

    return (
        <div className='position-absolute'>
            <div className={clsx(commonStyles.overlay, 'position-relative')}>
                <div className={clsx(commonStyles.content, 'align-self-start align-self-md-center', styles.content)} ref={contentRef}>
                    <Button
                        type='TextButton'
                        className={commonStyles.closeBtn}
                        onClick={close}
                    >
                        <X/>
                    </Button>
                    <FormProvider {...methods}>
                        <form className='vstack gap-32' onSubmit={submit}>
                            <Typography type='LargeTextSemibold' typesWithMedia={['H4Headermd']}>
                                Отключить сервис
                            </Typography>
                            <div className='vstack gap-16'>
                                <Typography>
                                    Жаль, что вы покидаете нас :(
                                </Typography>
                                <Typography>
                                    Расскажите, почему вы уходите
                                </Typography>
                                <Input name='desc' />
                                <Typography>
                                    Спасибо, что пользовались АК ❤️
                                </Typography>
                            </div>
                            <div className='d-flex flex-column flex-md-row gap-8'>
                                <Button
                                    className='w-100'
                                    type='Secondary'
                                    disabled={meta.status === LoadingStatus.Loading}
                                    htmlType='submit'
                                >
                                    Отключить сервис
                                </Button>
                                <Button
                                    onClick={close}
                                    className='w-100'
                                >
                                    Остаться
                                </Button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    )
}
