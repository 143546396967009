import React, {useMemo} from "react";
import fp from "lodash/fp";
import {Typography} from "@/components/base/Typography";
import * as runtime from 'react/jsx-runtime'
import {evaluateSync} from "@mdx-js/mdx";
import {LinkButton} from "@/components/base/LinkButton";
import {logger} from "@/utils/logger";
import clsx from "clsx";

import styles from './styles.module.scss';

export enum FormatType {
	currency = 'currency',
	link = 'link',
}

interface IHighlighterProps {
	type?: FormatType | FormatType[]
	text?: string | null
	mdxComponentsOverride?: Record<string, React.FC>
}

const floatWrap = (floatPart: string) => `<Typography as='span' color='base-secondary-1' type='Inherit'>${floatPart}</Typography>`


export const HighlighterComponent = (
	{
		type = [],
		text = '',
		mdxComponentsOverride = {},
	}: IHighlighterProps,
) => {
	const MDXContent = useMemo(() => {
		const newText = fp.pipe(
			type === FormatType.currency || type.includes(FormatType.currency)
				? fp.replace(/(,\d{2}\s\S)/gm, floatWrap)
				: fp.clone,
		);

		try {
			return evaluateSync(newText(text || ''), {...runtime}).default
		} catch {
			logger.warn('Parsing error. Source text (will be printed as is):', text)
		}

		return () => newText(text || '')
	}, [text, type])


	return (
		<MDXContent
			components={{
				Typography,
				a: ({...p}) => <LinkButton {...p} type='Inherit' />,
				p: ({children}) => <Typography type='Inherit' color='inherit' as='p'>{children}</Typography>,
				li: ({ children }) => <Typography type='Inherit' color='inherit' as='li'>{children}</Typography>,
				ul: ({ children }) => <Typography type='Inherit' color='inherit' className={clsx(styles.ul)} as='ul'>{children}</Typography>,
				...mdxComponentsOverride,
			}}
		/>
	)
}

export const Highlighter = React.memo(HighlighterComponent);
