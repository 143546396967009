import fp from 'lodash/fp'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {initialState, namespace} from './inits'
import {IPayloadForAction} from "@/core/store/utils/sagas";
import {RequestState, SourcesInfo, SourceType} from "@/services/openapi";

export const slice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		setMeta: (state, action: PayloadAction<IPayloadForAction>) => {
			state.sendLeadMeta = action.payload;
		},
		setBankAccounts: (state, action: PayloadAction<SourcesInfo>) => {
			state.debtAccounts = fp.filter(
				{ type: SourceType.account, state: RequestState.completed, disable_date: null },
				action.payload.sources,
			);
		},
		setDebtAccountsMeta: (state, action: PayloadAction<IPayloadForAction>) => {
			state.debtAccountsMeta = action.payload;
		},
	},
})
