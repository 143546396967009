import {LoadingStatus} from "@/core/store/utils/sagas"

export interface StatusObjectProto<Return> {
	onLoaded: Return
	onError: Return
	onLoading: Return
	onNever: Return
}

interface StatusObjectWithFallback<Return> extends Partial<StatusObjectProto<Return>> {
	fallback: Return
}

export type StatusObject<Return> = StatusObjectProto<Return> | StatusObjectWithFallback<Return>;


export const statusCondition = <Return>(
	status: LoadingStatus,
	{onNever, onLoading, onLoaded, onError, ...fb}: StatusObject<Return>,
): Return => {
	switch (status) {
		case LoadingStatus.Never: {
			if (onNever) {
				return onNever;
			}

			break;
		}
		case LoadingStatus.Loading: {
			if (onLoading) {
				return onLoading;
			}

			break;
		}
		case LoadingStatus.Loaded: {
			if (onLoaded) {
				return onLoaded;
			}

			break;
		}
		case LoadingStatus.Error: {
			if (onError) {
				return onError;
			}

			break;
		}
	}

	if ('fallback' in fb) {
		return fb.fallback;
	}

	throw Error('Ты что на типы не смотришь?');
};