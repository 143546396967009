import {IModalProps} from "@/components/wrappers/Modals/consts";
import {useSafeCloseModal} from "@/utils/common/modals";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import commonStyles from "@/components/wrappers/Modals/components/common.module.scss";
import styles from "@/components/wrappers/Modals/components/ENSAnalyzeRequest/styles.module.scss";
import {Button} from "@/components/base/Button";
import {X} from "@/components/icons/X";
import clsx from "clsx";
import {useRef} from "react";
import {Typography} from "@/components/base/Typography";
import {FormProvider, useForm} from "react-hook-form";
import {Select} from "@/components/form/Select";
import * as yup from "yup";
import i18next from "i18next";
import {yupEnum} from "@/utils/yup/common";
import {KudirFormat} from "@/services/openapi";
import {yupResolver} from "@hookform/resolvers/yup";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {DateTime} from "luxon";
import fp from "lodash/fp";
import {business} from "@/business";
import {clearData} from "@/utils/forms/clearData";
import {LoadingStatus} from "@/core/store/utils/sagas";
import {Spin} from "@/components/icons/Spin";


const schemaValidate = yup.object({
    period_year: yup.number().required(() => i18next.t('common.errors.yup.common.required')),
    kudir_format: yupEnum(KudirFormat).required(() => i18next.t('common.errors.yup.common.required')),
})


export const DownloadKudir = (
    {currentId}: IModalProps,
) => {
    const contentRef = useRef(null);
    const close = useSafeCloseModal(currentId);

    const methods = useForm({
        resolver: yupResolver(schemaValidate),
    });

    const dp = useAppDispatch();

    const status = useAppSelector(
        (s) => s.transactions.downloadKudirMeta.status === LoadingStatus.Loading,
    )

    const years = useAppSelector(
        (s) => {
            const sDate = s.auth.user?.tax_date_begin;

            if (sDate) {
                const dateBegin = DateTime.fromSQL(sDate);
                const yearBegin = dateBegin.year;
                return Array(DateTime.now().year - yearBegin + 1).fill(null).map((_, i) => i + yearBegin)
            }

            return [];
        },
        fp.isEqual,
    )

    useOnClickOutside(
        close,
        contentRef,
    )

    return (
        <div className='position-absolute'>
            <div className={clsx(commonStyles.overlay, 'position-relative')}>
                <div className={clsx(commonStyles.content, 'align-self-start align-self-md-center', styles.content)}
                     ref={contentRef}>
                    <Button
                        type='TextButton'
                        className={commonStyles.closeBtn}
                        onClick={close}
                    >
                        <X/>
                    </Button>
                    <FormProvider {...methods}>
                        <form className='vstack gap-32' onSubmit={methods.handleSubmit((d) => {
                            dp(business.transactions.actions.downloadKudir(clearData(d)))
                        })}>
                            <Typography type='H4Header'>
                                Книга учета доходов и расходов
                            </Typography>
                            <div className='vstack gap-24'>
                                <Select name='period_year' values={years} required label='Налоговый период'/>
                                <Select
                                    name='kudir_format'
                                    required
                                    label='Формат файла'
                                    values={[KudirFormat.pdf, KudirFormat.xlsx]}
                                />
                            </div>
                            <div className='d-flex flex-column flex-md-row gap-16'>
                                <Button className='w-100' type='Secondary' onClick={close} disabled={status}>
                                    Отменить
                                </Button>
                                <Button className='w-100' htmlType='submit' disabled={status || !methods.formState.isValid}>
                                    {status && <Spin/>}
                                    Скачать КУДиР
                                </Button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    )
}