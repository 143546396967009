import {Typography} from "@/components/base/Typography";
import {Check} from "@/components/icons/Check";
import {useFormContext} from "react-hook-form";

import styles from './styles.module.scss';
import clsx from "clsx";


interface ICheckboxProps {
    disabled?: boolean;
    label: string,
    name: string
}

export const Checkbox = (
    {
        label,
        name,
        disabled = false,
    }: ICheckboxProps,
) => {
    const {register, watch, setValue} = useFormContext();

    const valueWatch = watch(name);

    const disabledColor = disabled && 'base-secondary-2'

    return (
        <div
            tabIndex={-1}
            role='checkbox'
            aria-checked={valueWatch}
            className={clsx('d-flex gap-8', styles.container)}
            onClick={
                disabled
                    ? undefined
                    : () => setValue(name, !valueWatch, {shouldValidate: true, shouldTouch: true, shouldDirty: true})
            }
        >
            <input hidden {...register(name)} disabled={disabled}/>
            <Typography type='Inherit' color={disabledColor || 'base-color-white'} className={clsx(
                styles.iconBox,
                valueWatch && styles.iconBoxSelected,
                disabled && styles.iconBoxDisabled,
                'd-flex align-items-center justify-content-center',
            )}>
                {valueWatch && <Check/>}
            </Typography>
            <Typography type='SmallTextRegular' color={disabledColor || 'base-primary-dark'}>
                {label}
            </Typography>
        </div>
    )
}
