import {Controller, useFormContext} from "react-hook-form"
import {Input as AntInput} from 'antd';
import {Typography} from "@/components/base/Typography";

import styles from './styles.module.scss';
import fp from "lodash/fp";
import clsx, {ClassValue} from "clsx";
import React, { HTMLInputAutoCompleteAttribute } from "react";

interface IInputProps {
	name: string
	label?: React.ReactNode
	type?: 'text' | 'password' | 'number'
	required?: boolean
	hidden?: boolean
	disabled?: boolean
	customErrorPath?: string
	autoComplete?: HTMLInputAutoCompleteAttribute
	placeholder?: string
	step?: number | 'any'
	className?: ClassValue
	count?: {
		show: boolean,
		max: number,
	}
}

export const Input = (
	{
		name,
		label,
		type = 'text',
		hidden = false,
		autoComplete,
		placeholder = 'Введите',
		required = false,
		step = 'any',
		disabled = false,
		className,
		customErrorPath,
		count,
	}: IInputProps,
) => {
	const {control, formState} = useFormContext()

	const error = fp.get(`errors.${customErrorPath || name}.message`, formState) as string;

	return (
		<Typography className={clsx(className, styles.container)} type='LabelRegular'>
			<Typography type='SmallTextRegular' className='d-flex gap-4'>
				{label}
				{required && <Typography color='red-primary' as='span' type='SmallLabel' className='align-self-start'>*</Typography>}
			</Typography>
			<Controller
				name={name}
				control={control}
				render={({field}) =>
					<AntInput
						{...field}
						type={type}
						hidden={hidden}
						disabled={disabled}
						autoComplete={autoComplete}
						className={styles.antInput}
						placeholder={placeholder}
						step={step}
						status={error && 'error'}
						count={count}
					/>
				}
			/>
			{error && (
				<Typography type='SmallLabel' color='red-primary' as='span'>
					{error}
				</Typography>
			)}
		</Typography>
	)
}
