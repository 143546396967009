import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {initialState, namespace} from './inits'
import {ReportFormat, TaskResponse} from "@/services/openapi";
import {IPayloadForAction} from "@/core/store/utils/sagas";
import {DateTime} from "luxon";


export const slice = createSlice({
	name: namespace,
	initialState,
	selectors: {},
	reducers: {
		setListEntity: (state, action: PayloadAction<TaskResponse>) => {
			state.list.entity = action.payload
		},
		updateEntityReportCode: (state, action: PayloadAction<{ id: number, code: string }>) => {
			if (state.list.entity) {
				state.list.entity.tasks[action.payload.id].report_code = action.payload.code
				state.list.entity.tasks[action.payload.id].report_update = DateTime.now().toISO();
			}
		},
		setListMeta: (state, action: PayloadAction<IPayloadForAction>) => {
			state.list.meta.status = action.payload.status
			state.list.meta.errorMessage = action.payload.message || null
		},
		setGenerateDocMeta: (state, action: PayloadAction<IPayloadForAction>) => {
			state.generateDocMeta.status = action.payload.status
			state.generateDocMeta.message = action.payload.message
		},
		setConfirmSubmitReportMeta: (state, action: PayloadAction<IPayloadForAction>) => {
			state.confirmSubmitReportMeta.status = action.payload.status
			state.confirmSubmitReportMeta.message = action.payload.message
		},
		setGenerateDocMetaId: (state, action: PayloadAction<{ id: number, type?: ReportFormat | null }>) => {
			state.generateDocMeta.id = action.payload.id
			const type = action.payload.type
			if (type) state.generateDocMeta.type = type
		},
		setListBlockReload: (state, action: PayloadAction<number | null>) => {
			state.list.meta.blockUpTo = action.payload;
		},
	},
})