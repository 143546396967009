import {useFormContext} from "react-hook-form";
import {DateTime} from "luxon";
import {useState} from "react";

import styles from './styles.module.scss';
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import {Button} from "@/components/base/Button";
import {Typography} from "@/components/base/Typography";
import {MonthDays} from "@/components/form/DatePicker/Calendar/MonthDays";

interface ICalendarProps {
    name: string,
    close: () => void,
    maxDate?: DateTime,
    minDate?: DateTime,
    iternal?: 'left' | 'right'
    hoverDate?: DateTime | null
    setHoverDate?: (v: DateTime | null) => void
}

export const Calendar = (
    {
        name,
        close,
        maxDate,
        minDate,
        iternal,
        hoverDate,
        setHoverDate,
    }: ICalendarProps,
) => {
    const {t} = useTranslation();

    const {watch, setValue} = useFormContext();

    const value = watch(name) as Date

    const [iternalValue, setIternalValue] = useState(DateTime
        .fromJSDate(value || DateTime.now().toJSDate())
        .plus({month: iternal === 'right' ? 1 : 0}),
    );

    const jsDateIternal = iternalValue.toJSDate();

    const setToday = () => {
        setIternalValue(DateTime.now());
        setValue(name, DateTime.now().toJSDate(), {shouldValidate: true, shouldDirty: true, shouldTouch: true});
    }

    return (
        <div
            className={clsx(styles.container, iternal || styles.containerStandalone)}
        >
            <div className={clsx(styles.blockDividerBottom, 'd-flex px-16 py-8 gap-8 align-items-center')}>
                <Button
                    type='TextButton'
                    onClick={() => setIternalValue((d) => d.minus({year: 1}))}
                >
                    <Typography color='base-secondary-2'>
                        {'<<'}
                    </Typography>
                </Button>
                <Button type='TextButton' onClick={() => setIternalValue((d) => d.minus({month: 1}))}>
                    <Typography color='base-secondary-2'>
                        {'<'}
                    </Typography>
                </Button>
                <div className='d-flex gap-8 mx-auto align-items-center'>
                    <Typography className='d-flex' type='SmallTextSemibold' color='base-primary-dark'>
                        {t('common.picker.month', {val: jsDateIternal})}
                    </Typography>
                    <Typography className='d-flex' type='SmallTextSemibold' color='base-primary-dark'>
                        {t('common.picker.year', {val: jsDateIternal})}
                    </Typography>
                </div>
                <Button
                    type='TextButton'
                    onClick={() => setIternalValue((d) => d.plus({month: 1}))}
                >
                    <Typography color='base-secondary-2'>
                        {'>'}
                    </Typography>
                </Button>
                <Button type='TextButton' onClick={() => setIternalValue((d) => d.plus({year: 1}))}>
                    <Typography color='base-secondary-2'>
                        {'>>'}
                    </Typography>
                </Button>
            </div>
            <MonthDays
                hoverDate={hoverDate}
                setHoverDate={setHoverDate}
                selectedDate={iternalValue}
                value={DateTime.fromJSDate(value)}
                maxDate={maxDate}
                minDate={minDate}
                setNewDate={(nw) => setValue(name, nw.toJSDate(), {shouldValidate: true})}
            />
            {!iternal ?
                <div className={clsx('d-flex justify-content-between px-16 py-8', styles.blockDividerTop)}>
                    <Button type='TextButton' onClick={setToday}>
                        Сегодня
                    </Button>
                    <Button type='Primary' className='py-2 px-8' onClick={close}>
                        Подтвердить
                    </Button>
                </div>
                : null}
        </div>
    )
}
