export const Download32 = () => (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M28.5 19V26C28.5 26.5304 28.2893 27.0391 27.9142 27.4142C27.5391 27.7893 27.0304 28 26.5 28H6.5C5.96957 28 5.46086 27.7893 5.08579 27.4142C4.71071 27.0391 4.5 26.5304 4.5 26V19C4.5 18.7348 4.60536 18.4804 4.79289 18.2929C4.98043 18.1054 5.23478 18 5.5 18C5.76522 18 6.01957 18.1054 6.20711 18.2929C6.39464 18.4804 6.5 18.7348 6.5 19V26H26.5V19C26.5 18.7348 26.6054 18.4804 26.7929 18.2929C26.9804 18.1054 27.2348 18 27.5 18C27.7652 18 28.0196 18.1054 28.2071 18.2929C28.3946 18.4804 28.5 18.7348 28.5 19ZM15.7925 19.7075C15.8854 19.8005 15.9957 19.8742 16.1171 19.9246C16.2385 19.9749 16.3686 20.0008 16.5 20.0008C16.6314 20.0008 16.7615 19.9749 16.8829 19.9246C17.0043 19.8742 17.1146 19.8005 17.2075 19.7075L22.2075 14.7075C22.3004 14.6146 22.3741 14.5043 22.4244 14.3829C22.4747 14.2615 22.5006 14.1314 22.5006 14C22.5006 13.8686 22.4747 13.7385 22.4244 13.6171C22.3741 13.4957 22.3004 13.3854 22.2075 13.2925C22.1146 13.1996 22.0043 13.1259 21.8829 13.0756C21.7615 13.0253 21.6314 12.9994 21.5 12.9994C21.3686 12.9994 21.2385 13.0253 21.1171 13.0756C20.9957 13.1259 20.8854 13.1996 20.7925 13.2925L17.5 16.5863V5C17.5 4.73478 17.3946 4.48043 17.2071 4.29289C17.0196 4.10536 16.7652 4 16.5 4C16.2348 4 15.9804 4.10536 15.7929 4.29289C15.6054 4.48043 15.5 4.73478 15.5 5V16.5863L12.2075 13.2925C12.0199 13.1049 11.7654 12.9994 11.5 12.9994C11.2346 12.9994 10.9801 13.1049 10.7925 13.2925C10.6049 13.4801 10.4994 13.7346 10.4994 14C10.4994 14.2654 10.6049 14.5199 10.7925 14.7075L15.7925 19.7075Z"
            fill='currentColor'/>
    </svg>
)

export const Download = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.25 8.3125V11.375C12.25 11.6071 12.1578 11.8296 11.9937 11.9937C11.8296 12.1578 11.6071 12.25 11.375 12.25H2.625C2.39294 12.25 2.17038 12.1578 2.00628 11.9937C1.84219 11.8296 1.75 11.6071 1.75 11.375V8.3125C1.75 8.19647 1.79609 8.08519 1.87814 8.00314C1.96019 7.92109 2.07147 7.875 2.1875 7.875C2.30353 7.875 2.41481 7.92109 2.49686 8.00314C2.57891 8.08519 2.625 8.19647 2.625 8.3125V11.375H11.375V8.3125C11.375 8.19647 11.4211 8.08519 11.5031 8.00314C11.5852 7.92109 11.6965 7.875 11.8125 7.875C11.9285 7.875 12.0398 7.92109 12.1219 8.00314C12.2039 8.08519 12.25 8.19647 12.25 8.3125ZM6.69047 8.62203C6.7311 8.66271 6.77935 8.69498 6.83246 8.717C6.88558 8.73901 6.94251 8.75034 7 8.75034C7.05749 8.75034 7.11442 8.73901 7.16754 8.717C7.22065 8.69498 7.2689 8.66271 7.30953 8.62203L9.49703 6.43453C9.53768 6.39388 9.56992 6.34563 9.59192 6.29252C9.61392 6.23941 9.62524 6.18249 9.62524 6.125C9.62524 6.06751 9.61392 6.01059 9.59192 5.95748C9.56992 5.90437 9.53768 5.85612 9.49703 5.81547C9.45638 5.77482 9.40813 5.74258 9.35502 5.72058C9.30191 5.69858 9.24499 5.68726 9.1875 5.68726C9.13001 5.68726 9.07309 5.69858 9.01998 5.72058C8.96687 5.74258 8.91862 5.77482 8.87797 5.81547L7.4375 7.25648V2.1875C7.4375 2.07147 7.39141 1.96019 7.30936 1.87814C7.22731 1.79609 7.11603 1.75 7 1.75C6.88397 1.75 6.77269 1.79609 6.69064 1.87814C6.60859 1.96019 6.5625 2.07147 6.5625 2.1875V7.25648L5.12203 5.81547C5.03994 5.73338 4.9286 5.68726 4.8125 5.68726C4.6964 5.68726 4.58506 5.73338 4.50297 5.81547C4.42088 5.89756 4.37476 6.0089 4.37476 6.125C4.37476 6.2411 4.42088 6.35244 4.50297 6.43453L6.69047 8.62203Z"
            fill='currentColor'/>
    </svg>
)
