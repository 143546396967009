import React, {PropsWithChildren, useRef, useState} from "react";
import {AnimatePresence, motion} from 'motion/react'
import {arrow, autoUpdate, FloatingArrow, FloatingPortal, offset, Placement, shift, useFloating} from '@floating-ui/react';
import {Typography} from "@/components/base/Typography";
import clsx, {ClassValue} from "clsx";
import {Null} from "@/components/base/Null";

import styles from './styles.module.scss';
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import {dropdownFloatingPortalKey} from "@/utils/common/dropdown";

interface ITooltipProps {
	floatingContent?: React.FC | null
	floatingContentMaxWidth?: string | number | null
	placement?: Placement
	className?: ClassValue
}

export const Tooltip = (
	{
		floatingContent: FloatingContent = Null,
		floatingContentMaxWidth,
		placement = 'top',
		className,
		children,
	}: PropsWithChildren<ITooltipProps>,
) => {
	const [show, setShow] = useState(false);

	const arrowRef = useRef(null);

	const {refs, floatingStyles, context} = useFloating({
		open: show,
		onOpenChange: setShow,
		middleware: [
			offset(6),
			shift(),
			arrow({element: arrowRef}),
		],
		whileElementsMounted: autoUpdate,
		placement,
	});

	useOnClickOutside(
		() => setShow(false),
		refs.reference,
	);

	return (
		<>
			<FloatingPortal id={dropdownFloatingPortalKey}>
				<AnimatePresence>
					{(show && FloatingContent) && (
						<motion.div
							ref={refs.setFloating}
							style={{
								...floatingStyles,
								maxWidth: floatingContentMaxWidth || 'auto',
							}}
							className={styles.tooltipContainer}
							initial={{opacity: 0}}
							animate={{opacity: 1}}
							exit={{opacity: 0}}
						>
							<Typography
								className={styles.tooltipContent}
								color='base-color-white'
								type='SmallTextRegular'
							>
								<FloatingContent/>
							</Typography>
							<FloatingArrow
								ref={arrowRef}
								width={12}
								height={6}
								tipRadius={3}
								fill='var(--base-primary-dark)'
								context={context}
							/>
						</motion.div>
					)}
				</AnimatePresence>
			</FloatingPortal>
			<motion.div
				ref={refs.setReference}
				className={clsx(className)}
				onHoverStart={() => setShow(true)}
				onHoverEnd={() => setShow(false)}
				onTapStart={() => setShow(true)}
				onTapCancel={() => setShow(true)}
			>
				{children}
			</motion.div>
		</>
	)
}
