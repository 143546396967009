import {generatorWrap} from "@/utils/common/functions";
import {mixinWithErrorHandler, mixinWithLoadingHandler} from "@/core/store/utils/sagas";
import {call, Effect, put, takeEvery, takeLatest} from "redux-saga/effects";
import {sagaActions} from "./inits";
import {slice} from "./slice";
import {
    createOperationTaxPaymentOperationsTaxPaymentPost,
    generateEnsOrderTxtTaxesEnsOrderTxtPost,
    getEnsInfoTaxesEnsRequisitesGet,
    recalculationTaxesTaxesRecalculationPut, TaxType,
} from "@/services/openapi";
import {common} from "@/business/common";
import {NotificationType} from "@/business/common/inits";
import {uuid} from "@/utils/common/uuid";
import {DateTime} from "luxon";
import {AxiosError} from "axios";
import {downloadFile} from "@/utils/common/blob";
import {closeModal} from "@/utils/common/modals";
import fp from "lodash/fp";
import {actions} from "@/business/actions";
import {logger} from "@/utils/logger";
import {mixinWithInitAwait} from "@/business/auth/mixins";

function* getEnsInfo(): Generator<Effect> {
    yield put(slice.actions.setEnsInfo((yield call(getEnsInfoTaxesEnsRequisitesGet)).data));
}

export function* recalculateTaxes(): Generator<Effect> {
    try {
        yield call(recalculationTaxesTaxesRecalculationPut);

        yield put(common.actions.sendNotification({
            text: 'Расчет обновлен',
            type: NotificationType.Success,
        }))
    } catch (error) {
        logger.warn(error);

        yield put(common.actions.sendNotification({
            text: 'Ошибка обновления расчета.Попробуйте еще раз',
            type: NotificationType.Error,
        }))
    }
}


function* generatePaymentOrder(action: ReturnType<typeof sagaActions.generatePaymentOrder>): Generator<Effect> {
    const id = uuid.v4();
    yield put(common.actions.sendNotification({
        id,
        text: 'Обработка файла .txt',
        type: NotificationType.Loading,
    }))

    try {
        const resp = yield call(generateEnsOrderTxtTaxesEnsOrderTxtPost, {body: action.payload, responseType: 'blob'});

        downloadFile(resp, `Платежное поручение ЕНС ${DateTime.now().toLocaleString(DateTime.DATE_SHORT)}.txt`);

        yield put(common.actions.updateNotification({
            id,
            notify: {
                text: 'Скачивание платежного поручения в формате .txt',
                type: NotificationType.Success,
            },
        }))

        yield call(closeModal);
    } catch (error) {
        if (error instanceof AxiosError) {
            yield put(common.actions.updateNotification({
                id,
                notify: {
                    text: error.message,
                    type: NotificationType.Error,
                },
            }))
        }
    }
}

function* createOperations(action: ReturnType<typeof sagaActions.createOperations>): Generator<Effect> {
    const id = uuid.v4();
    yield put(common.actions.sendNotification({
        id,
        text: 'Обработка',
        type: NotificationType.Loading,
    }))

    try {
        const mapped = action.payload.taxes.map((f) => ({
            ...f,
            doc_number: fp.isEmpty(f.doc_number) ? null : f.doc_number,
            date: DateTime.fromSeconds(f.date).toSQLDate() as string,
            tax_type: TaxType._4,
            tax_period: action.payload.taxPeriod,
        }))

        yield call(createOperationTaxPaymentOperationsTaxPaymentPost, {body: {tax_payments: mapped}})

        yield put(common.actions.updateNotification({
            id,
            notify: {
                text: 'Уплата налогов и взносов добавлена',
                type: NotificationType.Success,
            },
        }))

        yield call(closeModal);

        yield put(actions.actions.getActionsList());
    } catch (error) {
        if (error instanceof AxiosError) {
            yield put(common.actions.updateNotification({
                id,
                notify: {
                    text: error.message,
                    type: NotificationType.Error,
                },
            }))
        }
    }
}


export const sagas = [
    takeLatest(sagaActions.recalculateTaxes.type, recalculateTaxes),
    takeLatest(sagaActions.getEnsInfo.type,
        generatorWrap(
            getEnsInfo,
            mixinWithErrorHandler(slice.actions.setEnsMeta),
            mixinWithLoadingHandler(slice.actions.setEnsMeta),
            mixinWithInitAwait(),
        ),
    ),
    takeLatest(sagaActions.createOperations.type,
        generatorWrap(
            createOperations,
            mixinWithErrorHandler(slice.actions.setCreateOperationsMeta),
            mixinWithLoadingHandler(slice.actions.setCreateOperationsMeta),
        ),
    ),
    takeEvery(sagaActions.generatePaymentOrder.type,
        generatorWrap(
            generatePaymentOrder,
            mixinWithErrorHandler(slice.actions.setPaymentOrderMeta),
            mixinWithLoadingHandler(slice.actions.setPaymentOrderMeta),
        ),
    ),
];
