import {Typography} from "@/components/base/Typography";
import {ElementType, forwardRef, ForwardRefRenderFunction, PropsWithChildren} from "react";
import clsx from "clsx";

import {IButtonProps} from "./types";

import styles from './styles.module.scss';
import { Spin } from "@/components/icons/Spin";

const baseCss = 'd-flex align-items-center text-center justify-content-center';

export const ButtonRef: ForwardRefRenderFunction<ElementType, PropsWithChildren<IButtonProps>> = (
	{
		onClick,
		onMiddleClick,
		onRightClick,
		type = 'Primary',
		textType = 'SmallTextRegular',
		textColor,
		loading = false,
		iconOnly = false,
		htmlType = 'button',
		className,
		disabled = false,
		children,
	},
	ref,
) => {
	return (
		<Typography
			ref={ref}
			as='button'
			onClick={loading ? undefined : onClick}
			onMiddleClick={loading ? undefined : onMiddleClick}
			onRightClick={loading ? undefined : onRightClick}
			color={textColor}
			type={textType}
			className={clsx(
				styles.base,
				type === 'TextButton'
					? [styles.baseTextButton, 'd-inline-flex']
					: baseCss,
				iconOnly ? styles.baseIconOnly : baseCss,
				styles[`${type}Type`],
				className,
			)}
			additionalProps={{type: htmlType, disabled}}
		>
			{loading ? <span><Spin /></span> : children}
		</Typography>
	)
}

export const Button = forwardRef(ButtonRef)
