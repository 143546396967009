import {DateTime, Info} from "luxon";
import {useMemo} from "react";

import styles from './styles.module.scss'
import {Typography} from "@/components/base/Typography";
import clsx from "clsx";
import fp from "lodash/fp";
import i18next from "i18next";
import {computeIsType} from "@/utils/common/functions";

interface IMonthDaysProps {
    selectedDate: DateTime,
    setNewDate?: (v: DateTime) => void,
    value?: DateTime
    range?: boolean
    secondDate?: DateTime | null,
    maxDate?: DateTime
    minDate?: DateTime
    hoverDate?: DateTime | null
    setHoverDate?: (v: DateTime | null) => void
}

enum HighlightType {
    none,
    all,
    toRight,
    toLeft,
}


export const MonthDays = (
    {
        selectedDate,
        secondDate,
        setNewDate = () => {
        },
        value,
        maxDate,
        minDate,
        hoverDate,
        setHoverDate,
        range = false,
    }: IMonthDaysProps,
) => {
    const weekDays = useMemo(() => Info.weekdays('short', {locale: i18next.language}), [])

    const firstDayInFirstMonthWeek = selectedDate
        .startOf('month')
        .startOf('week');

    const lastDayInLastMonthWeek = selectedDate
        .endOf('month')
        .endOf('week');

    const dayTextColor = (currentDate: DateTime, isDisabled: boolean, isSelected: boolean) => {
        if (isSelected) return 'base-color-white'

        if (isDisabled || selectedDate.month !== currentDate.month) return 'base-secondary-2'

        return undefined;
    }

    const dayIntoView = lastDayInLastMonthWeek
        .startOf('day')
        .diff(firstDayInFirstMonthWeek.startOf('day'), ['days']).days + 1;

    const sortedSelectedHoverDates = fp.sortBy(fp.result('toUnixInteger'), [value, secondDate?.isValid ? secondDate : hoverDate])

    const checkInRange = (d: DateTime) => {
        const [left, right] = sortedSelectedHoverDates;

        if (DateTime.isDateTime(left) && DateTime.isDateTime(right) && range) {
            if (value?.isValid) {
                if (left.hasSame(right, 'day')) {
                    return HighlightType.none;
                }

                if (left.hasSame(d, 'day')) {
                    return HighlightType.toRight;
                }

                if (right.hasSame(d, 'day')) {
                    return HighlightType.toLeft;
                }

                if (fp.inRange(left.toUnixInteger(), right.toUnixInteger(), d.toUnixInteger())) {
                    return HighlightType.all
                }
            }
        }

        return HighlightType.none
    }


    return (
        <div className={clsx(styles.daysContainer, 'py-8 px-12 d-grid')}>
            {weekDays.map((day, i) => (
                <Typography key={i} type='SmallTextRegular'
                            className={clsx(styles.dayButton, styles.dayButtonDisabled, 'text-center my-4 mx-6')}>{fp.capitalize(day)}</Typography>
            ))}
            {new Array(dayIntoView).fill(null).map((_, i) => {
                const currentDate = firstDayInFirstMonthWeek.plus({day: i})
                const isSelected = (!!value && currentDate.hasSame(value, 'day'))
                    || (!!secondDate && currentDate.hasSame(secondDate, 'day'))
                const isDisabled = (!!maxDate && currentDate > maxDate)
                    || (!!minDate && currentDate < minDate);

                const inHoverOrSelectedRange = computeIsType(checkInRange(currentDate), HighlightType)

                return (
                    <div
                        key={i}
                        className={clsx(
                            inHoverOrSelectedRange.none || styles.dayButtonInRange,
                            'my-4',
                            inHoverOrSelectedRange.none && 'mx-6',
                            inHoverOrSelectedRange.all && 'px-6',
                            inHoverOrSelectedRange.toLeft && ['ps-6 me-6', styles.dayButtonInRangeEnd],
                            inHoverOrSelectedRange.toRight && ['pe-6 ms-6', styles.dayButtonInRangeStart],
                        )}
                        onMouseEnter={setHoverDate ? () => setHoverDate(currentDate) : undefined}
                        onMouseLeave={setHoverDate ? () => setHoverDate(null) : undefined}
                    >
                        <Typography
                            type='SmallTextRegular'
                            onClick={isDisabled ? undefined : () => setNewDate(currentDate)}
                            className={clsx(
                                styles.dayButton,
                                isSelected && styles.dayButtonSelected,
                                (isDisabled && !isSelected) && styles.dayButtonDisabled,
                                'text-center',
                            )}
                            color={dayTextColor(currentDate, isDisabled, isSelected)}
                        >
                            {currentDate.day}
                        </Typography>
                    </div>
                );
            })}
        </div>
    );
}
