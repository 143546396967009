import {useSafeCloseModal} from "@/utils/common/modals";
import React, {useRef} from "react";
import {Button} from "@/components/base/Button";

import clsx from "clsx";
import {X} from "@/components/icons/X";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import {Typography} from "@/components/base/Typography";
import {BannerList} from "@/components/wrappers/DashboardBase/components/BannerList";
import commonStyles from '../common.module.scss';
import styles from "./styles.module.scss";
import {useAppDispatch} from "@/core/store/hooks";
import {business} from "@/business";
import {IModalProps} from "@/components/wrappers/Modals/consts";


export const ActionsNotifications = (
	{
		currentId,
	}: IModalProps,
) => {
	const contentRef = useRef(null);
	const close = useSafeCloseModal(currentId);

	useOnClickOutside(
		close,
		contentRef,
	)

	const dp = useAppDispatch();

	return (
		<div className='position-absolute'>
			<div className={clsx(commonStyles.overlay, ('position-relative'))}>
				<div className={commonStyles.content} ref={contentRef}>
					<Button
						type='TextButton'
						className={commonStyles.closeBtn}
						onClick={close}
					>
						<X/>
					</Button>
					<div className={clsx('d-flex flex-column', styles.notificationContainer)}>
						<Typography type='LargeTextSemibold'>
							Уведомления
						</Typography>
						<BannerList/>
						<Button type='Secondary' onClick={() => dp(business.banners.actions.hideAllBanners())}>
							Закрыть все уведомления
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}
