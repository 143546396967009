import {createNamespace} from "@/core/store/actions";
import {LoadingStatus} from "@/core/store/utils/sagas";
import {UserInfoBanner} from "@/services/openapi";


export const namespace = 'banners';

const actionCreator = createNamespace(namespace);


interface BaseMeta {
	status: LoadingStatus
	errorMessage: string | null
}


export interface State {
	list: {
		entity: UserInfoBanner[] | null
		meta: BaseMeta
		updatingMeta: Record<string, BaseMeta>
	}
}

export const initialState: State = {
	list: {
		entity: null,
		meta: {
			status: LoadingStatus.Never,
			errorMessage: null,
		},
		updatingMeta: {},
	},
}


export const sagaActions = {
	getBannerList: actionCreator('getBannerList'),
	hideBanner: actionCreator<UserInfoBanner['id']>('hideBanner'),
	hideAllBanners: actionCreator('hideAllBanners'),
}