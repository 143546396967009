export const X32 = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M26.2076 24.2921C26.3005 24.385 26.3742 24.4953 26.4245 24.6167C26.4747 24.7381 26.5006 24.8682 26.5006 24.9996C26.5006 25.131 26.4747 25.2611 26.4245 25.3825C26.3742 25.5039 26.3005 25.6142 26.2076 25.7071C26.1147 25.8 26.0044 25.8737 25.883 25.924C25.7616 25.9743 25.6315 26.0001 25.5001 26.0001C25.3687 26.0001 25.2386 25.9743 25.1172 25.924C24.9958 25.8737 24.8855 25.8 24.7926 25.7071L16.5001 17.4133L8.20757 25.7071C8.01993 25.8947 7.76543 26.0001 7.50007 26.0001C7.2347 26.0001 6.98021 25.8947 6.79257 25.7071C6.60493 25.5194 6.49951 25.2649 6.49951 24.9996C6.49951 24.7342 6.60493 24.4797 6.79257 24.2921L15.0863 15.9996L6.79257 7.70708C6.60493 7.51944 6.49951 7.26494 6.49951 6.99958C6.49951 6.73422 6.60493 6.47972 6.79257 6.29208C6.98021 6.10444 7.2347 5.99902 7.50007 5.99902C7.76543 5.99902 8.01993 6.10444 8.20757 6.29208L16.5001 14.5858L24.7926 6.29208C24.9802 6.10444 25.2347 5.99902 25.5001 5.99902C25.7654 5.99902 26.0199 6.10444 26.2076 6.29208C26.3952 6.47972 26.5006 6.73422 26.5006 6.99958C26.5006 7.26494 26.3952 7.51944 26.2076 7.70708L17.9138 15.9996L26.2076 24.2921Z"
            fill='currentColor'/>
    </svg>
);

export const X = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className='d-block'>
        <path
            d="M11.2473 10.6282C11.2879 10.6689 11.3202 10.7171 11.3422 10.7702C11.3642 10.8233 11.3755 10.8803 11.3755 10.9377C11.3755 10.9952 11.3642 11.0522 11.3422 11.1053C11.3202 11.1584 11.2879 11.2066 11.2473 11.2473C11.2066 11.2879 11.1584 11.3202 11.1053 11.3422C11.0522 11.3642 10.9952 11.3755 10.9377 11.3755C10.8803 11.3755 10.8233 11.3642 10.7702 11.3422C10.7171 11.3202 10.6689 11.2879 10.6282 11.2473L7.00024 7.61876L3.37227 11.2473C3.29018 11.3294 3.17884 11.3755 3.06274 11.3755C2.94665 11.3755 2.8353 11.3294 2.75321 11.2473C2.67112 11.1652 2.625 11.0538 2.625 10.9377C2.625 10.8216 2.67112 10.7103 2.75321 10.6282L6.38173 7.00024L2.75321 3.37227C2.67112 3.29018 2.625 3.17884 2.625 3.06274C2.625 2.94665 2.67112 2.8353 2.75321 2.75321C2.8353 2.67112 2.94665 2.625 3.06274 2.625C3.17884 2.625 3.29018 2.67112 3.37227 2.75321L7.00024 6.38173L10.6282 2.75321C10.7103 2.67112 10.8216 2.625 10.9377 2.625C11.0538 2.625 11.1652 2.67112 11.2473 2.75321C11.3294 2.8353 11.3755 2.94665 11.3755 3.06274C11.3755 3.17884 11.3294 3.29018 11.2473 3.37227L7.61876 7.00024L11.2473 10.6282Z"
            fill='currentColor'/>
    </svg>
)
