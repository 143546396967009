import fp from "lodash/fp";
import React from "react";
import {renderToString} from "react-dom/server";


type GeneratorFunction<T = any, TReturn = any, TNext = any> = (...args: any[]) => Generator<T, TReturn, TNext>;

export type Wrapper<T = any, TReturn = any, TNext = any> = (
	next: GeneratorFunction<T, TReturn, TNext>
) => (...args: any[]) => Generator<T, TReturn, TNext>;

export const generatorWrap = <T = any, TReturn = any, TNext = any>(
	generatorFunction: GeneratorFunction<T, TReturn, TNext>,
	...wrappers: Wrapper<T, TReturn, TNext>[]
): GeneratorFunction<T, TReturn, TNext> => {
	return wrappers.reduceRight(
		(chunk, wrapper) =>
			function* bind(...args: any[]): Generator<T, TReturn, TNext> {
				return yield* wrapper(() => chunk(...args))(...args);
			},
		generatorFunction,
	);
};


export const sleep = (ms = 100) => new Promise((resolve) => setTimeout(resolve, ms))

export const computeIsType = <S extends string | number | null | undefined, O extends Record<string | number, any>>(
	val: S, obj: O,
) => fp.mapValues(fp.eq(val), obj) as Record<keyof O, boolean>


// maybe too slow, and inaccurate
export const autoObjectSearch = (obj: any | any[], searchString: string = '') => fp.pipe(
	fp.cond([
		[fp.isObject, fp.values],
		[fp.stubTrue, fp.castArray],
	]),
	(v) => {
		return JSON.stringify(v, (_, v) => {
			if (React.isValidElement(v)) return renderToString(v)

			return v
		});
	},
	(v) => new RegExp(fp.escapeRegExp(searchString), 'gmi').test(v),
)(obj)
