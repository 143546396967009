import { createNamespace } from "@/core/store/actions";
import {IPayloadForAction, LoadingStatus} from "@/core/store/utils/sagas";
import {AccountDetails, Source} from "@/services/openapi";
import {generateExecId} from "@/business/common/utils/actionPreparer";
import {schemaValidate} from "@/components/views/auth/RegistrationStep3/consts";
import {yup} from "@/utils/yup/common";
import i18next from "i18next";
import {InferType} from "yup";

export const namespace = 'users';

const actionCreator = createNamespace(namespace);


export interface State {
	sendLeadMeta: Partial<IPayloadForAction>
	debtAccounts: Source[]
	debtAccountsMeta: Partial<IPayloadForAction>
}

export const initialState: State = {
	sendLeadMeta: {
		status: LoadingStatus.Never,
	},
	debtAccounts: [],
	debtAccountsMeta: {
		status: LoadingStatus.Never,
	},
}

export const taxRateChangeFormSchema = schemaValidate
	.omit(['inn', 'start_year'])
	.shape({
		year: yup
			.number()
			.required(() => i18next.t('common.errors.yup.common.required')),
	});

type TaxRateChangeFormSchemaType = InferType<typeof taxRateChangeFormSchema>;

export const sagaActions = {
	sendLeadEns: actionCreator<string>('sendLeadEns'),
	sendLeadOfd: actionCreator<string>('sendLeadOfd'),
	getDebtBankAccounts: actionCreator('getDebtBankAccounts'),
	createNewBankAccount: actionCreator<AccountDetails>('createNewBankAccount'),
	changeTaxSystemParams: actionCreator('changeTaxSystemParams', generateExecId<TaxRateChangeFormSchemaType>('users/changeTaxSystemParams')),
	changePass: actionCreator('changePass', generateExecId<{ oldPass: string, newPass: string }>('users/changePass')),
	disableSelf: actionCreator('disableSelf', generateExecId<string>('users/disableSelf')),
}
