import commonStyles from "@/components/wrappers/Modals/components/common.module.scss";
import styles from "@/components/wrappers/Modals/components/ENSAnalyzeRequest/styles.module.scss";
import clsx from "clsx";
import {Typography} from "@/components/base/Typography";
import {FormProvider, useForm} from "react-hook-form";
import {Input} from "@/components/form/Input";
import {Button} from "@/components/base/Button";

import iternalStyles from './styles.module.scss';
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {business} from "@/business";
import {LoadingStatus} from "@/core/store/utils/sagas";
import {Spin} from "@/components/icons/Spin";
import {yup} from "@/utils/yup/common";
import i18next from "i18next";
import {yupResolver} from "@hookform/resolvers/yup";


export const schemaValidate = yup.object().shape({
    password: yup.string()
        .required(() => i18next.t('common.errors.yup.common.required'))
        .min(8, 'Пароль должен содержать 8 и больше символов'),
    confirm: yup.string()
        .required(() => i18next.t('common.errors.yup.common.required'))
        .oneOf([yup.ref('password')], 'Пароли должны совпадать'),
    username: yup.string().notRequired().nullable(),
})



export const AtFirstChangePass = () => {
    const dp = useAppDispatch();

    const email = useAppSelector(
        (state) => state.auth.user?.email,
    )

    const methods = useForm({
        resolver: yupResolver(schemaValidate),
        defaultValues: {
            username: email,
        },
    });
    
    const isLoading = useAppSelector(
        (state) => state.auth.states['changePass']?.status === LoadingStatus.Loading,
    )
    

    const submit = methods.handleSubmit((data) => {
        dp(business.auth.actions.changePass(data.password));
    });

    return (
        <div className='position-absolute'>
            <div className={clsx(commonStyles.overlay, iternalStyles.overlay, 'position-relative')}>
                <div
                    className={clsx(commonStyles.content, 'align-self-start align-self-md-center p-0', styles.content)}>
                    <div className={clsx('vstack pt-32 pb-24 gap-16 gap-md-32 align-items-center', iternalStyles.imgBanner)}>
                        <img alt='logo' src='/imgs/logos/Logo_full_center.svg' className={clsx('px-md-12', iternalStyles.banner)}/>
                        <img alt='logo' src='/imgs/auth/RegBanner.svg' className={iternalStyles.banner}/>
                    </div>
                    <FormProvider {...methods}>
                        <form className='vstack gap-24 gap-md-32 m-24' onSubmit={submit}>
                            <div className='vstack gap-8'>
                                <Typography type='H4Header'>
                                    Новый пароль
                                </Typography>
                                <Typography color='base-additional-grey'>
                                    Пожалуйста, замените автоматически сгенерированный пароль из письма на собственный
                                    <Input name='username' hidden autoComplete='username'/>
                                </Typography>
                            </div>
                            <div className='vstack gap-24'>
                                <Input name='password' type='password' autoComplete='new-password' label='Пароль'/>
                                <Input name='confirm' type='password' autoComplete='new-password'
                                       label='Подтвердите пароль'/>
                            </div>
                            <Button htmlType='submit' disabled={!methods.formState.isValid}>
                                {isLoading && <Spin />}
                                Подтвердить
                            </Button>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    )
}