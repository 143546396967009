import fp from "lodash/fp";

import {
    BankReferenceResponse,
    DisableReason,
    RequestState,
    Source,
    SourceType,
} from "@/services/openapi";
import {DateTime} from "luxon";
import {InProgress20} from "@/components/icons/InProgress";
import {Close20} from "@/components/icons/Close";
import {Typography} from "@/components/base/Typography";
import {Null} from "@/components/base/Null";
import {computeIsType} from "@/utils/common/functions";
import React from "react";
import {ColorType} from "@/styles/types";
import i18next from "i18next";


export const displayAccountNumber = (
    entity: Source | null | undefined, short = false,
) => entity?.sub_name ? `*${short ? '' : '**'}${fp.takeRight(4, entity.sub_name).join('')}` : null;


export const displayLastInfo = (
    {last_info, state}: Source,
) => state === RequestState.completed && last_info
    ? DateTime.fromSQL(last_info).toLocaleString(DateTime.DATE_SHORT, {locale: i18next.language})
    : null;

/**
 * Type union with `undefined` need for includes pass ts check
 **/
// const marketAvailableRetry: (string | undefined)[] = [MarketplaceKey.ozon, MarketplaceKey.wb];
// const keyDRError: (string | undefined)[] = [
//     DisableReason.userDisable,
//     DisableReason.disabledUser,
// ];

export const showRetryConnect = (
) => {
    return false;
    // const sName = props?.name;
    //
    // if (marketAvailableRetry.includes(sName)) {
    //     return props?.reason === '';
    // }
    //
    // return props?.reason === DisableReason.integrationError
}

export const isManualSource = (props?: Source | null) => {
    if (props) {
        return fp.where<Partial<Source>>({
            is_integrated: fp.isEqual(false),
            disable_reason: fp.negate(fp.isString),
        }, props)
    }

    return false;
}

export const showHideManualSource = (
    source: Source,
) => {
    if (source.disable_date) {
        return !DateTime.fromSQL(source.disable_date).isValid && source.state === RequestState.completed
    }

    return source.state === RequestState.completed;
}

export const showOffAutoSource = (
    entity: Source,
) => {
    return !(
        entity.state !== RequestState.completed
        || entity.disable_reason === DisableReason.userDisable
    );
};

export const displayTextDie = (
    {state, reason}: Source,
): { message: React.ReactNode, wafer: ColorType, color: ColorType } | null => {
    const isState = computeIsType(state, RequestState);

    if (isState.failed) {
        return {
            message: reason,
            wafer: 'red-secondary-pale',
            color: 'red-primary',
        }
    }

    if (isState.processing || isState.inProgress) {
        return {
            message: 'Данные в процессе добавления',
            wafer: 'base-secondary-pale',
            color: 'base-additional-grey',
        }
    }

    return null
}

export const mapSourcesStatuesRow = {
    [RequestState.inProgress]: () => <Typography color='base-secondary-1'><InProgress20/></Typography>,
    [RequestState.processing]: () => <Typography color='base-secondary-1'><InProgress20/></Typography>,
    [RequestState.completed]: Null,
    [RequestState.failed]: () => <Typography color='red-primary'><Close20/></Typography>,
}

export const displayRowIcon = (
    {state}: Source,
) => state ? mapSourcesStatuesRow[state] : Null

export const mapSourcesStatuesHistory = {
    [RequestState.inProgress]: Null,
    [RequestState.processing]: Null,
    [RequestState.completed]: Null,
    [RequestState.failed]: () => <Typography color='red-primary'><Close20/></Typography>,
}

export const displayStatusHistoryIcon = (
    {state}: Source,
) => state ? mapSourcesStatuesHistory[state] : Null

export const colorStatusHistoryIcon = (
    {state}: Source,
) => state && state === RequestState.failed ? 'red-primary' : 'base-additional-grey'


export const displayProviderName = (
    props: Source | null | undefined, marketDisplay = false,
) => (props?.type === SourceType.marketplace && props?.title && marketDisplay)
    ? props?.title
    : props?.short_name
        ? props?.short_name : props?.name

export const displayDisableDateLabel = (props?: Source | null) => {
    if (props?.type) {
        switch (props?.type) {
            case SourceType.account:
                return 'Дата закрытия счёта'
            case SourceType.ofd:
                return 'Дата прекращения работы с ОФД'
            default:
                return null
        }
    }

    return null
}

export const displayBankNameBankReference = (entity: BankReferenceResponse | null) =>
    entity ? (entity.bank_short_name || entity.bank_name) : '';


export const wbOnlyUtils = {
    toDateBegin: (year: number) => year === 2024 ? DateTime.utc(year, 1, 29) : DateTime.utc(year, 1, 1),
    toProcessingBeginDate: (year: number) => DateTime.utc(year, 1, 1),
}
