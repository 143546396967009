import {Wrapper} from "@/utils/common/functions";
import {logger} from "@/utils/logger";
import {LoadingStatus} from "@/core/store/utils/sagas";
import {put} from "redux-saga/effects";
import {PayloadAction} from "@reduxjs/toolkit";
import {ErrorCode, NetworkMetaMapKeysType, PayloadSubDataConfig} from "@/business/common/types";
import {slice} from "./slice";
import {strictKickUnlikeUser} from "@/utils/routing";
import {AxiosError} from "axios";
import {toSimpleJSObjectRigid} from "@/utils/common/objects";
import fp from "lodash/fp";


const simplifyError = (error: unknown) => fp.pickBy(fp.negate(fp.isObject), toSimpleJSObjectRigid(error))

export const mixinWithLoadingHandler =
    (overrideId?: NetworkMetaMapKeysType): Wrapper =>
        (next) =>
            function* loadingHandlerWrapper<P=any, T extends string = string>(action: PayloadAction<P, T, PayloadSubDataConfig>) {
                logger.info(
                    `Include v2 mixin %cWithLoadingHandler%c -> %c${action?.type}`,
                    'color: yellow; font-style: italic;',
                    'color: #008cff; font-style: normal;',
                    'color: green; font-style: italic;',
                )

                const id = overrideId || action.meta?.asyncConfig?.executeId;
                
                if (id) {
                    yield put(slice.actions.setAsyncExecuteMeta({
                        id,
                        data: { status: LoadingStatus.Loading, errorCode: null, message: null },
                    }));

                    yield* next();

                    yield put(slice.actions.setAsyncExecuteMeta({
                        id,
                        data: { status: LoadingStatus.Loaded, errorCode: null, message: null },
                    }));
                } else {
                    logger.error('mixin WithLoadingHandler not receive, skip wrap. Source action: ', action)

                    yield* next();
                }
            };

export const mixinWithErrorHandler =
    (overrideId?: NetworkMetaMapKeysType): Wrapper =>
        (next) =>
            function* errorHandlerWrapper<P=any, T extends string = string>(action: PayloadAction<P, T, PayloadSubDataConfig>) {
                logger.info(
                    `Include v2 mixin %cWithErrorHandler%c -> %c${action?.type}`,
                    'color: yellow; font-style: italic;',
                    'color: #008cff; font-style: normal;',
                    'color: green; font-style: italic;',
                )

                const id = overrideId || action.meta?.asyncConfig?.executeId;
                
                if (id) {
                    try {
                        yield* next();
                    } catch (e) {
                        if (e instanceof AxiosError) {
                            logger.warn(e.response?.status, e.response?.data)

                            yield put(slice.actions.setAsyncExecuteMeta({
                                id,
                                data: {
                                    status: LoadingStatus.Error,
                                    message: e?.response?.data?.detail?.message || null,
                                    errorCode: (e?.status || 500) >= 500
                                        ? ErrorCode.validation
                                        : ErrorCode.unexpected,
                                    meta: simplifyError(e),
                                },
                            }));

                            if (e.response?.status === 401) {
                                strictKickUnlikeUser();
                            }

                            return;
                        }
                        if (e instanceof Error) {
                            return yield put(slice.actions.setAsyncExecuteMeta({
                                id,
                                data: { status: LoadingStatus.Error, errorCode: ErrorCode.unexpected, message: e.message, meta: simplifyError(e) },
                            }));
                        }

                        return yield put(slice.actions.setAsyncExecuteMeta({
                            id,
                            data: { status: LoadingStatus.Error, errorCode: ErrorCode.unexpected, message: 'Случилось что-то из ряда вон', meta: simplifyError(e) },
                        }));
                    }
                } else {
                    logger.error('mixin WithErrorHandler not receive, skip wrap. Source action: ', action)

                    yield* next();
                }
            };


export const mixinWithClearBefore =
    (overrideId?: NetworkMetaMapKeysType): Wrapper =>
        (next) =>
            function* loadingHandlerWrapper<P=any, T extends string = string>(action: PayloadAction<P, T, PayloadSubDataConfig>) {
                logger.info(
                    `Include v2 mixin %cWithClearBefore%c -> %c${action?.type}`,
                    'color: yellow; font-style: italic;',
                    'color: #008cff; font-style: normal;',
                    'color: green; font-style: italic;',
                )

                const id = overrideId || action.meta?.asyncConfig?.executeId;

                if (id) {
                    yield put(slice.actions.clearAsyncExecuteMeta(id));

                    yield* next();
                } else {
                    logger.error('mixin WithLoadingHandler not receive, skip wrap. Source action: ', action)

                    yield* next();
                }
            };


// reexport for non conflict commissioning new version
export const mixinWithErrorHandlerNext = mixinWithErrorHandler;
export const mixinWithLoadingHandlerNext = mixinWithLoadingHandler;
