import {FormProvider, useForm} from "react-hook-form";
import {UploadFile} from "@/components/form/UploadFile";
import {Button} from "@/components/base/Button";
import {business} from "@/business";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {LoadingStatus} from "@/core/store/utils/sagas";
import i18next from "i18next";
import {useEffect} from "react";
import {yup} from "@/utils/yup/common";
import {yupResolver} from "@hookform/resolvers/yup";
import {sendFileToTmp} from "@/utils/common/files";
import fp from "lodash/fp";
import {UUID} from "node:crypto";
import {asyncExecMetaSelector} from "@/business/common/selectors";


const title = {
    [LoadingStatus.Never]: undefined,
    [LoadingStatus.Loading]: 'Загрузка...',
    [LoadingStatus.Loaded]: 'Обработка прошла успешно',
    [LoadingStatus.Error]: 'Не удалось загрузить данные',
}

const desc = {
    [LoadingStatus.Never]: () => 'Загрузите файлы в формате .xlsx',
    [LoadingStatus.Loading]: (count?: number) => i18next.t('common.fileUpload.loadingDescMultiFiles.', {count}),
    [LoadingStatus.Loaded]: (count?: number) => i18next.t('common.fileUpload.success.', {count}),
    [LoadingStatus.Error]: () => 'Непредвиденная ошибка!',
}

const schemaValidate = yup.object().shape({
    files: yup.array()
        .of(yup.mixed<File>().required())
        .required()
        .min(1)
        .default([]),
})

export const OzonUploadStatement = () => {
    const dp = useAppDispatch();

    const meta = useAppSelector(
        asyncExecMetaSelector('sources/ozonCheckFiles'),
        fp.isEqual,
    );

    const metaUpload = useAppSelector(
        asyncExecMetaSelector('sources/ozonSendFiles'),
        fp.isEqual,
    );

    const methods = useForm({
        resolver: yupResolver(schemaValidate),
        mode: 'onChange',
        defaultValues: schemaValidate.getDefault(),
    });

    useEffect(() => methods.watch((data, {name}) => {
        if (name === 'files') {
            const id = sendFileToTmp(fp.compact(data[name]), true);
            dp(business.sources.actions.ozonCheckFiles(id as UUID[]));
        }
    }).unsubscribe, [dp, methods]);

    const submit = methods.handleSubmit((data) => {
        const id = sendFileToTmp(data.files);
        dp(business.sources.actions.ozonSendFiles(id as UUID[]));
    })

    const displayTitle = title[meta.status];
    
    const filesLength = methods.watch('files').length

    useEffect(() => {
        if (filesLength === 0 && meta.status !== LoadingStatus.Never) {
            dp(business.common.actions.clearAsyncExecuteMeta('sources/ozonCheckFiles'));
        }
    }, [dp, filesLength, meta]);

    const displayDescription = meta.status === LoadingStatus.Error
        ? meta.message
        : desc[meta.status](filesLength);

    const submitDisable = !methods.formState.isValid
        || meta.status !== LoadingStatus.Loaded
        || metaUpload.status === LoadingStatus.Loading

    return (
        <FormProvider {...methods}>
            <form onSubmit={submit} className='vstack gap-32'>
                <UploadFile name='files' multiple showFile currentState={meta.status} title={displayTitle} description={displayDescription}/>
                <div className='d-flex flex-column flex-md-row gap-8 gap-md-16'>
                    <Button
                        type='Secondary'
                        className='w-100'
                        onClick={() => dp(business.sources.actions.prevStep())}
                    >
                        ← Назад
                    </Button>
                    <Button className='w-100' htmlType='submit' disabled={submitDisable}>
                        Добавить данные
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}