import {FormProvider, useForm} from "react-hook-form";
import {DatePicker} from "@/components/form/DatePicker";
import {Typography} from "@/components/base/Typography";
import {Highlighter} from "@/components/composite/Highlighter";
import {Button} from "@/components/base/Button";
import {business} from "@/business";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {yup} from "@/utils/yup/common";
import i18next from "i18next";
import {yupResolver} from "@hookform/resolvers/yup";
import {DateTime} from "luxon";
import {asyncExecMetaSelector} from "@/business/common/selectors";
import fp from "lodash/fp";
import {LoadingStatus} from "@/core/store/utils/sagas";

const schemaValidate = yup.object().shape({
    date: yup
        .date()
        .required(() => i18next.t('common.errors.yup.common.required')),
})

export const YaMarketAuth = () => {
    const userTaxDateBegin = useAppSelector(
        (state) => `${state.auth.user?.tax_date_begin}`,
    )

    const meta = useAppSelector(
        asyncExecMetaSelector('sources/yaMarketAuthProcessing'),
        fp.isEqual,
    )

    const dp = useAppDispatch();
    const methods = useForm({
        resolver: yupResolver(schemaValidate),
    });

    const submit = methods.handleSubmit((d) => {
        dp(business.sources.actions.yaMarketOpenAuth({date: DateTime.fromJSDate(d.date).toUnixInteger()}));
    })

    return (
        <FormProvider {...methods}>
            <form onSubmit={submit} className='vstack gap-32'>
                <DatePicker name='date' label='Дата начала загрузки отчетов о реализации' required minDate={DateTime.fromSQL(userTaxDateBegin)} />
                <Typography>
                    <Highlighter text='Мы сгенерируем ссылку для предоставления доступа к данным. Ссылка отобразится в **Источники данных**. Для успешного подключения перейдите по ссылке и предоставьте доступ.'/>
                </Typography>
                <Typography>
                    <Highlighter text='Как только мы получим доступ, ваши продажи на Яндекс.Маркет отобразятся в разделе **Операции**'/>
                </Typography>
                {meta.status === LoadingStatus.Error && <div>
                    <Typography color='red-primary' type='NormalTextSemibold'>
                        {meta.message}
                    </Typography>
                </div>}
                <div className='d-flex flex-column flex-md-row gap-8 gap-16'>
                    <Button className='w-100' type='Secondary' onClick={() => {
                        dp(business.sources.actions.prevStep())
                    }}>
                        ← Назад
                    </Button>
                    <Button className='w-100' htmlType='submit' disabled={!methods.formState.isValid}>
                        Перейти в ЛК Я.Маркет
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}
