import {Typography} from "@/components/base/Typography";
import {FormProvider, useForm} from "react-hook-form";
import {Input} from "@/components/form/Input";
import {Button} from "@/components/base/Button";
import {business} from "@/business";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {closeModal, openModalPure} from "@/utils/common/modals";
import i18next from "i18next";
import {yup} from "@/utils/yup/common";
import {yupResolver} from "@hookform/resolvers/yup";
import {InferType} from "yup";
import {LoadingStatus} from "@/core/store/utils/sagas";


const schema = yup.object().shape({
    name: yup.string()
        .required(() => i18next.t('common.errors.yup.common.required')),
})

export const AskNewSource = () => {
    const dp = useAppDispatch();
    
    const isLoading = useAppSelector(
        (state) => state.users.sendLeadMeta.status === LoadingStatus.Loading,
    );

    const methods = useForm({
        resolver: yupResolver(schema),
    });

    const submit = ({name}: InferType<typeof schema>) => {
        dp(business.users.actions.sendLeadOfd(name))
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submit)} className='d-flex flex-column gap-16'>
                <div className='d-flex flex-column gap-16'>
                    <Typography type='NormalTextRegular'>
                        К сожалению, синхронизация с другими маркетплейсами сейчас не поддерживается, но вы можете
                        указать название и мы постараемся добавить его в наш сервис.
                    </Typography>
                    <div className='d-flex flex-wrap align-items-end gap-8'>
                        <Input name='name' label='Название желаемого маркетплейса' className='flex-fill'/>
                        <Button type='Secondary' htmlType='submit' disabled={isLoading}>
                            Отправить
                        </Button>
                    </div>
                    <div className='hr'/>
                    <Typography type='NormalTextRegular'>
                        Добавить удержанные суммы (комиссии, доставка, хранение, маркетинг) можно вручную.
                    </Typography>
                </div>
                <div className='d-flex flex-column flex-md-row gap-8 gap-md-16'>
                    <Button
                        type='Secondary'
                        className='w-100'
                        onClick={() => dp(business.sources.actions.prevStep())}
                    >
                        ← Назад
                    </Button>
                    <Button className='w-100' onClick={() => {
                        closeModal().then(() => openModalPure('CreateTransactionRecordExtend'))
                    }}>
                        Добавить доход
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}