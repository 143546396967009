import {uuid} from "@/utils/common/uuid";
import {UUID} from "node:crypto";
import fp from "lodash/fp";


export const sendFileToTmp = <F extends File | File[]>(files: F, arrAlways = false)  => {
    const ids = fp.castArray(files).map((e) => {
        const newId = uuid.v4();

        if (window.__uiTempFiles)
            window.__uiTempFiles[newId] = e;
        else
            window.__uiTempFiles = {[newId]: e}

        return newId
    })

    if (arrAlways) return ids as F extends File[] ? UUID[] : never;

    return fp.isArray(files) ? ids[0] : ids as F extends File[] ? UUID[] : UUID;
}

export const getFileFromTmp = (id: UUID) =>
    window.__uiTempFiles[id];


export const removeFileFromTmp = (id: UUID) => {
    delete window.__uiTempFiles[id];
}