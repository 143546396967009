import clsx from "clsx";

import {ITypographyProps} from "./types";
import {ElementType, forwardRef, ForwardRefRenderFunction, PropsWithChildren, useRef} from "react";

import styles from './styles.module.scss';
import fp from "lodash/fp";


const noop = () => {}

const TypographyRef: ForwardRefRenderFunction<ElementType, PropsWithChildren<ITypographyProps>> = ((
	{
		children,
		type = 'NormalTextRegular',
		typesWithMedia = [],
		color = 'base-primary-dark',
		className = null,
		onClick = noop,
		onMiddleClick = noop,
		onRightClick = noop,
		additionalProps = {},
		as: HtmlTag = 'div',
	},
	ref,
) => {
	const clicked = useRef<boolean>(false);

	const handleClick = (
		e: MouseEvent,
	) => {
		if (!clicked.current) return;

		clicked.current = false;

		switch (e.button) {
			case 0:
				return onClick();
			case 1:
				return onMiddleClick();
			case 2:
				return onRightClick();
		}
	};

	const isClickable = !([onClick, onMiddleClick, onRightClick].every(fp.isEqual(noop))) || HtmlTag === 'button';

	return (
		<HtmlTag
			ref={ref}
			className={clsx(
				styles.base,
				isClickable && styles.clickable,
				styles[`${type}xs`],
				styles[`${color}Color`],
				...typesWithMedia.map((t) => styles[t]),
				className,
			)}
			role={isClickable ? 'button' : 'text'}
			tabIndex={isClickable ? -1 : null}
			onMouseDown={() => clicked.current = true}
			onMouseUp={handleClick}
			{...additionalProps}
		>
			{children}
		</HtmlTag>
	)
})


export const Typography = forwardRef(TypographyRef);
