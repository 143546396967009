import {generatorWrap} from "@/utils/common/functions";
import {mixinWithErrorHandler, mixinWithLoadingHandler} from "@/core/store/utils/sagas";
import {call, Effect, put, select, takeLatest} from "redux-saga/effects";
import {sagaActions} from "./inits";
import {slice} from "./slice";
import {
    changeTaxUsersChangeTaxPut, client,
    createClientAccountSourcesAccountPost, disableUserUsersDisablePut,
    getSourcesInfoSourcesGet,
    LeadReason, passwordChangeRequestUsersPasswordChangePost, passwordChangeUsersPasswordChangePut,
    saveUserLeadUsersLeadPut, Token,
} from "@/services/openapi";
import {closeModal} from "@/utils/common/modals";
import {mixinWithInitAwait} from "@/business/auth/mixins";
import {common} from "@/business/common";
import {NotificationType} from "@/business/common/inits";
import {mixinWithErrorHandlerNext, mixinWithLoadingHandlerNext} from "@/business/common/mixins";
import {maskString} from "@/utils/data/common/format";
import {checkReductionAttemptByRate} from "@/components/views/auth/RegistrationStep3/consts";
import {business} from "@/business";
import {RootState} from "@/core/store";
import {createClient, createConfig} from "@hey-api/client-axios";
import {auth} from "@/business/auth";
import {AxiosError} from "axios";

function* sendLeadEns(action: ReturnType<typeof sagaActions.sendLeadEns>) {
    yield call(saveUserLeadUsersLeadPut, {
        body: {
            phone_number: action.payload,
            reason: LeadReason.ens,
        },
    })

    yield call(closeModal);
}

function* sendLeadOfd(action: ReturnType<typeof sagaActions.sendLeadOfd>) {
    yield call(saveUserLeadUsersLeadPut, {
        body: {
            description: action.payload,
            reason: LeadReason.ofd,
        },
    })

    yield put(common.actions.sendNotification({
        text: 'Данные сохранены',
        type: NotificationType.Success,
    }))

    yield call(closeModal);
}


function* getDebtBankAccounts(): Generator<Effect> {
    yield put(
        slice.actions.setBankAccounts(
            (yield call(getSourcesInfoSourcesGet)).data,
        ),
    )
}

function* createNewBankAccount(action: ReturnType<typeof sagaActions.createNewBankAccount>): Generator<Effect> {
    yield call(createClientAccountSourcesAccountPost, {body: action.payload});

    yield put(sagaActions.getDebtBankAccounts())
}

function* changeTaxSystemParams(action: ReturnType<typeof sagaActions.changeTaxSystemParams>): Generator<Effect> {
    const r0 = maskString(action.payload.rate_reason_0, '0000');
    const r1 = maskString(action.payload.rate_reason_1, '0000');
    const r2 = maskString(action.payload.rate_reason_2, '0000');

    const showReason = checkReductionAttemptByRate(action.payload.tax_system, action.payload.tax_rate);


    yield call(changeTaxUsersChangeTaxPut, {
        body: {
            year: action.payload.year,
            tax_system: action.payload.tax_system,
            tax_rate: action.payload.tax_rate,
            rate_reason: (action.payload.rate_reason_0 && showReason)
                ? `${r0}${r1}${r2}`
                : null,
            reason_type: (showReason && action.payload.reason_type)
                ? action.payload.reason_type
                : null,
        },
    })

    yield put(business.auth.actions.getUserInfo());

    yield put(business.common.actions.sendNotification({
        type: NotificationType.Success,
        text: 'Данные успешно сохранены',
    }));

    yield call(closeModal);
}

function* changePass(action: ReturnType<typeof sagaActions.changePass>): Generator<Effect> {
    const email: string = yield select((s: RootState) => (s.auth.user?.email));

    try {

        const token: string = (yield call(passwordChangeRequestUsersPasswordChangePost, {
            body: {
                username: email,
                password: action.payload.oldPass,
            },
        })).data.access_token;

        const tempClient = createClient(createConfig({
            ...client.getConfig(),
            auth: token,
        }));

        const upToken: Token = (yield call(passwordChangeUsersPasswordChangePut, {
            body: {new_password: action.payload.newPass},
            client: tempClient,
        })).data;

        yield put(auth.actions.setToken({token: upToken.access_token, cookieSet: true}));

        yield call(closeModal);
    } catch (error) {
        if (error instanceof AxiosError) {
            if (error.response?.data?.detail?.error_key === 'unauthorized_user') {
                throw new Error(error.response?.data?.detail?.message);
            }
        }
    }
}

function* disableSelf(action: ReturnType<typeof sagaActions.disableSelf>): Generator<Effect> {
    yield call(saveUserLeadUsersLeadPut, {body: {reason: LeadReason.serviceDisable, description: action.payload}});

    yield call(disableUserUsersDisablePut);

    yield put(auth.actions.logout());
}


export const sagas = [
    takeLatest(sagaActions.sendLeadEns.type,
        generatorWrap(
            sendLeadEns,
            mixinWithErrorHandler(slice.actions.setMeta),
            mixinWithLoadingHandler(slice.actions.setMeta),
        ),
    ),
    takeLatest(sagaActions.sendLeadOfd.type,
        generatorWrap(
            sendLeadOfd,
            mixinWithErrorHandler(slice.actions.setMeta),
            mixinWithLoadingHandler(slice.actions.setMeta),
        ),
    ),
    takeLatest(sagaActions.getDebtBankAccounts.type,
        generatorWrap(
            getDebtBankAccounts,
            mixinWithErrorHandler(slice.actions.setDebtAccountsMeta),
            mixinWithLoadingHandler(slice.actions.setDebtAccountsMeta),
            mixinWithInitAwait(),
        ),
    ),
    takeLatest(sagaActions.createNewBankAccount.type,
        generatorWrap(
            createNewBankAccount,
            mixinWithErrorHandler(slice.actions.setDebtAccountsMeta),
            mixinWithLoadingHandler(slice.actions.setDebtAccountsMeta),
        ),
    ),
    takeLatest(sagaActions.changeTaxSystemParams.type,
        generatorWrap(
            changeTaxSystemParams,
            mixinWithErrorHandlerNext(),
            mixinWithLoadingHandlerNext(),
        ),
    ),
    takeLatest(sagaActions.changePass.type,
        generatorWrap(
            changePass,
            mixinWithErrorHandlerNext(),
            mixinWithLoadingHandlerNext(),
        ),
    ),
    takeLatest(sagaActions.disableSelf.type,
        generatorWrap(
            disableSelf,
            mixinWithErrorHandlerNext(),
            mixinWithLoadingHandlerNext(),
        ),
    ),
];
