import {StringSkeleton} from "@/components/base/Motion/StringSkeleton";


export const Skeleton = () => (
	<div className='d-flex flex-column gap-16'>
		{...(
			new Array(4)
				.fill(null)
				.map((_, i) => (
					<div key={i} className='d-flex flex-column gap-4'>
						<StringSkeleton width={6} type='circle'/>
						<StringSkeleton width={50}/>
						<StringSkeleton width={100}/>
						<StringSkeleton/>
					</div>
				))
		)}
	</div>
)