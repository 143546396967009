import {FormProvider, useForm} from "react-hook-form";
import {Typography} from "@/components/base/Typography";
import {LinkButton} from "@/components/base/LinkButton";
import {Button} from "@/components/base/Button";
import {Input} from "@/components/form/Input";
import {DatePicker} from "@/components/form/DatePicker";
import {business} from "@/business";
import {useAppDispatch} from "@/core/store/hooks";
import {yupResolver} from "@hookform/resolvers/yup";
import {LoginPassSchemaValidate, LoginPassSchemaValidateType} from "@/business/sources/inits";
import {DateTime} from "luxon";


export const LoginPassOFD = () => {
    const dp = useAppDispatch();

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(LoginPassSchemaValidate),
    });

    const submit = (d: LoginPassSchemaValidateType) => {
        dp(business.sources.actions.createLoginPassOFDIntegration({
            ...d,
            date: DateTime.fromJSDate(d.date).toUnixInteger(),
        }))
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submit)} className='d-flex flex-column gap-24 gap-md-32'>
                <div className='d-flex flex-column gap-24'>
                    <Input name='login' required label='Логин / электронная почта'/>
                    <Input name='password' required label='Пароль'/>
                    <DatePicker name='date' required label='Дата начала загрузки отчетов по чекам'/>
                </div>
                <Typography type='NormalTextRegular'>
                    {'Логин и пароль для интеграции с онлайн-кассой необходимо взять в ЛК ОФД. '}
                    <LinkButton
                        href='https://drive.google.com/drive/u/1/folders/1yFkiwQuDDGUrHxINyXWVs8x3wtf_aLw_'
                        type='Inherit'
                    >
                        Инструкция →
                    </LinkButton>
                </Typography>
                <div className='d-flex flex-column flex-md-row gap-8 gap-md-16'>
                    <Button type='Secondary' className='w-100' onClick={() => {
                        dp(business.sources.actions.prevStep())
                    }}>
                        ← Назад
                    </Button>
                    <Button className='w-100' htmlType='submit' disabled={!methods.formState.isValid}>
                        Подключить
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
}
