import {LoadingStatus} from "@/core/store/utils/sagas";


// пока не придумал механизм внешнего наполнения safe типизации, string в конце для использования в местах где сложно
// однозначно вычислить template union
export type NetworkMetaMapKeysType =
    'sources/wbFireReportValidation'
    | `sources/wbSalesReportValidation`
    | `sources/wbReportAuthComplete`
    | `sources/wbCheckTokenValidity`
    | `sources/wbAuthFirstStepProcessing`
    | `sources/yaMarketAuthProcessing`
    | `sources/yaMarketAuthCheck`
    | `sources/loadAllSources`
    | `sources/ozonCheckFiles`
    | `sources/ozonSendFiles`
    | `sources/ozonCheckToken`
    | `actions/generateOrUpdateReport${number}`
    | `users/changeTaxSystemParams`
    | `users/changePass`
    | `users/disableSelf`

export enum ErrorCode {
    unexpected = 'unexpected',
    validation = 'validation',
    auth = 'auth',
}

export interface PayloadSubDataConfig {
    asyncConfig?: {
        executeId: NetworkMetaMapKeysType
    }
}

export interface IMetaInfoInitial {
    status: LoadingStatus.Never
    errorCode: null
    message: null
}

export const initialMetaInfoValue: IMetaInfoInitial = {
    status: LoadingStatus.Never,
    errorCode: null,
    message: null,
}


export interface IMetaInfoSimple<M = any> {
    status: LoadingStatus.Loaded | LoadingStatus.Loading;
    errorCode: null
    message: string | null
    meta?: M
}

export interface IMetaInfoError<M = any> {
    status: LoadingStatus.Error
    message: string | null
    errorCode: ErrorCode
    meta?: M
}

export const isIMetaInfoInitial = (v: any): v is IMetaInfoInitial =>
    LoadingStatus.Never === v?.status

export const isIMetaInfoSimple = (v: any): v is IMetaInfoSimple =>
    [LoadingStatus.Loaded, LoadingStatus.Loading].includes(v?.status)

export const isIMetaInfoError = (v: any): v is IMetaInfoError =>
    LoadingStatus.Error === v?.status

