import {IModalProps} from "@/components/wrappers/Modals/consts";
import {useSafeCloseModal} from "@/utils/common/modals";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import commonStyles from "@/components/wrappers/Modals/components/common.module.scss";
import styles from "@/components/wrappers/Modals/components/ENSAnalyzeRequest/styles.module.scss";
import {Button} from "@/components/base/Button";
import {X} from "@/components/icons/X";
import {useEffect, useMemo, useRef} from "react";
import {FormProvider, useForm} from "react-hook-form";
import clsx from "clsx";
import {Typography} from "@/components/base/Typography";
import {business} from "@/business";
import {checkReductionAttemptByRate} from "@/components/views/auth/RegistrationStep3/consts";
import {yupResolver} from "@hookform/resolvers/yup";
import {getFormTaxInfo} from "@/business/auth/selectors";
import fp from "lodash/fp";
import {DateTime} from "luxon";
import {Select} from "@/components/form/Select";
import {taxSystemValues} from "@/utils/data/users/formats";
import {TaxSystemType} from "@/services/openapi";
import {computeIsType} from "@/utils/common/functions";
import {reasonTypeValues} from "@/components/views/auth/RegistrationStep3/components/TaxRate";
import {Input} from "@/components/form/Input";
import {taxRateChangeFormSchema} from "@/business/users/inits";
import {asyncExecMetaSelector} from "@/business/common/selectors";
import {LoadingStatus} from "@/core/store/utils/sagas";
import {Spin} from "@/components/icons/Spin";


const displayPartialSupportTaxSys = () => {
    return [...taxSystemValues].map((item) => ({
        ...item,
        disable: ![TaxSystemType.usnD, TaxSystemType.usnDR].includes(item.value),
    }))
}

const partialSupportTaxSys = displayPartialSupportTaxSys();


export const EditTaxSystem = (
    {currentId}: IModalProps,
) => {
    const contentRef = useRef(null);
    const close = useSafeCloseModal(currentId);

    const dp = useAppDispatch();

    useOnClickOutside(
        close,
        contentRef,
    )

    const formPreset = useAppSelector(
        getFormTaxInfo,
        fp.isEqual,
    )

    const meta = useAppSelector(
        asyncExecMetaSelector('users/changeTaxSystemParams'),
        fp.isEqual,
    )

    const taxBeginYear = useAppSelector(
        (state) => DateTime.fromSQL(state.auth.user?.tax_date_begin || '').year,
        fp.isEqual,
    )

    const values = () => {
        const now = DateTime.now().year + 1;
        if (taxBeginYear > 0) {
            return new Array(now - taxBeginYear).fill(null).map((_, i) => taxBeginYear + i);
        } else {
            return [];
        }
    }

    const methods = useForm({
        resolver: yupResolver(taxRateChangeFormSchema),
        reValidateMode: 'onChange',
    });

    useEffect(() => {
        if (formPreset) {
            methods.reset({...formPreset});
        }
    }, [formPreset, methods])

    const taxSystem = methods.watch('tax_system');
    const taxRate = methods.watch('tax_rate');

    const isTaskSys = computeIsType(taxSystem, TaxSystemType);

    const percentageCountList = useMemo(
        () => Array(
            isTaskSys.usnD
                ? 7
                : isTaskSys.usnDR
                    ? 16 : 0,
        ).fill(null).map((_, i) => ({value: i, label: `${i} %`})),
        [isTaskSys.usnD, isTaskSys.usnDR],
    )

    useEffect(() => {
        if (taxSystem && methods.getFieldState('tax_system').isTouched) {
            methods.setValue('tax_rate', undefined)
        }
    }, [methods, taxSystem]);

    const showReason = checkReductionAttemptByRate(taxSystem, taxRate);

    const submit = methods.handleSubmit((data) => {
        dp(business.users.actions.changeTaxSystemParams(data))
    })

    const isSubmitting = meta.status === LoadingStatus.Loading;

    return (
        <div className='position-absolute'>
            <div className={clsx(commonStyles.overlay, 'position-relative')}>
                <div className={clsx(commonStyles.content, 'align-self-start align-self-md-center', styles.content)}
                     ref={contentRef}>
                    <Button
                        type='TextButton'
                        className={commonStyles.closeBtn}
                        onClick={close}
                    >
                        <X/>
                    </Button>
                    <FormProvider {...methods}>
                        <form className='d-flex flex-column gap-24 gap-md-32 w-100' onSubmit={submit}>
                            <Typography type='LargeTextSemibold' typesWithMedia={['H4Headermd']}>
                                Редактировать систему налогообложения
                            </Typography>
                            <div className='vstack gap-24'>
                                <Select name='year' values={values()} required label='Налоговый период'/>
                                <Select name='tax_system' required label='Система налогообложения'
                                        values={partialSupportTaxSys}/>
                                <Select name='tax_rate' required label='Налоговая ставка' values={percentageCountList}/>
                                {showReason && <>
                                    <div style={{height: '1px', backgroundColor: 'var(--brand-secondary-pale)'}}/>
                                    <div className='vstack gap-8'>
                                        <Typography type='NormalTextSemibold'>
                                            Обоснование применения пониженной ставки
                                        </Typography>
                                        <Typography color='base-additional-grey'>
                                            Укажите обоснование из регионального законодательства. Эта информация
                                            отразится
                                            в Декларации по УСН
                                        </Typography>
                                    </div>
                                    <Select name='reason_type' label='Обоснование' values={reasonTypeValues}/>
                                    <div className='d-flex gap-8 w-100'>
                                        <Input name='rate_reason_0' className='w-100' label='Статья' required/>
                                        <Input name='rate_reason_1' className='w-100' label='Пункт'/>
                                        <Input name='rate_reason_2' className='w-100' label='Подпункт'/>
                                    </div>
                                </>}
                            </div>
                            <div className='d-flex flex-column flex-md-row gap-8 gap-md-16'>
                                <Button
                                    type='Secondary'
                                    onClick={close}
                                    className='w-100'
                                >
                                    Отменить
                                </Button>
                                <Button
                                    className='w-100'
                                    htmlType='submit'
                                    disabled={!methods.formState.isValid || isSubmitting}
                                >
                                    {isSubmitting && <Spin />}
                                    Сохранить
                                </Button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    )
}
