import {Actions, IconModifier, SelectStyle, Sources, ViewType} from "./types";
import {OfdSource} from "@/services/openapi";


// Make backend in the future. All sources:
export const LocalAddSources: Sources = {
    root_id: '00b48eaa-d0be-4224-8d7c-2aa395b5c439',
    sources: [
        {
            id: '00b48eaa-d0be-4224-8d7c-2aa395b5c439',
            type: ViewType.list,
            style: SelectStyle.column,
            actions: [
                {
                    id: '0dd90a9f-b0f7-4c11-83d4-6b80392914a6',
                    name: 'Ручной ввод операции',
                    description: 'Добавить операцию, не отраженную в сервисе',
                    icon: 'Pen24',
                    action: {
                        type: 'sources/openModal',
                        payload: {
                            name: 'CreateTransactionRecordExtend',
                        },
                    },
                },
                {
                    id: '09082f3a-83f8-4c02-96ba-f7524bfb23f6',
                    name: 'Выписка из банка',
                    description: 'Загрузить выписку в формате 1С (.txt)',
                    icon: 'AccountBalance',
                    action: {
                        type: 'sources/nextStep',
                        payload: {
                            id: 'UploadBankStatement',
                        },
                    },
                },
                {
                    id: '9b96caa1-2e12-4e95-9074-0546b764c048',
                    name: 'Интеграция с банком',
                    description: 'Модульбанк, T-банк, Точка, Хайс',
                    icon: 'AccountBalance',
                    action: {
                        type: 'sources/nextStep',
                        payload: {
                            id: 'e3c5ac0d-7c43-42d7-8f06-69b697aeff35',
                        },
                    },
                },
                {
                    id: 'bbe89c2a-28dd-4d92-91a5-40650f835e3b',
                    name: 'Онлайн-касса',
                    description: 'ОФД.ру, Первый ОФД, Платформа, СБИС, Контур и т.д.',
                    icon: 'AddBusiness',
                    action: {
                        type: 'sources/nextStep',
                        payload: {
                            id: '4d1548bc-9638-4d6a-9b86-92871292470a',
                        },
                    },
                },
                {
                    id: '3bfd4c6a-fc41-406b-bf44-314a6ca7a42d',
                    name: 'Маркетплейс',
                    description: 'Ozon, Wildberries, Яндекс Маркет',
                    icon: 'ShoppingCart',
                    action: {
                        type: 'sources/nextStep',
                        payload: {
                            id: 'b0672913-48bb-4ede-8f08-46329ac5501f',
                        },
                    },
                },
            ],
        },
        {
            id: 'e3c5ac0d-7c43-42d7-8f06-69b697aeff35',
            type: ViewType.list,
            style: SelectStyle.row,
            description: 'Выберите банк',
            actions: [
                // Не используем, покуда интеграция не сделается на беке
                // {
                //     id: 'ab255824-891b-438c-a717-479b3addf57d',
                //     name: 'Альфа-Банк',
                //     logo: '/imgs/thirdParty/logos/alfaBank48.png',
                //     action: {
                //         payload: {
                //             id: '???Bank1CDirect???',
                //         },
                //         type: '???sources/nextStep???',
                //     },
                // },
                {
                    id: '425a0ed3-1255-4c1d-8fa8-7db55084a1ac',
                    name: 'Модульбанк',
                    logo: '/imgs/thirdParty/logos/moduleBank48.png',
                    action: {
                        payload: {
                            id: 'Bank1CDirect',
                        },
                        type: 'sources/nextStep',
                    },
                },
                {
                    id: '05fc0f14-c70d-4082-81d0-62a99259aadb',
                    name: 'Т-Банк',
                    logo: '/imgs/thirdParty/logos/tBank48.png',
                    action: {
                        payload: {
                            id: 'Bank1CDirect',
                        },
                        type: 'sources/nextStep',
                    },
                },
                {
                    id: '178cd70c-5eb7-433c-8d0f-506578d48cf8',
                    name: 'Точка',
                    logo: '/imgs/thirdParty/logos/tochka48.png',
                    action: {
                        payload: {
                            id: 'Bank1CDirect',
                        },
                        type: 'sources/nextStep',
                    },
                },
                {
                    id: '1fab5259-0dde-4ba8-8085-a3dadc252ac8',
                    name: 'Хайс',
                    logo: '/imgs/thirdParty/logos/hais48.png',
                    action: {
                        payload: {
                            id: 'Bank1CDirect',
                        },
                        type: 'sources/nextStep',
                    },
                },

            ],
        },
        {
            id: '4d1548bc-9638-4d6a-9b86-92871292470a',
            type: ViewType.list,
            style: SelectStyle.row,
            description: 'Выберите оператора фискальных данных',
            actions: [
                {
                    id: '8dd65ba9-22c0-467b-b3be-26d5d53550ae',
                    name: 'OFD.ru',
                    logo: '/imgs/thirdParty/logos/ofdRu48.png',
                    action: {
                        type: 'sources/nextStep',
                        payload: {
                            id: 'LoginPassOFD',
                            meta: {
                                ofd_source: OfdSource.ofdRu,
                            },
                        },
                    },
                },
                {
                    id: 'a58101b5-ba53-4fb4-90b8-4bfd078f2cf6',
                    name: 'Контур ОФД',
                    logo: '/imgs/thirdParty/logos/KonturOFD48.png',
                    action: {
                        type: 'sources/nextStep',
                        payload: {
                            id: 'UploadXLSXStatementOFD',
                            meta: {
                                ofd_source: OfdSource.konturOfd,
                            },
                        },
                    },
                },
                {
                    id: '9ea9d28e-5b47-4269-b1a2-166df8641e86',
                    name: 'Первый ОФД',
                    logo: '/imgs/thirdParty/logos/firstOFD48.png',
                    action: {
                        type: 'sources/nextStep',
                        payload: {
                            id: 'LoginPassOFD',
                            meta: {
                                ofd_source: OfdSource.perviiOfd,
                            },
                        },
                    },
                },
                {
                    id: 'cc1f768f-26de-4ed6-be04-77f4c3ba73b4',
                    name: 'Платформа',
                    logo: '/imgs/thirdParty/logos/platformOFD48.png',
                    action: {
                        type: 'sources/nextStep',
                        payload: {
                            id: 'UploadXLSXStatementOFD',
                            meta: {
                                ofd_source: OfdSource.platformaOfd,
                            },
                        },
                    },
                },
                {
                    id: '06487bf0-5b23-4ef4-a9c2-296d7c7e608e',
                    name: 'СБИС ОФД',
                    logo: '/imgs/thirdParty/logos/SBISOFD48.png',
                    action: {
                        type: 'sources/nextStep',
                        payload: {
                            id: 'UploadXLSXStatementOFD',
                            meta: {
                                ofd_source: OfdSource.sbisOfd,
                            },
                        },
                    },
                },
                {
                    id: '1d710d4d-c303-4283-9037-ca83e1042436',
                    name: 'Такском ОФД',
                    logo: '/imgs/thirdParty/logos/TaxComOFD48.png',
                    action: {
                        type: 'sources/nextStep',
                        payload: {
                            id: 'UploadXLSXStatementOFD',
                            meta: {
                                ofd_source: OfdSource.takskomOfd,
                            },
                        },
                    },
                },
                {
                    id: 'a17d44b1-e62a-4c20-b05f-0910eddcccee',
                    name: 'Яндекс ОФД',
                    logo: '/imgs/thirdParty/logos/yandexOFD48.png',
                    action: {
                        type: 'sources/nextStep',
                        payload: {
                            id: 'UploadXLSXStatementOFD',
                            meta: {
                                ofd_source: OfdSource.yandeksOfd,
                            },
                        },
                    },
                },
                {
                    id: 'c612378f-67cc-469e-811d-6ba6df9eedcc',
                    name: 'Другая касса',
                    logo: '/imgs/thirdParty/logos/addBusiness48.png',
                    action: {
                        type: 'sources/nextStep',
                        payload: {
                            id: 'AskNewSource',
                        },
                    },
                },

            ],
        },
        {
            id: 'b0672913-48bb-4ede-8f08-46329ac5501f',
            type: ViewType.list,
            style: SelectStyle.row,
            description: 'Выберите маркетплейс',
            actions: [
                {
                    id: 'c69dacf0-4ee9-4d1e-9d5d-cee444732511',
                    name: 'Wildberries',
                    iconModifier: IconModifier.round,
                    logo: '/imgs/thirdParty/logos/mpSvg/wb.svg',
                    action: {
                        type: 'sources/nextStep',
                        payload: {
                            id: 'WBAuth',
                        },
                    },
                },
                {
                    id: '0b04a105-f74f-428c-898c-188ba341afcf',
                    name: 'Ozon',
                    iconModifier: IconModifier.round,
                    logo: '/imgs/thirdParty/logos/mpSvg/ozon.svg',
                    action: {
                        type: 'sources/nextStep',
                        payload: {
                            id: '7a9b32f4-f2da-4faa-b4e2-4fe82e51aefa',
                        },
                    },
                },
                {
                    id: '79ef7a22-dc22-4a87-a004-48c7a83608b5',
                    name: 'Яндекс.Маркет',
                    iconModifier: IconModifier.round,
                    logo: '/imgs/thirdParty/logos/mpSvg/yandex.svg',
                    action: {
                        type: 'sources/nextStep',
                        payload: {
                            id: 'YaMarketAuth',
                        },
                    },
                },
            ],
        },
        {
            id: '7a9b32f4-f2da-4faa-b4e2-4fe82e51aefa',
            type: ViewType.list,
            style: SelectStyle.row,
            description: 'Выберите способ загрузки для Ozon',
            actions: [
                {
                    id: '7538dfaf-b439-46f6-8348-ece12a5ef69a',
                    name: 'Отчeт в формате .xls',
                    logo: '/imgs/sources/FileText.svg',
                    action: {
                        type: 'sources/nextStep',
                        payload: {
                            id: 'OzonUploadStatement',
                        },
                    },
                },
                {
                    id: 'dcec8f19-3773-46bd-a0ca-6d36979da83a',
                    name: 'Интеграция по API',
                    logo: '/imgs/sources/ArrowsCounterClockwise.svg',
                    action: {
                        type: 'sources/nextStep',
                        payload: {
                            id: 'OzonAuth',
                        },
                    },
                },
            ],
        },
        {
            id: 'UploadBankStatement',
            type: ViewType.form,
            description: 'Подгрузите выписку в формате .txt (1С), сформированную в ЛК банка. Файл должен содержать все входящие и исходящие операции по одному счету ИП',
            bottom_actions: Actions.submitInside,
            default_next_button_text: 'Закрыть',
            default_next_button_action: {
                type: 'transactions/nextStep',
                payload: {},
            },
            fields_type: {},
            regex_validation: {},
            backend_validation: [],
        },
        {
            id: 'YaMarketAuth',
            type: ViewType.form,
            description: 'Синхронизация с Яндекс.Маркет',
            bottom_actions: Actions.submitInside,
            default_next_button_text: 'Закрыть',
            default_next_button_action: {
                type: 'transactions/nextStep',
                payload: {},
            },
            fields_type: {},
            regex_validation: {},
            backend_validation: [],
        },
        {
            id: 'YaMarketConfirm',
            type: ViewType.form,
            description: '',
            bottom_actions: Actions.submitInside,
            default_next_button_text: 'Закрыть',
            default_next_button_action: {
                type: 'transactions/nextStep',
                payload: {},
            },
            fields_type: {},
            regex_validation: {},
            backend_validation: [],
        },
        {
            id: 'WBAuth',
            type: ViewType.form,
            description: 'Укажите данные для подключения Wildberries',
            bottom_actions: Actions.submitInside,
            default_next_button_text: 'Закрыть',
            default_next_button_action: {
                type: 'transactions/nextStep',
                payload: {},
            },
            fields_type: {},
            regex_validation: {},
            backend_validation: [],
        },
        {
            id: 'WBAuthReports',
            type: ViewType.form,
            description: 'Расчёт налогов  начинается с 1 января 2024 года',
            bottom_actions: Actions.submitInside,
            default_next_button_text: 'Закрыть',
            default_next_button_action: {
                type: 'transactions/nextStep',
                payload: {},
            },
            fields_type: {},
            regex_validation: {},
            backend_validation: [],
        },

        {
            id: 'LoginPassOFD',
            type: ViewType.form,
            description: 'Укажите данные для подключения ОФД',
            bottom_actions: Actions.submitInside,
            default_next_button_text: 'Закрыть',
            default_next_button_action: {
                type: 'transactions/nextStep',
                payload: {},
            },
            fields_type: {},
            regex_validation: {},
            backend_validation: [],
        },
        {
            id: 'AskNewSource',
            type: ViewType.form,
            description: undefined,
            bottom_actions: Actions.submitInside,
            default_next_button_text: 'Закрыть',
            default_next_button_action: {
                type: 'transactions/nextStep',
                payload: {},
            },
            fields_type: {},
            regex_validation: {},
            backend_validation: [],
        },
        {
            id: 'UploadXLSXStatementOFD',
            type: ViewType.form,
            description: 'Подгрузите отчёт по чекам в формате .xlsx',
                // + '\n[Инструкция как выгрузить отчёт →]()',
            bottom_actions: Actions.submitInside,
            default_next_button_text: 'Закрыть',
            default_next_button_action: {
                type: 'transactions/nextStep',
                payload: {},
            },
            fields_type: {},
            regex_validation: {},
            backend_validation: [],
        },
        {
            id: 'Bank1CDirect',
            type: ViewType.form,
            description: 'Укажите данные для подключения',
            content: '',
            bottom_actions: Actions.submitInside,
            default_next_button_text: 'Закрыть',
            default_next_button_action: {
                type: 'transactions/nextStep',
                payload: {},
            },
            fields_type: {},
            regex_validation: {},
            backend_validation: [],
        },
        {
            id: 'OzonUploadStatement',
            type: ViewType.form,
            description: 'Загрузите отчёт о реализации из ЛК Ozon в формате .xlsx\n[Инструкция как выгрузить отчeт  →](/404)',
            content: '',
            bottom_actions: Actions.submitInside,
            default_next_button_text: 'Закрыть',
            default_next_button_action: {
                type: 'transactions/nextStep',
                payload: {},
            },
            fields_type: {},
            regex_validation: {},
            backend_validation: [],
        },
        {
            id: 'OzonAuth',
            type: ViewType.form,
            description: 'Укажите данные для подключения источника Ozon',
            content: '',
            bottom_actions: Actions.submitInside,
            default_next_button_text: 'Закрыть',
            default_next_button_action: {
                type: 'transactions/nextStep',
                payload: {},
            },
            fields_type: {},
            regex_validation: {},
            backend_validation: [],
        },
    ],
}
