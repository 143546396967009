import commonStyles from "@/components/wrappers/Modals/components/common.module.scss";
import styles from "@/components/wrappers/Modals/components/ENSAnalyzeRequest/styles.module.scss";
import {Button} from "@/components/base/Button";
import {X} from "@/components/icons/X";
import clsx from "clsx";
import {useSafeCloseModal} from "@/utils/common/modals";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import {IModalProps} from "@/components/wrappers/Modals/consts";
import {useRef} from "react";
import {FormProvider, useForm} from "react-hook-form";

import {Radio} from "@/components/form/Radio";
import {Highlighter} from "@/components/composite/Highlighter";
import {Typography} from "@/components/base/Typography";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {business} from "@/business";
import {useRouter} from "next/router";
import {LoadingStatus} from "@/core/store/utils/sagas";
import {Spin} from "@/components/icons/Spin";


const first = '**Подтверждаю**, что:\n- не нанимаю физических лиц по трудовым или гражданско-правовым договорам;\n- не применяю патентную систему налогообложения;\n- не являюсь плательщиком торгового сбора;\n- не имею движений по валютным счетам ИП.'


export const ConfirmRegConditions = (
    {currentId}: IModalProps,
) => {
    const contentRef = useRef(null);
    const close = useSafeCloseModal(currentId);

    const {push} = useRouter();

    useOnClickOutside(
        close,
        contentRef,
    )

    const dp = useAppDispatch();

    const loading = useAppSelector(
        (state) => state.auth.states['completeRegistration']?.status === LoadingStatus.Loading,
    )

    const methods = useForm({
        defaultValues: {
            confirm: true,
        },
    });

    const submit = methods.handleSubmit(async (data) => {
        if (data.confirm) {
            dp(business.auth.actions.completeRegistration());
        } else {
            await close();
            await push('/auth/registration/unsupported');
        }
    })

    return (
        <div className='position-absolute'>
            <div className={clsx(commonStyles.overlay, 'position-relative')}>
                <div className={clsx(commonStyles.content, 'align-self-start align-self-md-center', styles.content)} ref={contentRef}>
                    <Button
                        type='TextButton'
                        className={commonStyles.closeBtn}
                        onClick={close}
                    >
                        <X/>
                    </Button>
                    <FormProvider {...methods}>
                        <form onSubmit={submit} className='vstack gap-32'>
                            <div className='vstack gap-8'>
                                <Typography type='H4Header'>
                                    Подтверждение
                                </Typography>
                                <Typography type='SmallTextRegular'>
                                    Пожалуйста, выберите условие
                                </Typography>
                            </div>
                            <Radio name='confirm' value={true} label={<div className='vstack'><Highlighter text={first} /></div>} />
                            <Radio
                                name='confirm'
                                value={false}
                                label={<Highlighter text='**Не подтверждаю** вышеперечисленное' />}
                            />
                            <Button htmlType='submit' disabled={loading}>
                                {loading && <Spin />}
                                Отправить
                            </Button>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    )
}
