import {createPortal} from "react-dom";
import {modalKeys, ModalTypes, ModalTypesKey, modalWrapperPortalId} from "@/components/wrappers/Modals/consts";
import React, {useEffect, useState} from "react";
import {useIsBrowser} from "@/utils/common/next";
import {useRouter} from "next/router";
import fp from "lodash/fp";

export const Modals = () => {
	const [el, setElement] = useState<HTMLElement | null>(null);
	const isBrowser = useIsBrowser();
	const {query} = useRouter();

	const [modalsMap, setModalsMap] = useState<React.ReactNode[]>([]);

	useEffect(() => {
		if (isBrowser) setElement(window?.document.getElementById(modalWrapperPortalId))
	}, [isBrowser])


	useEffect(() => {
		const modalQuery = query.modal as ModalTypesKey | ModalTypesKey[] | undefined;

		if (modalsMap.length > 0 && fp.isUndefined(modalQuery)) setModalsMap([]);


		if (fp.isString(modalQuery)) {
			if (!modalsMap[0]) {
				const Component = ModalTypes[modalQuery]?.Component;
				if (Component) setModalsMap([(<Component key={0} currentId={0} />)])
			}
			const mapLength = Object.keys(modalsMap).length;

			if (mapLength > 1) {
				setModalsMap(fp.take(1));
			}
		}

		if (fp.isArray(modalQuery)) {
			modalQuery.forEach((i, index) => {
				if (modalKeys.includes(i) && !modalsMap[index]) {
					const Component = ModalTypes[i].Component;
					setModalsMap((prev) => ([
						...prev,
						(<Component key={index} currentId={index} />),
					]));
				}
			})

			const queryLength = modalQuery.length;
			const mapLength = Object.keys(modalsMap).length;

			if (mapLength > queryLength) {
				setModalsMap(fp.take(queryLength))
			}
		}
	}, [modalsMap, query.modal])

	if (!el) return null;

	return createPortal(
		<>{...modalsMap}</>,
		el,
	)
}