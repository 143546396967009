import {sagaActions} from "./inits";
import {generatorWrap, sleep} from "@/utils/common/functions";
import {call, Effect, put, select, takeEvery, takeLatest} from "redux-saga/effects";
import {
    generateReportsReportsPost,
    getReportByIdReportsReportIdReportFormatGet,
    getTasksTasksGet,
    type GetTasksTasksGetResponse,
    ReportFormat,
    ReportPeriodType, ReportResponse,
    ReportType,
    TaskInfo, TaskType,
    UpdateReportRequest,
    updateReportStatusTasksStatusPut,
    UpdateRepostStatus,
} from "@/services/openapi";
import {mixinWithInitAwait} from "@/business/auth/mixins";
import {slice} from "@/business/actions/slice";
import {DateTime} from "luxon";
import {common} from "@/business/common";
import {mixinWithErrorHandler, mixinWithLoadingHandler} from "@/core/store/utils/sagas";
import {NotificationType} from "@/business/common/inits";
import {logger} from "@/utils/logger";
import {RootState} from "@/core/store";
import {downloadFile} from "@/utils/common/blob";
import {uuid} from "@/utils/common/uuid";
import {closeModal} from "@/utils/common/modals";
import { recalculateTaxes } from "../taxes/sagas";
import fp from "lodash/fp";
import {mixinWithErrorHandlerNext, mixinWithLoadingHandlerNext} from "@/business/common/mixins";


function* getActionsList(): Generator {
    const dirtyResp = (yield call(getTasksTasksGet)).data as GetTasksTasksGetResponse;

    const filterOther = fp.remove({type: TaskType.other}, dirtyResp.tasks)

    const resp = {
        ...dirtyResp,
        tasks: filterOther,
    }

    yield put(slice.actions.setListEntity(resp))

    const countInfo = Object.groupBy(
        resp.tasks,
        (e) => String(
            DateTime.fromSQL(e.due_date) <= DateTime.now().plus({days: 10}),
        ),
    )['true']?.length

    yield put(common.actions.setCounter({name: "actions", newValue: countInfo}))
}

function* callGetActionListWithCooldown(): Generator {
    yield call(recalculateTaxes);

    yield put(sagaActions.getActionsList());

    const expireDate = DateTime.now().plus({minute: 1});

    while (expireDate > DateTime.now()) {
        yield put(slice.actions.setListBlockReload(
            Math.floor(expireDate.diffNow('second').seconds),
        ));

        yield call(sleep, 499);
    }

    yield put(slice.actions.setListBlockReload(null))
}


function* downloadReport(action: ReturnType<typeof sagaActions.downloadReport>): Generator<Effect> {
    yield put(slice.actions.setGenerateDocMetaId({id: action.payload.id}))

    const id = uuid.v4();

    yield put(common.actions.sendNotification({
        id,
        text: 'Обработка файла',
        type: NotificationType.Loading,
    }))

    const taskInfo = (yield select((state: RootState) => state.actions.list.entity?.tasks[action.payload.id])) as TaskInfo

    const format = action.payload.type;

    if (taskInfo) {
        try {
            const resp = yield call(getReportByIdReportsReportIdReportFormatGet, {
                path: {
                    report_format: action.payload.type,
                    report_id: taskInfo.report_code as string,
                },
                responseType: 'blob',
            })

            const date = DateTime.fromISO(taskInfo.report_update || '');

            downloadFile(
                resp,
                format === ReportFormat.pdf
                    ? `${taskInfo.title} от ${date.toLocaleString(DateTime.DATE_SHORT)}.pdf`
                    : undefined,
            )
            yield put(common.actions.updateNotification({
                id,
                notify: {
                    text: 'Скачивание файла',
                    type: NotificationType.Success,
                },
            }))
        } catch (error) {
            logger.warn(error);

            yield put(common.actions.updateNotification({
                id,
                notify: {
                    text: 'Ошибка скачивания файла. Попробуйте еще раз',
                    type: NotificationType.Error,
                },
            }))

            throw error;
        }
    }
}

const prepareTaskInfoToReport = (task: TaskInfo) => {
    const tCode = task.task_code;

    let report_type: ReportType | null = null;
    let period_type: ReportPeriodType | null = null;

    if (tCode.startsWith('UV')) {
        report_type = ReportType._2;
        period_type = Number(tCode?.split('UV')[1]) as ReportPeriodType
    }

    if (tCode === 'ZDP') {
        report_type = ReportType._3;
        period_type = ReportPeriodType._0;
    }
    return {
        report_type, period_type, period_year: task.year,
    }
}

function* callGenerateReport(action: ReturnType<typeof sagaActions.callGenerateReport>): Generator<Effect> {
    const taskInfo = (yield select((state: RootState) => state.actions.list.entity?.tasks[action.payload.id])) as TaskInfo
    const {report_type, period_type, period_year} = prepareTaskInfoToReport(taskInfo)

    try {
        if (report_type !== null && period_type !== null) {
            const resp:ReportResponse = (yield call(generateReportsReportsPost, {
                body: {
                    report_type,
                    period_type,
                    period_year,
                },
            })).data;

            yield put(slice.actions.updateEntityReportCode({id: action.payload.id, code: resp.report_code}))
        } else {
            yield put(common.actions.sendNotification({
                text: 'Ошибка формирования файлов. Попробуйте еще раз',
                type: NotificationType.Error,
                expire: DateTime.now().plus({second: 15}).toUnixInteger(),
            }))
        }
    } catch (e) {
        logger.warn(e);

        yield put(common.actions.sendNotification({
            text: 'Ошибка формирования файлов. Попробуйте еще раз',
            type: NotificationType.Error,
            expire: DateTime.now().plus({second: 15}).toUnixInteger(),
        }))

        throw e;
    }
}

function* confirmSubmitReport(action: ReturnType<typeof sagaActions.confirmSubmitReport>): Generator<Effect> {
    const taskInfo = (yield select((state: RootState) => state.actions.list.entity?.tasks[action.payload.id])) as TaskInfo
    const dump = prepareTaskInfoToReport(taskInfo)

    yield call(updateReportStatusTasksStatusPut, {
        body: {
            ...dump,
            report_code: taskInfo.report_code,
            report_status: UpdateRepostStatus._4,
        } as UpdateReportRequest,
    })

    yield put(sagaActions.getActionsList());

    yield call(closeModal);
}


export const sagas = [
    takeLatest(sagaActions.getActionsList.type,
        generatorWrap(
            getActionsList,
            mixinWithErrorHandler(slice.actions.setListMeta),
            mixinWithLoadingHandler(slice.actions.setListMeta),
            mixinWithInitAwait(),
        ),
    ),
    takeLatest(sagaActions.callGetActionListWithCooldown.type,
        generatorWrap(
            callGetActionListWithCooldown,
            mixinWithErrorHandler(slice.actions.setListMeta),
            mixinWithLoadingHandler(slice.actions.setListMeta),
            mixinWithInitAwait(),
        ),
    ),
    takeEvery(sagaActions.callGenerateReport.type,
        generatorWrap(
            callGenerateReport,
            mixinWithErrorHandlerNext(),
            mixinWithLoadingHandlerNext(),
        ),
    ),
    takeLatest(sagaActions.confirmSubmitReport.type,
        generatorWrap(
            confirmSubmitReport,
            mixinWithErrorHandler(slice.actions.setConfirmSubmitReportMeta),
            mixinWithLoadingHandler(slice.actions.setConfirmSubmitReportMeta),
        ),
    ),
    takeLatest(sagaActions.downloadReport.type,
        generatorWrap(
            downloadReport,
            mixinWithErrorHandler(slice.actions.setGenerateDocMeta),
            mixinWithLoadingHandler(slice.actions.setGenerateDocMeta),
        ),
    ),
];
