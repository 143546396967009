import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {initialState, namespace} from './inits'
import {IPayloadForAction} from "@/core/store/utils/sagas";
import {BankReferenceResponse} from "@/services/openapi";

export const slice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		setGetBankInfoByBikMeta: (state, action: PayloadAction<IPayloadForAction>) => {
			state.getBankInfoByBikMeta = action.payload;
		},
		setBankInfoByBik: (state, action: PayloadAction<BankReferenceResponse | null>) => {
			state.bankInfoByBik = action.payload;
		},
	},
})