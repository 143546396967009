export const Check32 = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M29.2076 9.70708L13.2076 25.7071C13.1147 25.8001 13.0044 25.8738 12.883 25.9241C12.7616 25.9745 12.6315 26.0004 12.5001 26.0004C12.3687 26.0004 12.2385 25.9745 12.1171 25.9241C11.9957 25.8738 11.8854 25.8001 11.7926 25.7071L4.79257 18.7071C4.60493 18.5194 4.49951 18.2649 4.49951 17.9996C4.49951 17.7342 4.60493 17.4797 4.79257 17.2921C4.98021 17.1044 5.2347 16.999 5.50007 16.999C5.76543 16.999 6.01993 17.1044 6.20757 17.2921L12.5001 23.5858L27.7926 8.29208C27.9802 8.10444 28.2347 7.99902 28.5001 7.99902C28.7654 7.99902 29.0199 8.10444 29.2076 8.29208C29.3952 8.47972 29.5006 8.73422 29.5006 8.99958C29.5006 9.26494 29.3952 9.51944 29.2076 9.70708Z"
            fill='currentColor'/>
    </svg>
)

export const Check = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className='d-block'>
        <path
            d="M12.5598 4.24727L5.55977 11.2473C5.51914 11.288 5.47089 11.3202 5.41778 11.3422C5.36467 11.3643 5.30774 11.3756 5.25024 11.3756C5.19275 11.3756 5.13582 11.3643 5.08271 11.3422C5.0296 11.3202 4.98134 11.288 4.94071 11.2473L1.87821 8.18477C1.79612 8.10268 1.75 7.99134 1.75 7.87524C1.75 7.75915 1.79612 7.6478 1.87821 7.56571C1.9603 7.48362 2.07165 7.4375 2.18774 7.4375C2.30384 7.4375 2.41518 7.48362 2.49727 7.56571L5.25024 10.3192L11.9407 3.62821C12.0228 3.54612 12.1341 3.5 12.2502 3.5C12.3663 3.5 12.4777 3.54612 12.5598 3.62821C12.6419 3.7103 12.688 3.82165 12.688 3.93774C12.688 4.05384 12.6419 4.16518 12.5598 4.24727Z"
            fill='currentColor'/>
    </svg>
)
