
export const InProgress20 = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='d-block'>
        <mask id="mask0_6756_69549" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
            <rect width="20" height="20" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_6756_69549)">
            <path d="M9.99966 17.9165C8.90452 17.9165 7.87536 17.7088 6.91218 17.2932C5.94898 16.8777 5.11113 16.3137 4.39863 15.6013C3.68613 14.889 3.12207 14.0513 2.70645 13.0882C2.29082 12.1252 2.08301 11.0962 2.08301 10.0013C2.08301 8.90635 2.29079 7.87716 2.70634 6.91371C3.1219 5.95025 3.68586 5.11218 4.39822 4.3995C5.11059 3.68681 5.94829 3.12259 6.91132 2.70686C7.87434 2.29112 8.90332 2.08325 9.99826 2.08325C11.0932 2.08325 12.1224 2.29107 13.0858 2.70669C14.0493 3.12232 14.8874 3.68638 15.6001 4.39888C16.3127 5.11138 16.877 5.94923 17.2927 6.91242C17.7084 7.8756 17.9163 8.90476 17.9163 9.9999C17.9163 10.3749 17.8928 10.743 17.8458 11.1041C17.7988 11.4652 17.724 11.8193 17.6214 12.1666C17.4697 12.0085 17.2988 11.873 17.1086 11.7603C16.9185 11.6476 16.7107 11.5688 16.4852 11.524C16.5483 11.2782 16.5942 11.029 16.6231 10.7764C16.6519 10.5237 16.6663 10.2649 16.6663 9.9999C16.6663 8.13879 16.0205 6.5624 14.7288 5.27073C13.4372 3.97907 11.8608 3.33323 9.99966 3.33323C8.13854 3.33323 6.56215 3.97907 5.27049 5.27073C3.97882 6.5624 3.33299 8.13879 3.33299 9.9999C3.33299 11.861 3.97882 13.4374 5.27049 14.7291C6.56215 16.0207 8.13854 16.6666 9.99966 16.6666C10.7294 16.6666 11.4237 16.5568 12.0827 16.3372C12.7417 16.1176 13.3476 15.8102 13.9003 15.415C14.0296 15.587 14.181 15.7419 14.3546 15.8797C14.5282 16.0175 14.7165 16.127 14.9195 16.2082C14.2432 16.7456 13.4897 17.1647 12.6589 17.4654C11.8281 17.7662 10.9417 17.9165 9.99966 17.9165ZM15.9216 14.7915C15.6884 14.7915 15.4911 14.711 15.3298 14.55C15.1685 14.389 15.0878 14.1919 15.0878 13.9587C15.0878 13.7255 15.1683 13.5282 15.3293 13.3669C15.4904 13.2056 15.6875 13.1249 15.9207 13.1249C16.1539 13.1249 16.3511 13.2054 16.5124 13.3664C16.6738 13.5275 16.7544 13.7246 16.7544 13.9578C16.7544 14.191 16.6739 14.3882 16.5129 14.5496C16.3519 14.7109 16.1548 14.7915 15.9216 14.7915ZM12.8939 13.7723L9.37468 10.2529V5.83323H10.6246V9.74669L13.7721 12.8941L12.8939 13.7723Z" fill="#8C8C8C"/>
        </g>
    </svg>

)

export const InProgress18 = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_1682_12238" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="18"
              height="18">
            <rect width="18" height="18" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_1682_12238)">
            <path
                d="M8.99998 16.125C8.01436 16.125 7.08811 15.938 6.22125 15.564C5.35438 15.19 4.60031 14.6824 3.95906 14.0413C3.31781 13.4001 2.81016 12.6462 2.43609 11.7795C2.06203 10.9128 1.875 9.98669 1.875 9.00124C1.875 8.01579 2.062 7.08951 2.436 6.22241C2.81 5.3553 3.31756 4.60104 3.95869 3.95963C4.59983 3.3182 5.35376 2.81041 6.22048 2.43624C7.08719 2.06208 8.01328 1.875 8.99873 1.875C9.98418 1.875 10.9105 2.06203 11.7776 2.43609C12.6447 2.81016 13.3989 3.31781 14.0403 3.95906C14.6818 4.60031 15.1896 5.35438 15.5637 6.22125C15.9379 7.08811 16.125 8.01436 16.125 8.99998C16.125 9.33748 16.1038 9.66873 16.0615 9.99373C16.0192 10.3187 15.9519 10.6375 15.8596 10.95C15.723 10.8077 15.5692 10.6858 15.398 10.5844C15.2269 10.4829 15.0399 10.412 14.837 10.3716C14.8937 10.1505 14.9351 9.9262 14.961 9.69879C14.987 9.47138 15 9.23844 15 8.99998C15 7.32498 14.4187 5.90623 13.2562 4.74373C12.0937 3.58123 10.675 2.99998 8.99998 2.99998C7.32498 2.99998 5.90623 3.58123 4.74373 4.74373C3.58123 5.90623 2.99998 7.32498 2.99998 8.99998C2.99998 10.675 3.58123 12.0937 4.74373 13.2562C5.90623 14.4187 7.32498 15 8.99998 15C9.65672 15 10.2816 14.9012 10.8747 14.7035C11.4679 14.5059 12.0131 14.2293 12.5106 13.8735C12.6269 14.0283 12.7632 14.1678 12.9194 14.2918C13.0757 14.4158 13.2452 14.5144 13.4279 14.5875C12.8192 15.0711 12.141 15.4483 11.3933 15.719C10.6456 15.9896 9.8478 16.125 8.99998 16.125ZM14.3297 13.3125C14.1198 13.3125 13.9423 13.24 13.7971 13.0951C13.6519 12.9502 13.5793 12.7728 13.5793 12.5629C13.5793 12.353 13.6518 12.1755 13.7967 12.0303C13.9416 11.8851 14.119 11.8125 14.3289 11.8125C14.5388 11.8125 14.7163 11.885 14.8615 12.0299C15.0067 12.1748 15.0793 12.3522 15.0793 12.5621C15.0793 12.772 15.0068 12.9495 14.8619 13.0947C14.717 13.2399 14.5396 13.3125 14.3297 13.3125ZM11.6048 12.3952L8.4375 9.22772V5.24998H9.56246V8.7721L12.3952 11.6048L11.6048 12.3952Z"
                fill="#8C8C8C"/>
        </g>
    </svg>
)
