import {Button} from "@/components/base/Button";
import {X} from "@/components/icons/X";
import clsx from "clsx";
import {useParseQueryProps, useSafeCloseModal} from "@/utils/common/modals";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import {useEffect, useMemo, useRef} from "react";
import commonStyles from "@/components/wrappers/Modals/components/common.module.scss";
import {IModalProps} from "@/components/wrappers/Modals/consts";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {Typography} from "@/components/base/Typography";
import {BannerItem, FEBannerType} from "@/components/wrappers/DashboardBase/components/BannerList/BannerItem";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Input} from "@/components/form/Input";
import {DateTime} from "luxon";
import {DatePicker} from "@/components/form/DatePicker";
import {Trash} from "@/components/icons/Trash";
import {Plus} from "@/components/icons/Plus";
import styles from "./styles.module.scss";
import {business} from "@/business";
import i18next from "i18next";
import {defaultMoney} from "@/utils/yup/money";
import {bankDocNumber} from "@/utils/yup/banks";
import {useAppDispatch} from "@/core/store/hooks";

const infoTemplate = () => 'Независимо от того, какой налог или взнос вы оплатили, оплата зачтется в погашение обязательств ИП или физлица с наиболее ранним сроком уплаты' +
    `\n[Подробнее →](${window.location.pathname + window.location.search}&modal=ENSAnalyzeRequest)`

const taxPaymentSchema = yup.object().shape({
    amount: defaultMoney,
    date: yup
        .date()
        .required(() => i18next.t('common.errors.yup.common.required'))
        .default(DateTime.now().toJSDate()),
    doc_number: bankDocNumber,
})

const schemaValidate = yup.object().shape({
    tax_payments: yup.array().of(taxPaymentSchema),
})

export const CreateTransactionRecord = ({currentId}: IModalProps) => {
    const contentRef = useRef(null);
    const close = useSafeCloseModal(currentId);

    useOnClickOutside(
        close,
        contentRef,
    )

    const query = useParseQueryProps(currentId);

    const dp = useAppDispatch();

    const infoTemplateDisplay = useMemo(infoTemplate, [window.location.search])

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(schemaValidate),
        defaultValues: {
            tax_payments: [taxPaymentSchema.getDefault()],
        },
    });

    useEffect(() => {
        const list = methods.getValues('tax_payments') || [];
        const fSum = Number(query?.firstSum);

        if (list?.length > 0 && fSum > 0) {
            methods.setValue('tax_payments.0.amount', fSum)
        }
    }, [methods, query?.firstSum]);

    const {fields, append, remove} = useFieldArray({
        control: methods.control,
        name: 'tax_payments',
    })

    return (
        <div className='position-absolute'>
            <div className={clsx(commonStyles.overlay, ('position-relative p-md-0 justify-content-md-end'))}>
                <div className={clsx(commonStyles.content, commonStyles.contentLeftAligned)} ref={contentRef}>
                    <Button
                        type='TextButton'
                        className={clsx(commonStyles.closeBtn, 'd-md-none')}
                        onClick={close}
                    >
                        <X/>
                    </Button>
                    <FormProvider {...methods}>
                        <form className='d-flex flex-column gap-16' onSubmit={methods.handleSubmit((data) => {
                            dp(business.taxes.actions.createOperations({
                                taxes: data.tax_payments?.map(({ date, ...other}) => ({...other, date: DateTime.fromJSDate(date).toUnixInteger()})) || [],
                                taxPeriod: Number(query?.taxPeriod),
                            }))
                        })}>
                            <Typography type='LargeTextSemibold'>
                                Добавить уплату
                            </Typography>
                            <Typography type='SmallTextRegular' color='base-additional-grey'>
                                Укажите информацию об уплаченных налогах и взносах, сведения о которых не содержатся в
                                загруженных выписках
                            </Typography>
                            <BannerItem
                                title='Уплаты налогов и взносов формируют баланс ЕНС'
                                description={infoTemplateDisplay}
                                type={FEBannerType.info}
                            />
                            {fields.map((field, index) => (
                                <div key={field.id} className='d-flex flex-column gap-16'>
                                    {index !== 0 && (
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <Typography type='NormalTextSemibold'>
                                                Дополнительный платеж
                                            </Typography>
                                            <Typography as='span' color='red-primary' onClick={() => remove(index)}>
                                                <Trash/>
                                            </Typography>
                                        </div>
                                    )}
                                    <div className='d-flex flex-column row-gap-24 column-gap-16 flex-wrap flex-md-row'>
                                        <Input
                                            name={`tax_payments.${index}.amount`}
                                            label='Сумма уплаты'
                                            placeholder='Введите'
                                            className={styles.inputHalf}
                                            required
                                        />
                                        <DatePicker
                                            name={`tax_payments.${index}.date`}
                                            label='Дата уплаты'
                                            maxDate={DateTime.now()}
                                            className={styles.inputHalf}
                                            required
                                        />
                                        <div className='w-100 d-flex flex-column gap-2'>
                                            <Input
                                                name={`tax_payments.${index}.doc_number`}
                                                label='Номер платежного документа'
                                                placeholder='Введите'
                                            />
                                            <Typography type='SmallLabel' color='base-secondary-1'>
                                                Укажите номер платежного документа в банке или номер чека (квитанции)
                                            </Typography>
                                        </div>
                                    </div>
                                    <div
                                        className={clsx(
                                            commonStyles.hr,
                                            ((index === 0 && fields.length <= 1) || fields.length - 1 === index) && commonStyles.hrFirst,
                                        )}
                                    />
                                </div>
                            ))}
                            <div className='d-flex'>
                                <Button
                                    onClick={() => append(taxPaymentSchema.getDefault())}
                                    className='flex-fill flex-md-grow-0'
                                    type='Secondary'
                                >
                                    <Plus/>
                                    Добавить платеж
                                </Button>
                            </div>
                            <div className='mt-auto w-100'>
                                <Button className='w-100' htmlType='submit' disabled={!methods.formState.isValid}>
                                    Добавить уплату взноса
                                </Button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    )
}
