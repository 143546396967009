import commonStyles from "@/components/wrappers/Modals/components/common.module.scss";
import styles from "@/components/wrappers/Modals/components/ENSAnalyzeRequest/styles.module.scss";
import {Button} from "@/components/base/Button";
import {X} from "@/components/icons/X";
import clsx from "clsx";
import {IModalProps} from "@/components/wrappers/Modals/consts";
import {useSafeCloseModal} from "@/utils/common/modals";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import {useRef} from "react";
import {useAppDispatch} from "@/core/store/hooks";
import {business} from "@/business";
import {Typography} from "@/components/base/Typography";


export const CloseAttention = (
    {currentId}: IModalProps,
) => {
    const contentRef = useRef(null);
    const close = useSafeCloseModal(currentId);

    useOnClickOutside(
        close,
        contentRef,
    )

    const dp = useAppDispatch();

    const closeTwo = () => {
        dp(business.common.actions.closeTwoModals());
    }

    return <div className='position-absolute'>
        <div className={clsx(commonStyles.overlay, 'position-relative')}>
            <div className={clsx(commonStyles.content, 'align-self-start align-self-md-center', styles.content)}
                 ref={contentRef}>
                <Button
                    type='TextButton'
                    className={commonStyles.closeBtn}
                    onClick={close}
                >
                    <X/>
                </Button>
                <div className='d-flex flex-column gap-16'>
                    <Typography type='LargeTextSemibold'>
                        Выйти без сохранения
                    </Typography>
                    <Typography color='base-additional-grey'>
                        Если закрыть окно сейчас, то данные не сохранятся. Вы уверены, что хотите закрыть окно?
                    </Typography>
                    <div className='d-flex flex-column flex-md-row gap-8 gap-md-16'>
                        <Button className='w-100' type='Secondary' onClick={closeTwo}>
                            Закрыть без сохранения
                        </Button>
                        <Button className='w-100' onClick={close}>
                            Вернуться к загрузке
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}