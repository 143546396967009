import {Bank1CDirect} from "./components/Bank1CDirect";
import {
    UploadBankStatement,
} from "./components/UploadBankStatement";
import {LoginPassOFD} from "./components/LoginPassOFD";
import {
    UploadXLSXStatementOFD,
} from "@/components/wrappers/Modals/components/AddSource/components/Form/components/UploadXLSXStatementOFD";
import {AskNewSource} from "@/components/wrappers/Modals/components/AddSource/components/Form/components/AskNewSource";
import {WBAuth} from "@/components/wrappers/Modals/components/AddSource/components/Form/components/WBAuth";
import {
    WBAuthReports,
} from "@/components/wrappers/Modals/components/AddSource/components/Form/components/WBAuthReports";
import {YaMarketAuth} from "@/components/wrappers/Modals/components/AddSource/components/Form/components/YaMarketAuth";
import {
    YaMarketConfirm,
} from "@/components/wrappers/Modals/components/AddSource/components/Form/components/YaMarketConfirm";
import {
    OzonUploadStatement,
} from "@/components/wrappers/Modals/components/AddSource/components/Form/components/OzonUploadStatement";
import {OzonAuth} from "@/components/wrappers/Modals/components/AddSource/components/Form/components/OzonAuth";


export const AddFormComponents = {
    Bank1CDirect,
    UploadBankStatement,
    LoginPassOFD,
    UploadXLSXStatementOFD,
    AskNewSource,
    WBAuth,
    WBAuthReports,
    YaMarketAuth,
    YaMarketConfirm,
    OzonUploadStatement,
    OzonAuth,
}