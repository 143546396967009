import fp from 'lodash/fp'

export interface IAppConfigProps {
	storeConfig: {
		NEXT_PUBLIC_BASE_URI: string
		NEXT_PUBLIC_BACKEND_URI: string
		NEXT_PUBLIC_BACKEND_WS: string
		NEXT_PUBLIC_URI: string
		NEXT_PUBLIC_MOCK_DATA: 'true' | string
		NEXT_PUBLIC_TEST_ENV: 'true' | string
	}
}

export const cleanedConfig = () => {
	return fp.pick([
		'NEXT_PUBLIC_URI',
		'NEXT_PUBLIC_BASE_URI',
		'NEXT_PUBLIC_BACKEND_URI',
		'NEXT_PUBLIC_BACKEND_WS',
		'NEXT_PUBLIC_MOCK_DATA',
		'NEXT_PUBLIC_TEST_ENV',
	], process.env) as IAppConfigProps['storeConfig']
}

export type PropsWithAppConfig<P = object> = P & {
	config: Partial<typeof process.env> & {
		NEXT_PUBLIC_BACKEND_URI: string
		NEXT_PUBLIC_URI: string
		NEXT_PUBLIC_BACKEND_WS: string
		NEXT_PUBLIC_BASE_URI: string
		NEXT_PUBLIC_TEST_ENV: string
	}
}


export const isTestEnv = () => window?.__NEXT_DATA__?.props?.storeConfig?.NEXT_PUBLIC_TEST_ENV === 'true';
