import {Typography} from "@/components/base/Typography";
import {Button} from "@/components/base/Button";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {useEffect} from "react";
import {business} from "@/business";
import {asyncExecMetaSelector} from "@/business/common/selectors";
import fp from "lodash/fp";
import {StatusCondition} from "@/utils/networkMeta/StatusCondition";
import {Spin} from "@/components/icons/Spin";
import {closeModal} from "@/utils/common/modals";

export const YaMarketConfirm = () => {
    const dp = useAppDispatch();
    const meta = useAppSelector(
        asyncExecMetaSelector('sources/yaMarketAuthCheck'),
        fp.isEqual,
    )

    const openModal = async () => {
        dp(business.sources.actions.yaMarketRetryModal());
    }

    useEffect(() => {
        dp(business.sources.actions.yaMarketCheck())
    }, [dp]);

    return (
        <div className='vstack gap-32'>
            <img alt='logo' src='/imgs/thirdParty/logos/mpSvg/yandexHorizontal.svg'/>
            <StatusCondition
                status={meta.status}
                fallback={Spin}
                onLoading={Spin}
                onError={() => (
                    <>
                        <Typography type='H3Header'>
                            :(
                        </Typography>
                        <Typography>
                            Произошла ошибка при подключении Яндекс.Маркет. Повторите попытку. Если ошибка появится
                            снова обратитесь в чат поддержки
                        </Typography>
                        <Button onClick={openModal}>
                            Попробовать снова
                        </Button>
                    </>
                )}
                onLoaded={() => (
                    <>
                        <Typography type='H3Header'>
                            Все хорошо!
                        </Typography>
                        <Typography>
                            Подключение Яндекс.Маркет началось. Источник появится в вашем профиле в течении дня
                        </Typography>
                        <Button onClick={closeModal}>
                            Отлично!
                        </Button>
                    </>
                )}/>
        </div>
    )
}