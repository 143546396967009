import {IModalProps} from "@/components/wrappers/Modals/consts";
import {useParseQueryProps, useSafeCloseModal} from "@/utils/common/modals";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import commonStyles from "@/components/wrappers/Modals/components/common.module.scss";
import styles from "@/components/wrappers/Modals/components/ENSAnalyzeRequest/styles.module.scss";
import {Button} from "@/components/base/Button";
import {X} from "@/components/icons/X";
import {useRef} from "react";
import clsx from "clsx";
import {Typography} from "@/components/base/Typography";
import {Highlighter} from "@/components/composite/Highlighter";
import {business} from "@/business";
import {LoadingStatus} from "@/core/store/utils/sagas";

export const ConfirmSubmitReport = (
    {currentId}: IModalProps,
) => {
    const contentRef = useRef(null);
    const close = useSafeCloseModal(currentId);

    const query = useParseQueryProps(currentId);

    const dp = useAppDispatch();

    const user = useAppSelector(
        (state) => state.auth.user,
    )

    useOnClickOutside(
        close,
        contentRef,
    )
    
    const loading = useAppSelector((state) => state.actions.confirmSubmitReportMeta?.status === LoadingStatus.Loading)

    return (
        <div className='position-absolute'>
            <div className={clsx(commonStyles.overlay, commonStyles.overlayMediumPadging, 'position-relative')}>
                <div className={clsx(commonStyles.content, 'align-self-start', styles.content)} ref={contentRef}>
                    <Button
                        type='TextButton'
                        className={commonStyles.closeBtn}
                        onClick={close}
                    >
                        <X/>
                    </Button>
                    <div className='d-flex flex-column gap-md-24 gap-16'>
                        <Typography type='LargeTextSemibold'>
                            Подтверждение сдачи
                        </Typography>
                        <Typography type='SmallTextRegular'>
                            <Highlighter
                                text={`Подтверждаю, что документ принят налоговой ${user?.fns_code} - ${user?.fns_description}.\n\nСформировать повторно или проверить сформированные документы вы можете в разделе ["Отчётность"](/dashboard/reports)`}/>
                        </Typography>
                        <div className='d-flex flex-column flex-md-row gap-8'>
                            <Button className='w-100' type='Secondary' onClick={close}>
                                Отмена
                            </Button>
                            <Button
                                className='w-100'
                                loading={loading}
                                onClick={() => dp(business.actions.actions.confirmSubmitReport({ id: Number(query?.id) }))}
                            >
                                Подтвердить
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}