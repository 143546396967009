import {createNamespace} from "@/core/store/actions";
import {CreateUser, InnInfo, LeadInfoToSave, User} from "@/services/openapi";
import {IPayloadForAction} from "@/core/store/utils/sagas";
import {FormType} from "@/components/views/auth/RegistrationStep3/consts";


export const namespace = 'auth';

const actionCreator = createNamespace(namespace);


export interface State {
    currentToken: string | null;
    user: User | null;
    leadPreSave: Omit<LeadInfoToSave, 'phone_number'> | null;
    restorePassCooldown: number | null;
    accumulatedRegisterData: {
        email: string | null;
        step2RetryCooldown: number | null;
        stepProgress: number;
    },
    states: Partial<Record<string, Partial<IPayloadForAction>>>
    innInfo: InnInfo | null;
}

export const initialState: State = {
    currentToken: null,
    user: null,
    states: {},
    leadPreSave: null,
    innInfo: null,
    restorePassCooldown: null,
    accumulatedRegisterData: {
        email: null,
        step2RetryCooldown: null,
        stepProgress: 20,
    },
}

export const sagaActions = {
    getToken: actionCreator<{ username: string, password: string }>('getToken'),
    tryInitialize: actionCreator<string | undefined>('tryInitialize'),
    getUserInfo: actionCreator('getUserInfo'),
    sendFirstRegistrationDataPackage: actionCreator<CreateUser>('sendFirstRegistrationDataPackage'),
    sendSecondRegistrationDataPackage: actionCreator<string>('sendSecondRegistrationDataPackage'),
    sendThirdRegistrationDataPackage: actionCreator<FormType>('sendThirdRegistrationDataPackage'),
    sendUnsupportedLead: actionCreator<string>('sendUnsupportedLead'),
    completeRegistration: actionCreator('completeRegistration'),
    retryRegistrationFirstStep: actionCreator('retryRegistrationFirstStep'),
    searchINN: actionCreator<string>('searchINN'),
    askRestorePass: actionCreator<string>('askRestorePass'),
    changeOnRestorePass: actionCreator<{ pass: string, token: string }>('changeOnRestorePass'),
    openActionListener: actionCreator('openActionListener'),
    changePass: actionCreator<string>('changePass'),
    logoutModal: actionCreator('logoutModal'),
}
