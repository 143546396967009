import {MarketplaceKey, OfdSource} from "@/services/openapi";

export const logos: Record<string, string | null> = {
    // broken prevent
    'undefined': null,
    'null': null,

    // MP
    'Ozon': '/imgs/thirdParty/logos/mpSvg/ozon.svg',
    'Wildberries': '/imgs/thirdParty/logos/mpSvg/wb.svg',
    'Яндекс.Маркет': '/imgs/thirdParty/logos/mpSvg/yandex.svg',

    // MP enum duplicate
    [MarketplaceKey.yaMarket]: '/imgs/thirdParty/logos/mpSvg/yandex.svg',
    [MarketplaceKey.wb]: '/imgs/thirdParty/logos/mpSvg/wb.svg',
    [MarketplaceKey.ozon]: '/imgs/thirdParty/logos/mpSvg/ozon.svg',

    // OFD
    [OfdSource.ofdRu]: '/imgs/thirdParty/logos/ofdSvg/OfdRu.svg',
    [OfdSource.perviiOfd]: '/imgs/thirdParty/logos/ofdSvg/FirstOFD.svg',
    [OfdSource.platformaOfd]: '/imgs/thirdParty/logos/ofdSvg/PlatformaOFD.svg',
    [OfdSource.yandeksOfd]: '/imgs/thirdParty/logos/ofdSvg/YaOFD.svg',
    [OfdSource.sbisOfd]: '/imgs/thirdParty/logos/ofdSvg/sbis.svg',
    [OfdSource.takskomOfd]: '/imgs/thirdParty/logos/ofdSvg/taxCom.svg',
    [OfdSource.konturOfd]: '/imgs/thirdParty/logos/ofdSvg/konturOFD.svg',

    // Banks
    'АБ Россия': '/imgs/thirdParty/logos/banksSvg/abr.svg',
    'Альфа-Банк': '/imgs/thirdParty/logos/banksSvg/alfabank.svg',
    'АТБ Банк': '/imgs/thirdParty/logos/banksSvg/atb.svg',
    'Абсолют Банк': '/imgs/thirdParty/logos/banksSvg/absolutbank.svg',
    'Авангард': '/imgs/thirdParty/logos/banksSvg/avangard.svg',
    'Азиатско-Тихоокеанский Банк': '/imgs/thirdParty/logos/banksSvg/atb.svg',
    'Ак Барс': '/imgs/thirdParty/logos/banksSvg/akbars.svg',
    'Акибанк': '/imgs/thirdParty/logos/banksSvg/akibank.svg',
    'БКС Банк': '/imgs/thirdParty/logos/banksSvg/bcs-bank.svg',
    'Банк 131': '/imgs/thirdParty/logos/banksSvg/131.svg',
    'Банк Зенит': '/imgs/thirdParty/logos/banksSvg/zenit.svg',
    'Банк Интеза': '/imgs/thirdParty/logos/banksSvg/intesa.svg',
    'Банк Казани': '/imgs/thirdParty/logos/banksSvg/ofkazan.svg',
    'Банк Санкт-Петербург': '/imgs/thirdParty/logos/banksSvg/bspb.svg',
    'Бланк банк': '/imgs/thirdParty/logos/banksSvg/blanc.svg',
    'ВБРР': '/imgs/thirdParty/logos/banksSvg/vbrr.svg',
    'ВТБ': '/imgs/thirdParty/logos/banksSvg/vtb.svg',
    'ВТБ 24': '/imgs/thirdParty/logos/banksSvg/vtb.svg',
    'Вайлдберриз Банк': '/imgs/thirdParty/logos/banksSvg/wb-bank.svg',
    'Возрождение': '/imgs/thirdParty/logos/banksSvg/vbank.svg',
    'Восточный': '/imgs/thirdParty/logos/banksSvg/sovcombank.svg',
    'ГПБ': '/imgs/thirdParty/logos/banksSvg/gazprombank.svg',
    'ДОМ.РФ': '/imgs/thirdParty/logos/banksSvg/domrfbank.svg',
    'Делобанк': '/imgs/thirdParty/logos/banksSvg/delo.svg',
    'Живаго Банк': '/imgs/thirdParty/logos/bankPng/jBank422.png',
    'Ингосстрах Банк': '/imgs/thirdParty/logos/banksSvg/ingobank.svg',
    'Камкомбанк': '/imgs/thirdParty/logos/banksPng/kkb625.png',
    'Киви Банк': '/imgs/thirdParty/logos/banksSvg/qiwibank.svg',
    'Контур.Банк': '/imgs/thirdParty/logos/banksSvg/kontur-bank.svg',
    'Кредит Европа Банк': '/imgs/thirdParty/logos/banksSvg/crediteurope.svg',
    'Кубань Кредит': '/imgs/thirdParty/logos/banksSvg/kk-bank.svg',
    'Ланта-Банк': '/imgs/thirdParty/logos/banksSvg/lanta.svg',
    'Левобережный': '/imgs/thirdParty/logos/banksSvg/nskbl.svg',
    'Локо-Банк': '/imgs/thirdParty/logos/banksSvg/lockobank.svg',
    'МТС Банк': '/imgs/thirdParty/logos/banksSvg/mtsbank.svg',
    'Металлинвестбанк': '/imgs/thirdParty/logos/banksSvg/metallinvestbank.svg',
    'Модульбанк': '/imgs/thirdParty/logos/banksSvg/modulbank.svg',
    'Морской Банк': '/imgs/thirdParty/logos/banksSvg/maritimebank.svg',
    'ОТП Банк': '/imgs/thirdParty/logos/banksSvg/otpbank.svg',
    'Озон Банк': '/imgs/thirdParty/logos/banksSvg/ozon.svg',
    'ПСБ': '/imgs/thirdParty/logos/banksSvg/psbank.svg',
    'Почта Банк': '/imgs/thirdParty/logos/banksSvg/pochtabank.svg',
    'ПростоБанк': '/imgs/thirdParty/logos/banksSvg/prosto.svg',
    'РЕСО Кредит': '/imgs/thirdParty/logos/banksSvg/resocredit.svg',
    'РНКБ Банк': '/imgs/thirdParty/logos/banksSvg/rncb.svg',
    'Развитие': '/imgs/thirdParty/logos/banksSvg/dcapital.svg',
    'Развитие-Столица': '/imgs/thirdParty/logos/banksSvg/dcapital.svg',
    'Райффайзенбанк': '/imgs/thirdParty/logos/banksSvg/raiffeisen.svg',
    'Ренессанс': '/imgs/thirdParty/logos/banksSvg/rencredit.svg',
    'Росбанк': '/imgs/thirdParty/logos/banksSvg/rosbank.svg',
    'Россельхозбанк': '/imgs/thirdParty/logos/banksSvg/rshb.svg',
    'Руснарбанк': '/imgs/thirdParty/logos/banksSvg/rusnarbank.svg',
    'Русский Стандарт': '/imgs/thirdParty/logos/banksSvg/rsb.svg',
    'СДМ-Банк': '/imgs/thirdParty/logos/banksSvg/sdm.svg',
    'СМП Банк': '/imgs/thirdParty/logos/banksSvg/smpbank.svg',
    'СПБ Банк': '/imgs/thirdParty/logos/banksSvg/spbbank.svg',
    'Сбербанк': '/imgs/thirdParty/logos/banksSvg/sberbank.svg',
    'Связь-Банк': '/imgs/thirdParty/logos/bankPng/svb200.png',
    'Совкомбанк': '/imgs/thirdParty/logos/banksSvg/sovcombank.svg',
    'Солидарность': '/imgs/thirdParty/logos/banksSvg/solid.svg',
    'Т-Банк': '/imgs/thirdParty/logos/banksSvg/tbank.svg',
    'Точка Банк': '/imgs/thirdParty/logos/banksSvg/tochka.svg',
    'УБРиР': '/imgs/thirdParty/logos/banksSvg/ubrr.svg',
    'Уралсиб': '/imgs/thirdParty/logos/banksSvg/uralsib.svg',
    'ФК Открытие': '/imgs/thirdParty/logos/banksSvg/open.svg',
    'Финам': '/imgs/thirdParty/logos/banksSvg/finambank.svg',
    'Фора-Банк': '/imgs/thirdParty/logos/banksSvg/forabank.svg',
    'Хайс': '/imgs/thirdParty/logos/banksSvg/hicebank.svg',
    'Хлынов': '/imgs/thirdParty/logos/banksSvg/hlynov.svg',
    'Центр-инвест': '/imgs/thirdParty/logos/banksSvg/centrinvest.svg',
    'Экспобанк': '/imgs/thirdParty/logos/banksSvg/expobank.svg',
    'Энерготрансбанк': '/imgs/thirdParty/logos/banksSvg/energotransbank.svg',
    'ЮниКредит': '/imgs/thirdParty/logos/banksSvg/unicreditbank.svg',
    'Юнистрим': '/imgs/thirdParty/logos/banksSvg/unistream.svg',
    'Яндекс Банк': '/imgs/thirdParty/logos/banksSvg/yandex.svg',
    //
    'МКБ': '/imgs/thirdParty/logos/banksSvg/mkb.svg',
    // huh
    'ВВБ': null,
}

