import {PayloadAction} from "@reduxjs/toolkit";
import {NetworkMetaMapKeysType, PayloadSubDataConfig} from "@/business/common/types";


export const generateExecId = <P = void, T extends string = string>(preset?: NetworkMetaMapKeysType) => (payload: P, id?: NetworkMetaMapKeysType): Omit<PayloadAction<P, T, PayloadSubDataConfig>, 'type'> => {
    const executeId = preset || id;

    return ({
        payload,
        meta: {
            asyncConfig: executeId ? {executeId} : undefined,
        },
    });
}