import {useSafeCloseModal} from "@/utils/common/modals";
import {useRef} from "react";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import {Button} from "@/components/base/Button";
import {X} from "@/components/icons/X";
import commonStyles from "../common.module.scss";
import styles from "./styles.module.scss";
import clsx from "clsx";
import {Typography} from "@/components/base/Typography";
import {FormProvider, useForm} from "react-hook-form";
import {Input} from "@/components/form/Input";

import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useAppDispatch} from "@/core/store/hooks";
import {business} from "@/business";
import {IModalProps} from "@/components/wrappers/Modals/consts";
import i18next from "i18next";
import {yupPhone} from "@/utils/yup/common";


export const phoneSchemaValidate = yup.object().shape({
	tel: yup.string()
		.required(() => i18next.t('common.errors.yup.common.required'))
		.concat(yupPhone)
		.default(''),
})


export const ENSAnalyzeRequest = (
	{currentId}: IModalProps,
) => {
	const contentRef = useRef(null);
	const close = useSafeCloseModal(currentId);

	const dp = useAppDispatch();

	useOnClickOutside(
		close,
		contentRef,
	)

	const methods = useForm({
		resolver: yupResolver(phoneSchemaValidate),
		reValidateMode: 'onChange',
		defaultValues: phoneSchemaValidate.getDefault(),
	});

	return (
		<div className='position-absolute'>
			<div className={clsx(commonStyles.overlay, 'position-relative')}>
				<div className={clsx(commonStyles.content, 'align-self-start align-self-md-center', styles.content)} ref={contentRef}>
					<Button
						type='TextButton'
						className={commonStyles.closeBtn}
						onClick={close}
					>
						<X/>
					</Button>
					<FormProvider {...methods}>
						<form
							className='d-flex flex-column gap-24'
							onSubmit={methods.handleSubmit((d) => dp(business.users.actions.sendLeadEns(d.tel)))}
						>
							<div className='d-flex flex-column gap-8'>
								<Typography type='LargeTextSemibold'>
									Анализ ЕНС
								</Typography>
								<Typography type='SmallTextRegular'>
									Доступен на платном тарифе
								</Typography>
							</div>
							<Typography type='SmallTextRegular'>
								В тарифы аутсорсинга входит сверка с бюджетом и ежеквартальный контроль состояния единого налогового
								счета.
							</Typography>
							<Typography type='SmallTextRegular'>
								Оставьте номер телефона, и мы расскажем о тарифах подробнее
							</Typography>
							<Input name='tel' placeholder='+7 900 123 12 12'/>
							<div className='d-flex flex-column flex-md-row gap-8'>
								<Button type='Secondary' onClick={close} className='d-flex w-100 d-xl-none'>
									Отменить
								</Button>
								<Button
									disabled={!methods.formState.isValid}
									htmlType='submit'
									className='d-flex w-100'
								>
									Сохранить
								</Button>
							</div>
						</form>
					</FormProvider>

				</div>
			</div>
		</div>
	)
}
