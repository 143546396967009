import fp from 'lodash/fp'
import {useEffect, useState} from "react";
import {Typography} from "@/components/base/Typography";
import {CaretDown} from "@/components/icons/CaretDown";
import {CaretUp} from "@/components/icons/CaretUp";

import styles from './styles.module.scss'
import clsx from "clsx";
import React from "react";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {business} from "@/business";
import {EnsInfo} from "@/services/openapi";
import {useFormContext} from "react-hook-form";


const fieldsMatch: Partial<Record<keyof EnsInfo, React.ReactNode>> = {
	'receiver_name': 'Наименование',
	'kbk': 'КБК',
	'receiver_inn': 'ИНН',
	'receiver_cor_account': 'Счёт',
	'receiver_bank_bik': 'БИК',
	'receiver_bank_name': 'Банк',
}

export const RecipientDetails = () => {
	const [isOpen, setIsOpen] = useState(true);

	const dp = useAppDispatch();

	const data = useAppSelector(
		(s) => s.taxes.ensInfo,
		fp.isEqual,
	)

	useEffect(() => {
		dp(business.taxes.actions.getEnsInfo())
	}, [dp]);

	const { setValue } = useFormContext();

	useEffect(() => {
		const purpose = data?.purpose

		if (!fp.isEmpty(purpose)) {
			setValue('purpose', purpose, { shouldValidate: true });
		}
	}, [data, setValue]);

	return (
		<div>
			<div className='d-flex flex-column gap-8'>
				<Typography
					type='NormalTextSemibold'
					className={clsx(styles.openBtn, 'd-inline-flex align-items-center gap-8 p-0')}
					onClick={() => setIsOpen(!isOpen)}
					as='button'
					additionalProps={{ type: 'button' }}
				>
					<span>
						Реквизиты получателя
					</span>
					{isOpen ? <CaretUp/> : <CaretDown/>}
				</Typography>
				{isOpen && (
					<Typography type='SmallTextRegular' className={clsx(styles.list, 'd-flex row-gap-4 flex-column d-md-grid column-gap-md-16 row-gap-md-8')}>
						{(Object.keys(fieldsMatch) as (keyof EnsInfo)[]).map((item, index) => (
							<React.Fragment key={index}>
								<Typography type='Inherit' color='base-secondary-1'>
									{fieldsMatch[item]}
								</Typography>
								<Typography type='Inherit' color='base-primary-dark' className='mb-6 mb-md-0'>
									{fp.get(item, data)}
								</Typography>
							</React.Fragment>
						))}
					</Typography>
				)}
			</div>
		</div>
	)
}