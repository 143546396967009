import styles from './common.module.scss';
import clsx from "clsx";

export const Icon = (
    {state, disabled = false}: { state: boolean, disabled?: boolean },
) => (
    <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx('d-block', styles.icon, disabled && styles.iconDisabled)}
    >
        <rect x="0.5" y="0.5" width="21" height="21" rx="10.5" stroke='var(--outer-circle)'/>
        {state && <circle cx="11" cy="11" r="7" fill='var(--inner-circle)'/>}
    </svg>
)