import {useAppSelector} from "@/core/store/hooks";
import {RootState} from "@/core/store";
import {useEffect, useMemo} from "react";
import {FieldValues, Path, UseFormReturn} from "react-hook-form";


export const useGetStoreErrors = (
    callback: (s: RootState) => string | null | undefined,
) => {
    const error = useAppSelector(
        (state) => callback(state) || null,
    )

    return useMemo(
        () => (
            error ?
                {type: 'backend', message: error}
                : undefined
        ), [error],
    )
}


export const useSubscribeError = <F extends FieldValues = FieldValues>(
    fm: UseFormReturn<F, any, any>,
    name: Path<F>,
    msg: string | null | undefined,
    type?: string | null,
) => {
    useEffect(() => {
        return fm.watch((_, {name: iternalName}) => {
            if (name === iternalName && msg) {
                fm.setError(iternalName, {})
            }
        }).unsubscribe;
    }, [fm, msg, name]);

    useEffect(() => {
        if (msg) {
            fm.setError(name, {type: type || 'backend', message: msg})
        }
    }, [
        fm,
        msg,
        name,
        type,
    ])
}