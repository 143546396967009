import {createAction, PayloadActionCreator, PrepareAction} from '@reduxjs/toolkit';

const isPrepareAction = <A = any>(v: any): v is PrepareAction<A> => typeof v !== 'undefined';

interface IPseudoOverrideActionCreator<NS extends string> {
	<A = void, T extends string = string>(type: T): PayloadActionCreator<A, `${NS}/${T}`>
	<PA extends PrepareAction<any>, T extends string>(type: T, prepareAction: PA): PayloadActionCreator<ReturnType<PA>['payload'], `${NS}/${T}`, PA>
}

export const createNamespace = <N extends string>(ns: N): IPseudoOverrideActionCreator<N> => {
	return (actionName: string, prepareAction?: PrepareAction<any>) => {
		if (isPrepareAction(prepareAction)) {
			return createAction(`${ns}/${actionName}`, prepareAction);
		}

		return createAction(`${ns}/${actionName}`);
	};
}