import React, {ElementType, useCallback, useRef, useState} from "react";
import {useFormContext} from "react-hook-form";
import {Typography} from "@/components/base/Typography";
import clsx, {ClassValue} from "clsx";
import fp from "lodash/fp";

import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import {CaretDown} from "@/components/icons/CaretDown";
import {computeToFullValues, searchFieldNameCreator} from "./utils";
import {BaseValue, IFullValue} from "./types";
import {DefaultRender} from "./DefaultRender";
import {autoObjectSearch} from "@/utils/common/functions";
import {Check} from "@/components/icons/Check";
import styles from './styles.module.scss';
import {Null} from "@/components/base/Null";
import {dropdownFloatingPortalKey} from "@/utils/common/dropdown";
import {autoUpdate, FloatingPortal, offset, shift, size, useFloating} from "@floating-ui/react";


interface ISelectProps<
    V extends BaseValue = BaseValue,
    VE = IFullValue<V> | V,
    VEA = IFullValue<V>[] | V[],
> {
    name: string
    label?: React.ReactNode
    type?: 'single' | 'multiple'
    className?: ClassValue
    search?: (v: VE, searchString?: string) => boolean
    placeholder?: string
    required?: boolean
    disabled?: boolean
    values?: VEA
    renderOption?: React.FC<{ entity: IFullValue<V> }>;
    renderSelected?: React.FC<{ entity: IFullValue<V> }>;
    dropdownFooter?: React.FC;
}


export const Select = <V extends BaseValue, Props extends ISelectProps<V>>(
    {
        name,
        type = 'single',
        values = [],
        label,
        search = autoObjectSearch,
        placeholder = 'Выберите',
        required = false,
        disabled = false,
        renderSelected: Selected = DefaultRender,
        renderOption: Option = DefaultRender,
        dropdownFooter: DropdownFooter = Null,
        className,
    }: Props,
) => {
    const isSingle = type === 'single';
    const isMultiple = type === 'multiple';
    const searchFieldName = searchFieldNameCreator(name);

    const searchRef = useRef<HTMLInputElement>(null);

    const {formState, register, watch, setValue, setFocus} = useFormContext();
    const searchFieldValue = watch(searchFieldName);

    const filteredValues = (searchFieldValue
            ? values?.filter((v) => search(v, searchFieldValue))
            : values
    ) as Props['values']

    const fullValues = computeToFullValues<V>(filteredValues || [])

    const fullValuesAll = computeToFullValues<V>(values || [])

    const ref = useRef<ElementType>(null);

    const [isOpen, setIsOpen] = useState(false);

    const changeIsOpen = (v: boolean) => {
        if (!disabled) {
            if (v !== isOpen) {
                setFocus(searchFieldName, {shouldSelect: v})

                setValue(searchFieldName, '', {shouldTouch: false})
            }
            setIsOpen(v);
        }
    }

    // const switchIsOpen = () => changeIsOpen(!isOpen);

    // const reg = register(name);
    const regSearch = register(searchFieldName);

    const val = watch(name) as typeof type extends 'single' ? V : V[];

    const error = fp.get(`errors.${name}.message`, formState) as string;

    const processSelectValue = (value: IFullValue<V>) => () => {

        if (value.disable) return;

        if (isSingle) {
            setValue(name, value.value, {shouldValidate: true, shouldTouch: true, shouldDirty: true});
            changeIsOpen(false);
            searchRef.current?.blur();
        }

        if (isMultiple) {
            const newArr = fp.xor(val, [value.value])
            setValue(name, newArr, {shouldValidate: true, shouldTouch: true, shouldDirty: true});
        }
    }

    useOnClickOutside(
        () => changeIsOpen(false),
        ref,
    )

    const SingleOrMapSelected = useCallback(() => {
        if (isSingle && fp.isEmpty(searchFieldValue)) {
            const entity = fp.find({value: val}, fullValues) as IFullValue<V>;
            if (entity) {
                return <Selected entity={entity}/>
            }
        }

        if (isMultiple) {
            const filteredEntities = fp.filter(({value}) => val?.includes(value), fullValuesAll) as IFullValue<V>[];

            if (filteredEntities.length > 0) {
                return <div className={clsx('d-flex gap-4 align-items-end', styles.selectedMultipleContainer)}>
                    <Selected entity={filteredEntities[0]}/>
                    {filteredEntities.length > 1 ? `+${filteredEntities.length - 1}` : null}
                </div>
            }

            return null;
        }

        return null
    }, [
        Selected,
        fullValues,
        fullValuesAll,
        isMultiple,
        isSingle,
        searchFieldValue,
        val,
    ])

    const placeholderDisplay = isMultiple
        ? (val?.length >= 1 ? undefined : placeholder)
        : (
            ['null', 'undefined'].includes(typeof val)
                ? placeholder
                : undefined
        );

    const {refs, floatingStyles} = useFloating({
        open: isOpen,
        elements: {
            reference: ref.current as any,
        },
        onOpenChange: setIsOpen,
        middleware: [
            offset(6),
            shift(),
            size({
                apply({rects, elements}) {
                    Object.assign(elements.floating.style, {
                        minWidth: `${rects.reference.width}px`,
                    });
                },
            }),
        ],
        whileElementsMounted: autoUpdate,
        placement: 'bottom-start',
    });

    return (
        <div className={clsx('d-flex flex-column gap-2', className)}>
            {(label || required) && <Typography type='SmallTextRegular'>
                {label}
                {required && <Typography color='red-primary' as='span'>*</Typography>}
            </Typography>}
            <Typography
                ref={ref}
                className={clsx(
                    'd-flex align-items-center position-relative gap-2',
                    styles.content,
                    isOpen && styles.contentOpen,
                    disabled && styles.contentDisabled,
                )}
                type='SmallTextRegular'
                color='base-secondary-1'
                onClick={() => changeIsOpen(true)}
            >
                <div className='flex-shrink-0'>
                    <SingleOrMapSelected/>
                </div>
                <input
                    placeholder={placeholderDisplay}
                    readOnly={disabled}
                    autoComplete='off'
                    className={clsx('flex-shrink-1', styles.searchInput)}
                    {...regSearch}
                    ref={(ref) => {
                        searchRef.current = ref;
                        regSearch.ref(ref)
                    }}
                />
                <span>
					<CaretDown/>
				</span>
            </Typography>
            <FloatingPortal id={dropdownFloatingPortalKey}>
                {isOpen && (
                    <div
                        className={clsx(styles.dropContainer, 'd-flex flex-column')}
                        ref={refs.setFloating}
                        style={floatingStyles}
                    >
                        {fullValues?.map((e) => {
                            const isSelected = !fp.isArray(val)
                                ? e.value === val
                                : val.includes(e.value);

                            const selectedClass = isSelected && styles.dropItemSelected;
                            const disabledClass = e.disable && styles.dropItemDisabled;

                            return (
                                <Typography
                                    type='SmallTextRegular'
                                    key={e.value}
                                    onClick={processSelectValue(e)}
                                    className={clsx(
                                        styles.dropItem,
                                        selectedClass,
                                        disabledClass,
                                        'd-flex align-items-center',
                                    )}
                                >
									<span>
										<Option entity={e}/>
									</span>
                                    {isSelected && <div className={clsx(styles.dropItemCheck, 'ms-auto')}>
                                        <Check/>
                                    </div>}
                                </Typography>
                            );
                        })}
                        <DropdownFooter/>
                    </div>
                )}
            </FloatingPortal>
            {error && (
                <Typography type='SmallLabel' color='red-primary' as='span'>
                    {error}
                </Typography>
            )}
        </div>
    )
}
