import {SelectStyle, SelectType} from "@/utils/data/sources/cms/types";
import {CardFullWidth} from "@/components/wrappers/Modals/components/AddSource/components/List/CardFullWidth";
import {CardShrink} from "@/components/wrappers/Modals/components/AddSource/components/List/CardShrink";
import clsx from "clsx";

import styles from './styles.module.scss';
import {computeIsType} from "@/utils/common/functions";
import {Button} from "@/components/base/Button";
import {LocalAddSources} from "@/utils/data/sources/cms/local";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {business} from "@/business";
import fp from "lodash/fp";
import {MarketplaceKey, SourceType} from "@/services/openapi";

export const List = (
    {
        actions,
        style,
        id,
    }: SelectType) => {
    const isRoot = LocalAddSources.root_id === id;

    const dp = useAppDispatch();
    const isStyle = computeIsType(style, SelectStyle);

    const actionsCutIds: Record<typeof id, string[]> = useAppSelector(
        (state) => {
            const sourceList = state.sources.sourceList;

            const wbExist = fp.some(
                {type: SourceType.marketplace, name: MarketplaceKey.wb},
                sourceList,
            )
            const yaExist = fp.some(
                {type: SourceType.marketplace, name: MarketplaceKey.yaMarket},
                sourceList,
            )

            return {
                'b0672913-48bb-4ede-8f08-46329ac5501f': fp.compact([
                    wbExist && 'c69dacf0-4ee9-4d1e-9d5d-cee444732511',
                    yaExist && '79ef7a22-dc22-4a87-a004-48c7a83608b5',
                ]),
            };
        },
        fp.isEqual,
    )

    const cutIds = actionsCutIds[id] || [];

    const availableActions = fp.filter(
        fp.where({id: (v: string) => !cutIds.includes(v)}),
        actions,
    );

    const Component = isStyle.column
        ? CardFullWidth
        : CardShrink;

    return (
        <>
            <div className={clsx(
                'gap-8',
                isStyle.column && 'd-flex flex-column',
                isStyle.row && styles.list,
            )}>
                {...availableActions.map((action) => (
                    <Component key={action.id} {...action} />
                ))}
            </div>
            {isRoot || (
                <Button type='Secondary' onClick={() => {
                    dp(business.sources.actions.prevStep())
                }}>
                    ← Назад
                </Button>
            )}
        </>
    )
}
