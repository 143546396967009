import {Source} from "@/services/openapi";
import {Typography} from "@/components/base/Typography";
import {displayAccountNumber, displayProviderName} from "@/utils/data/sources/format";
import {truncateString} from "@/utils/data/common/format";
import { useIsBreakpointUp } from "@/utils/common/browser";
import { useMemo } from "react";

export const AccountSelectItem = (
    props: Source,
) => {
    const dbn = displayProviderName(props);

    const isBreakpointUp = useIsBreakpointUp();

    const length = useMemo(() => {
        let lengthAcc = 16;

        if (isBreakpointUp?.md) {
            lengthAcc = 8;
        }

        if (isBreakpointUp?.lg) {
            lengthAcc = 10;
        }

        if (isBreakpointUp?.xl) {
            lengthAcc = 20;
        }

        return lengthAcc
    }, [isBreakpointUp])



    return (
        <Typography
            type='SmallTextRegular' color='base-primary-dark'
            className='d-flex align-items-baseline gap-8 flex-fill'
        >
			<span
                title={(dbn?.length || 0) > length ? dbn : undefined}
            >
				{truncateString(dbn, length)}
			</span>
            <Typography type='LabelRegular' color='base-secondary-1'>
                {displayAccountNumber(props)}
            </Typography>
        </Typography>
    )
}