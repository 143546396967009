import {FormType} from "@/utils/data/sources/cms/types";
import fp from "lodash/fp";
import {Null} from "@/components/base/Null";
import {AddFormComponents} from './consts';

export const Form = (
    {
        id,
    }: FormType,
) => {
    const Component = fp.getOr(Null, id, AddFormComponents);

    return (
        <Component/>
    )
}