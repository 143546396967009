import {sagaActions} from "./inits";
import {slice} from "./slice";
import {sagas} from "./sagas";


export const taxes = {
	slice,
	actions: {...slice.actions, ...sagaActions},
	sagas,
}
