import {motion} from "motion/react";
import {useMemo} from "react";


const animate = {
    rotate: [0, 360],
    pathLength: [0.1, 1, 0.1],
}

const transition = {
    pathLength: {
        ease: 'linear',
        duration: 3,
        repeat: Infinity,
    },
    rotate: {
        ease: 'linear',
        duration: .7,
        repeat: Infinity,
    },
};

export const Spin = ({size = 14}: { size?: number }) => {
    const centerPos = useMemo(() => size / 2, [size]);
    const lineWidth = useMemo(() => Math.floor(centerPos / 6), [centerPos]);
    const radius = useMemo(() => centerPos - lineWidth - 0.1, [centerPos, lineWidth]);

    return (
        <motion.svg
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='d-block'
        >
            <motion.circle
                cx={centerPos}
                cy={centerPos}
                r={radius}
                strokeWidth={lineWidth}
                stroke='currentColor'
                animate='animate'
                variants={{animate}}
                transition={transition}
            />
        </motion.svg>
    );
}
