import {logger} from "@/utils/logger";
import {PayloadActionCreator} from "@reduxjs/toolkit";
import {put} from "redux-saga/effects";
import {Wrapper} from "@/utils/common/functions";
import {AxiosError} from "axios";
import {strictKickUnlikeUser} from "@/utils/routing";


export enum LoadingStatus {
    Never,
    Loading,
    Loaded,
    Error,
}

export interface IPayloadForAction {
    status: LoadingStatus,
    key?: string,
    message?: string
}

/**
 * @deprecated please use new same name realization (use `mixinWithErrorHandlerNext` for non conflict usage)
 */
export const mixinWithErrorHandler =
    (action: PayloadActionCreator<IPayloadForAction>, key?: string): Wrapper =>
        (next) =>
            function* errorHandlerWrapper(args) {
                logger.info(
                    `Include mixin %cWithErrorHandler%c -> %c${args?.type}`,
                    'color: yellow; font-style: italic;',
                    'color: #008cff; font-style: normal;',
                    'color: green; font-style: italic;',
                )
                yield put(action({key, status: LoadingStatus.Never}));

                try {
                    yield* next();
                } catch (error) {
                    if (error instanceof AxiosError) {
                        logger.warn(error.response?.status, error.response?.data)

                        yield put(action({
                            key,
                            status: LoadingStatus.Error,
                            message: error?.response?.data?.detail?.message,
                        }));

                        if (error.response?.status === 401) {
                            strictKickUnlikeUser();
                        }

                        return;
                    }
                    if (error instanceof Error) {
                        logger.error(error)
                        return yield put(action({key, status: LoadingStatus.Error, message: error.message}));
                    }
                }
            };


export const mixinWithSimpleErrorHandler =
    (): Wrapper =>
        (next) =>
            function* errorHandlerWrapper(args) {
                logger.info(
                    `Include mixin %cWithSimpleErrorHandler%c -> %c${args?.type}`,
                    'color: yellow; font-style: italic;',
                    'color: #008cff; font-style: normal;',
                    'color: green; font-style: italic;',
                )
                try {
                    yield* next();
                } catch (error) {
                    if (error instanceof AxiosError) {
                        logger.warn(error.response?.status, error.response?.data)

                        if (error.response?.status === 401) {
                            strictKickUnlikeUser();
                        }

                        return;
                    }
                    if (error instanceof Error) {
                        logger.error(error)
                    }
                }
            };

/**
 * @deprecated please use new same name realization (use `mixinWithLoadingHandlerNext` for non conflict usage)
 */
export const mixinWithLoadingHandler =
    (action: PayloadActionCreator<IPayloadForAction>, key?: string): Wrapper =>
        (next) =>
            function* errorHandlerWrapper(args) {
                logger.info(
                    `Include mixin %cWithLoadingHandler%c -> %c${args?.type}`,
                    'color: yellow; font-style: italic;',
                    'color: #008cff; font-style: normal;',
                    'color: green; font-style: italic;',
                )

                yield put(action({key, status: LoadingStatus.Loading}));
                yield* next();
                yield put(action({key, status: LoadingStatus.Loaded}));
            };

