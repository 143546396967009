import {IModalProps} from "@/components/wrappers/Modals/consts";
import {useSafeCloseModal} from "@/utils/common/modals";
import {useAppDispatch} from "@/core/store/hooks";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import commonStyles from "@/components/wrappers/Modals/components/common.module.scss";
import styles from "@/components/wrappers/Modals/components/ENSAnalyzeRequest/styles.module.scss";
import {Button} from "@/components/base/Button";
import {X} from "@/components/icons/X";
import {useRef} from "react";
import clsx from "clsx";
import {business} from "@/business";
import {Typography} from "@/components/base/Typography";


export const LogOut = (
    {currentId}: IModalProps,
) => {
    const contentRef = useRef(null);
    const close = useSafeCloseModal(currentId);

    const dp = useAppDispatch();

    useOnClickOutside(
        close,
        contentRef,
    )

    return (
        <div className='position-absolute'>
            <div className={clsx(commonStyles.overlay, 'position-relative')}>
                <div className={clsx(commonStyles.content, 'align-self-start align-self-md-center', styles.content)}
                     ref={contentRef}>
                    <Button
                        type='TextButton'
                        className={commonStyles.closeBtn}
                        onClick={close}
                    >
                        <X/>
                    </Button>
                    <div className='d-flex flex-column gap-32 w-100 m-16'>
                        <Typography type='H4Header'>
                            Выйти из профиля?
                        </Typography>
                        <div className='d-flex flex-column flex-md-row gap-8 gap-md-16'>
                            <Button
                                type='Secondary'
                                onClick={() => dp(business.auth.actions.logoutModal())}
                                className='w-100'
                            >
                                Выйти
                            </Button>
                            <Button
                                onClick={close}
                                className='w-100'
                            >
                                Отмена
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
