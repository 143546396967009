import {useFormContext} from "react-hook-form";
import {checkReductionAttemptByRate, FormType} from "../../consts";
import {RateReasonType, TaxSystemType} from "@/services/openapi";
import {computeIsType} from "@/utils/common/functions";
import {Typography} from "@/components/base/Typography";

import styles from './styles.module.scss';
import clsx from "clsx";
import {Button} from "@/components/base/Button";
import {useEffect, useMemo} from "react";
import {Info16} from "@/components/icons/Info";
import {Select} from "@/components/form/Select";
import {Input} from "@/components/form/Input";
import {Tooltip} from "@/components/base/Tooltip";


export const reasonTypeValues = [
    {value: RateReasonType.nothing, label: 'Другое'},
    {value: RateReasonType.crimea, label: 'Предприниматель Крыма или Севастополя'},
    {value: RateReasonType.taxHolidays, label: 'Налоговые каникулы'},
]


export const TaxRate = () => {
    const {watch, setValue} = useFormContext<FormType>();

    const taxSystem = watch('tax_system');
    const taxRate = watch('tax_rate');
    const isTaskSys = computeIsType(taxSystem, TaxSystemType);

    const showReason = checkReductionAttemptByRate(taxSystem, taxRate);

    useEffect(() => {
        if (!showReason) {
            setValue('reason_type', undefined, {shouldValidate: true});
            setValue('rate_reason_0', undefined, {shouldValidate: true});
            setValue('rate_reason_1', undefined, {shouldValidate: true});
            setValue('rate_reason_2', undefined, {shouldValidate: true});
        }
    }, [setValue, showReason]);

    useEffect(() => {
        return watch((_, {name}) => {
            if (name === 'tax_system') {
                setValue('tax_rate', undefined, {shouldValidate: true});
            }
        }).unsubscribe;
    }, [setValue, watch]);

    const percentageCountList = useMemo(
        () => Array(
            isTaskSys.usnD
                ? 6
                : isTaskSys.usnDR
                    ? 15 : 0,
        ).fill(null),
        [isTaskSys.usnD, isTaskSys.usnDR],
    )

    if (!(isTaskSys.usnDR || isTaskSys.usnD)) return null;


    return (
        <div className={clsx(styles.container, 'p-16 vstack gap-16')}>
            <Typography type='NormalTextSemibold'>
                Ставка налогообложения, %
            </Typography>
            <div className={clsx('d-flex overflow-hidden', styles.buttonsRow)}>
                {...percentageCountList.map((_, index) => {
                    const percentage = index + 1;
                    const isCurrent = taxRate === percentage;

                    return (
                        <Button
                            key={index}
                            type={isCurrent ? 'Primary' : 'Secondary'}
                            className={clsx('w-100', styles.percentageBtn, isCurrent || styles.percentageBtnBg)}
                            onClick={() => setValue('tax_rate', percentage, {shouldValidate: true, shouldTouch: true, shouldDirty: true})}
                        >
                            {percentage}
                        </Button>
                    );
                })}
            </div>
            {showReason && (
                <>
                    <div className='d-flex gap-8 align-items-center'>
                        <Typography type='NormalTextSemibold'>
                            Обоснование ставки
                        </Typography>
                        <Tooltip floatingContent={() => ''}>
                            <Typography color='brand-primary' type='Inherit'>
                                <Info16/>
                            </Typography>
                        </Tooltip>
                    </div>
                    <Select name='reason_type' label='Причина снижения ставки' required values={reasonTypeValues}/>
                    <div className='d-flex gap-8'>
                        <Input name='rate_reason_0' label='Статья' required/>
                        <Input name='rate_reason_1' label='Пункт'/>
                        <Input name='rate_reason_2' label='Подпункт'/>
                    </div>
                </>
            )}
        </div>
    )
}