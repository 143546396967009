import {FormProvider, useForm} from "react-hook-form";
import {Button} from "@/components/base/Button";
import {business} from "@/business";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {UploadFile} from "@/components/form/UploadFile";
import {Checkbox} from "@/components/form/Checkbox";
import {Typography} from "@/components/base/Typography";
import {Highlighter} from "@/components/composite/Highlighter";
import {useEffect, useRef} from "react";
import {sendFileToTmp} from "@/utils/common/files";
import fp from "lodash/fp";
import {asyncExecMetaSelector} from "@/business/common/selectors";
import {LoadingStatus} from "@/core/store/utils/sagas";
import {UUID} from "node:crypto";
import {useSubscribeError} from "@/utils/forms/hooks";
import i18next from "i18next";


const titles = {
    [LoadingStatus.Never]: undefined,
    [LoadingStatus.Loading]: 'Загрузка...',
    [LoadingStatus.Loaded]: 'Файл загружен',
    [LoadingStatus.Error]: 'Не удалось загрузить данные',
}

const titlesReports = {
    [LoadingStatus.Never]: () => 'Выберите или перетащите файлы',
    [LoadingStatus.Loading]: () => 'Загрузка...',
    [LoadingStatus.Loaded]: (count?: number) => i18next.t('transactionsPage.sources.wb.auth.reports.fileUploaded.', {count}),
    [LoadingStatus.Error]: () => 'Не удалось загрузить данные',
}


const descriptions = {
    [LoadingStatus.Never]: () => 'Загрузите файл в формате .xls или .pdf',
    [LoadingStatus.Loading]: ({f}: { f: File | null }) => f?.name,
    [LoadingStatus.Loaded]: () => 'Обработка прошла успешно',
    [LoadingStatus.Error]: (
        {f}: { f: File | null },
    ) => `Проверьте корректность загруженного файла ${f?.name} и повторите загрузку`,
}

const descriptionsReports = {
    [LoadingStatus.Never]: () => 'Загрузите 4 файла в формате .xlsx или .pdf',
    [LoadingStatus.Loading]: (count?: number) => i18next.t('transactionsPage.sources.wb.auth.reports.fileCheck.', {count}),
    [LoadingStatus.Loaded]: () => 'Обработка прошла успешно',
    [LoadingStatus.Error]: () => `Не удалось загрузить файл. Попробуйте снова`,
}

interface IIdsSubSetRef {
    fireReport: null | UUID,
    others: UUID[]
}

export const WBAuthReports = () => {
    const dp = useAppDispatch();
    const idsSubSetRef = useRef<IIdsSubSetRef>({fireReport: null, others: []});
    const methods = useForm<{ fireReport: File, otherReports: File[], fireReportDisable?: boolean }>();

    const fireReportMeta = useAppSelector(
        asyncExecMetaSelector('sources/wbFireReportValidation'),
        fp.isEqual,
    );

    const reportsMeta = useAppSelector(
        asyncExecMetaSelector('sources/wbSalesReportValidation'),
        fp.isEqual,
    );

    const completeRegMeta = useAppSelector(
        asyncExecMetaSelector('sources/wbReportAuthComplete'),
        fp.isEqual,
    );

    useSubscribeError(methods, 'fireReport', fireReportMeta.message);
    useSubscribeError(methods, 'otherReports', reportsMeta.message);

    const fireReport = methods.watch('fireReport');
    const fireReportDisable = methods.watch('fireReportDisable');
    const otherReports = methods.watch('otherReports');

    useEffect(() => {
        if (fireReportDisable) {
            methods.setValue('fireReport', [] as any);
        }
    }, [fireReportDisable, methods]);

    useEffect(() => {
        if (fireReport instanceof File) {
            const fileId = sendFileToTmp(fireReport);
            idsSubSetRef.current.fireReport = fp.first(fileId) as UUID;
            dp(business.sources.actions.checkWBFile(fileId as unknown as UUID[], 'sources/wbFireReportValidation'));
        }

        if (fp.getOr(-1, 'length', fireReport) === 0) {
            idsSubSetRef.current.fireReport = null;
            dp(business.common.actions.clearAsyncExecuteMeta('sources/wbFireReportValidation'))
        }
    }, [dp, fireReport]);

    useEffect(() => {
        if (otherReports?.length > 0) {
            const fileIds = sendFileToTmp(otherReports, true) as UUID[];
            idsSubSetRef.current.others = fileIds;

            dp(business.sources.actions.checkWBFile(fileIds, 'sources/wbSalesReportValidation'));
        } else {
            if (otherReports?.length === 0) {
                idsSubSetRef.current.others = [];
                dp(business.common.actions.clearAsyncExecuteMeta('sources/wbSalesReportValidation'))
            }
        }
    }, [dp, otherReports]);


    const otherReportsLength = otherReports?.length || 0

    const title = titles[fireReportMeta.status];
    const titleReports = titlesReports[reportsMeta.status](otherReportsLength);

    const description = fireReportMeta.message || descriptions[fireReportMeta.status]({f: fireReport});
    const descriptionReports = reportsMeta.message || descriptionsReports[reportsMeta.status](otherReportsLength);

    const readySubmit = idsSubSetRef.current.others.length > 0 && (!!idsSubSetRef.current.fireReport || !!fireReportDisable)

    const submit = methods.handleSubmit(() => {
        if (readySubmit)
            dp(business.sources.actions.wbCompleteRegistration([...idsSubSetRef.current.others, idsSubSetRef.current.fireReport] as UUID[], 'sources/wbReportAuthComplete'))
    })

    const disableSubmit = !methods.formState.isValid
        || !readySubmit
        || completeRegMeta.status === LoadingStatus.Loading
        || fireReportMeta.status === LoadingStatus.Loading
        || reportsMeta.status === LoadingStatus.Loading

    useEffect(() => {
        if (
            [LoadingStatus.Loaded, LoadingStatus.Error].includes(reportsMeta.status)
            && otherReportsLength === 0
        ) {
            dp(business.common.actions.clearAsyncExecuteMeta('sources/wbSalesReportValidation'))
        }
    }, [dp, otherReportsLength, reportsMeta]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={submit} className='vstack gap-32'>
                <div className='vstack gap-8'>
                    <UploadFile
                        showFile
                        required
                        disabled={fireReportDisable}
                        name='fireReport'
                        label='Отчет о пожарах за 2024'
                        title={title}
                        description={description}
                        currentState={fireReportMeta.status}
                    />
                    {fireReport instanceof File || <Checkbox label='Отчет отсутствует' name='fireReportDisable'/>}
                </div>
                <div className='vstack gap-8'>
                    <UploadFile
                        showFile
                        required
                        name='otherReports'
                        label='Отчеты о продахаж и выкупе  с 1 по 28 января 2024'
                        title={titleReports}
                        description={descriptionReports}
                        currentState={reportsMeta.status}
                        multiple
                    />
                </div>
                <Typography>
                    <Highlighter
                        text='Найти отчеты можно в ЛК Wildberries в разделе Финансовые отчеты. [Инструкция →](/404)'/>
                </Typography>
                <div className='d-flex flex-column flex-md-row gap-16'>
                    <Button
                        type='Secondary'
                        className='w-100'
                        onClick={() => dp(business.sources.actions.prevStep())}
                    >
                        ← Назад
                    </Button>
                    <Button
                        className='w-100'
                        htmlType='submit'
                        disabled={disableSubmit}
                    >
                        Подключить
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}