import {sagaActions} from "./inits";
import {generatorWrap} from "@/utils/common/functions";
import {mixinWithInitAwait} from "@/business/auth/mixins";
import {LoadingStatus, mixinWithErrorHandler, mixinWithLoadingHandler} from "@/core/store/utils/sagas";
import {slice} from "./slice";
import {all, call, Effect, put, select, takeEvery, takeLatest} from "redux-saga/effects";
import {
	getUserBannersBannersGet,
	type GetUserBannersBannersGetResponse,
	updateUserBannerStateBannersPut, UserInfoBanner,
} from "@/services/openapi";
import {DateTime} from "luxon";
import {logger} from "@/utils/logger";
import {RootState} from "@/core/store";

function* getBannerList(): Generator<Effect> {
	const resp = (yield call(
		getUserBannersBannersGet,
		{query: {current_date: DateTime.now().toSQLDate()}},
	)).data as GetUserBannersBannersGetResponse;

	yield put(slice.actions.setListEntity(resp.banners));
}

function* hideBanner(action: ReturnType<typeof sagaActions.hideBanner>): Generator<Effect> {
	const key = action.payload;

	yield put(slice.actions.setUpdatingMeta({key, status: LoadingStatus.Loading}));

	try {
		yield call(
			updateUserBannerStateBannersPut,
			{query: {banner_id: key}},
		);

		yield put(slice.actions.setUpdatingMeta({key, status: LoadingStatus.Loaded}));
		yield put(sagaActions.getBannerList());
	} catch (e) {
		logger.warn('Request failed!: ', e);
		yield put(slice.actions.setUpdatingMeta({key, status: LoadingStatus.Error}));
	}
}

function* hideAllBanners(): Generator<Effect> {
	const ids:UserInfoBanner[] = yield select((state: RootState) => state.banners.list.entity);

	yield all(ids
		.map(
			(obj) => (
				call(updateUserBannerStateBannersPut, {query: {banner_id: obj.id}})
			),
		),
	);

	yield put(sagaActions.getBannerList());
}


export const sagas = [
	takeLatest(sagaActions.getBannerList.type,
		generatorWrap(
			getBannerList,
			mixinWithErrorHandler(slice.actions.setListMeta),
			mixinWithLoadingHandler(slice.actions.setListMeta),
			mixinWithInitAwait(),
		),
	),
	takeEvery(sagaActions.hideBanner.type,
		hideBanner,
	),
	takeEvery(sagaActions.hideAllBanners.type,
		generatorWrap(
			hideAllBanners,
			mixinWithErrorHandler(slice.actions.setListMeta),
			mixinWithLoadingHandler(slice.actions.setListMeta),
		),
	),
];
