import {UploadFile} from "@/components/form/UploadFile";
import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {Button} from "@/components/base/Button";
import {business} from "@/business";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {LoadingStatus} from "@/core/store/utils/sagas";
import {sendFileToTmp} from "@/utils/common/files";
import {closeModal} from "@/utils/common/modals";
import {computeIsType} from "@/utils/common/functions";
import {Highlighter} from "@/components/composite/Highlighter";
import {Typography} from "@/components/base/Typography";
import fp from "lodash/fp";
import {displayBankNameAccountResp} from "@/utils/data/transactions/format";
import {DateTime} from "luxon";


const actionBtnClose = {
    children: 'Закрыть',
    type: 'Primary' as const,
}

const actionBtnBack = {
    children: '← Назад',
    type: 'Secondary' as const,
}

const titles = {
    [LoadingStatus.Never]: undefined,
    [LoadingStatus.Loading]: 'Загрузка...',
    [LoadingStatus.Loaded]: 'Файл загружен',
    [LoadingStatus.Error]: 'Не удалось загрузить данные',
}

const descriptions = {
    [LoadingStatus.Never]: () => undefined,
    [LoadingStatus.Loading]: ({f}: { f: File | null }) => f?.name,
    [LoadingStatus.Loaded]: () => 'После успешной обработки данные отобразятся в разделе Операции',
    [LoadingStatus.Error]: (
        {f}: { f: File | null },
    ) => `Проверьте корректность загруженного файла ${f?.name} и повторите загрузку`,
}


export const UploadBankStatement = () => {
    const dp = useAppDispatch();
    const [fileObj, setFileObj] = useState<File | null>(null);

    const error = useAppSelector(
        (state) => state.transactions.upload1CBankStatement.meta.message,
    )

    const data = useAppSelector(
        (state) => state.transactions.upload1CBankStatement.data,
        fp.isEqual,
    )

    const status = useAppSelector(
        (state) => state.transactions.upload1CBankStatement.meta.status,
    )

    const isStatus = computeIsType(status, LoadingStatus);

    const methods = useForm();

    useEffect(() => {
        return () => {
            dp(business.transactions.actions.clearUpload1CBankStatementFile());
        }
    }, [dp]);

    useEffect(() => {
        return methods.watch((d, {name}) => {
            if (name === 'file') {
                const file: File = d[name] instanceof FileList ? d[name][0] : d[name];

                if (file) {
                    const id = sendFileToTmp(file);

                    setFileObj(file);

                    dp(business.transactions.actions.upload1CBankStatementFile(id));
                }
            }
        }).unsubscribe
    }, [dp, methods])

    const descriptionStatus = error || descriptions[status]({f: fileObj});

    const closableStatus = isStatus.Loaded || isStatus.Error

    return (
        <div className='d-flex flex-fill flex-column gap-32'>
            <FormProvider {...methods}>
                <form className='d-flex flex-column gap-16'>
                    <UploadFile
                        name='file'
                        currentState={status}
                        title={titles[status]}
                        description={descriptionStatus}
                    />
                    {data && (
                        <Typography type='NormalTextRegular' color='base-additional-grey'>
                            <Highlighter
                                text={`
                                    Номер счета: ${data.account_number}
                                    Наименование банка: ${displayBankNameAccountResp(data)}
                                    Дата начала выписки: ${data.start_date && DateTime.fromSQL(data.start_date).toLocaleString(DateTime.DATE_SHORT)}
                                    Дата окончания выписки: ${data.end_date && DateTime.fromSQL(data.end_date).toLocaleString(DateTime.DATE_SHORT)}
                                `}
                            />
                        </Typography>
                    )}
                </form>
            </FormProvider>
            <Button
                className='w-100' {...(closableStatus ? actionBtnClose : actionBtnBack)}
                onClick={() => {
                    if (closableStatus)
                        return closeModal();
                    return dp(business.sources.actions.prevStep())
                }}
            />
        </div>
    )
}
