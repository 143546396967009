import commonStyles from "@/components/wrappers/Modals/components/common.module.scss";
import styles from "@/components/wrappers/Modals/components/ENSAnalyzeRequest/styles.module.scss";
import {Button} from "@/components/base/Button";
import {X} from "@/components/icons/X";
import clsx from "clsx";
import {useParseQueryProps, useSafeCloseModal} from "@/utils/common/modals";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import {IModalProps} from "@/components/wrappers/Modals/consts";
import {useEffect, useRef} from "react";
import {business} from "@/business";
import fp from "lodash/fp";
import {Typography} from "@/components/base/Typography";

import iternalStyles from './styles.module.scss';
import {Trash} from "@/components/icons/Trash";
import {TagLabel, TagLabelType} from "@/components/base/TagLabel";
import React from "react";
import {DateTime} from "luxon";
import {RequestState} from "@/services/openapi";
import {StatusCondition} from "@/utils/networkMeta/StatusCondition";
import {Spin} from "@/components/icons/Spin";
import {Null} from "@/components/base/Null";
import {LoadingStatus} from "@/core/store/utils/sagas";


const matchLabel = {
    [RequestState.inProgress]: {text: 'В обработке', type: TagLabelType.info},
    [RequestState.processing]: {text: 'В обработке', type: TagLabelType.info},
    [RequestState.completed]: {text: 'Успешно', type: TagLabelType.success},
    [RequestState.failed]: {text: 'Ошибка', type: TagLabelType.error},
}


export const SourceHistory = (
    {currentId}: IModalProps,
) => {
    const contentRef = useRef(null);
    const close = useSafeCloseModal(currentId);
    const query = useParseQueryProps(currentId);

    const dp = useAppDispatch();

    useOnClickOutside(
        close,
        contentRef,
    );

    const id = query?.id;

    useEffect(() => {
        if (fp.isString(id)) {
            dp(business.sources.actions.loadHistory(id))
        }
    }, [dp, id])

    const list = useAppSelector(
        (state) => state.sources.sourceHistory,
    )

    const status = useAppSelector(
        (state) => state.sources.disableSourceMeta.status || LoadingStatus.Never,
    )

    const removeItem = (itemId: string) => () => {
        dp(business.sources.actions.removeHistoryItem({id: itemId, sourceId: id as string}))
    }


    return (
        <div className='position-absolute'>
            <div className={clsx(commonStyles.overlay, 'position-relative')}>
                <div className={clsx(commonStyles.content, 'align-self-start align-self-md-center', styles.content)}
                     ref={contentRef}>
                    <Button
                        type='TextButton'
                        className={commonStyles.closeBtn}
                        onClick={close}
                    >
                        <X/>
                    </Button>
                    <div className='vstack gap-32 align-items-center'>
                        <Typography type='H4Header' className='align-self-start'>
                            История загрузки данных
                        </Typography>
                        <StatusCondition
                            status={status}
                            fallback={Null}
                            onLoading={Spin}
                            onLoaded={() => (
                                <div>
                                    {list.length > 0 ?
                                        <>
                                            <div className={clsx('d-grid', iternalStyles.contentTableHeaderRow)}>
                                                <Typography className='px-8 py-4' type='LabelSemibold'
                                                            color='base-secondary-1'>
                                                    Дата загрузки
                                                </Typography>
                                                <Typography className='px-8 py-4' type='LabelSemibold'
                                                            color='base-secondary-1'>
                                                    Название файла
                                                </Typography>
                                                <Typography className='px-8 py-4' type='LabelSemibold'
                                                            color='base-secondary-1'>
                                                    Статус загрузки
                                                </Typography>
                                            </div>
                                            <div className={clsx('d-grid', iternalStyles.contentTable)}>
                                                {list.map((item) => {
                                                    const tagP = matchLabel[item.state];

                                                    return (
                                                        <React.Fragment key={item.id}>
                                                            <Typography className='px-8 py-12'>
                                                                {DateTime.fromISO(item.date, {zone: 'utc'}).toLocal().toLocaleString(DateTime.DATE_SHORT)}
                                                            </Typography>
                                                            <Typography className='px-8 py-12'>
                                                                {item.filename}
                                                            </Typography>
                                                            <div
                                                                className='d-flex align-items-center gap-16 px-8 py-12'>
                                                                <TagLabel type={tagP.type}>
                                                                    {tagP.text}
                                                                </TagLabel>
                                                                {[RequestState.inProgress, RequestState.processing].includes(item.state) ||
                                                                    <Button type='Danger' iconOnly className='p-4'
                                                                            onClick={removeItem(item.id)}>
                                                                        <Trash/>
                                                                    </Button>}
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        </>
                                        : <Typography type='NormalTextSemibold' color='base-secondary-1'
                                                      className='text-center w-100'>
                                            Нет данных
                                        </Typography>}
                                </div>
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}