import {call, Effect, put, takeLatest} from "redux-saga/effects";
import {sagaActions} from "./inits";
import {generatorWrap} from "@/utils/common/functions";
import {getBankInfoReferencesBankInfoGet} from "@/services/openapi";
import {mixinWithErrorHandler, mixinWithLoadingHandler} from "@/core/store/utils/sagas";
import {slice} from "./slice";


function* getBankInfoByBik(action: ReturnType<typeof sagaActions.getBankInfoByBik>): Generator<Effect> {
	yield put(slice.actions.setBankInfoByBik(null));

	yield put(slice.actions.setBankInfoByBik(
		(yield call(getBankInfoReferencesBankInfoGet, {query: {bik: action.payload}})).data,
	))
}

export const sagas = [
	takeLatest(sagaActions.getBankInfoByBik.type,
		generatorWrap(
			getBankInfoByBik,
			mixinWithErrorHandler(slice.actions.setGetBankInfoByBikMeta),
			mixinWithLoadingHandler(slice.actions.setGetBankInfoByBikMeta),
		),
	),
];
