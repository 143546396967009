import {FormProvider, useForm} from "react-hook-form";
import {logger} from "@/utils/logger";
import {UploadFile} from "@/components/form/UploadFile";
import {business} from "@/business";
import {Button} from "@/components/base/Button";
import {closeModal} from "@/utils/common/modals";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {useEffect, useState} from "react";
import {sendFileToTmp} from "@/utils/common/files";
import {LoadingStatus} from "@/core/store/utils/sagas";
import {computeIsType} from "@/utils/common/functions";

const actionBtnClose = {
    children: 'Закрыть',
    type: 'Primary' as const,
}

const actionBtnBack = {
    children: '← Назад',
    type: 'Secondary' as const,
}

const titles = {
    [LoadingStatus.Never]: undefined,
    [LoadingStatus.Loading]: 'Загрузка...',
    [LoadingStatus.Loaded]: 'Документ загружен',
    [LoadingStatus.Error]: 'Не удалось загрузить данные',
}

const descriptions = {
    [LoadingStatus.Never]: () => 'Загрузите файл в формате .xlsx',
    [LoadingStatus.Loading]: (s?: string) => s,
    [LoadingStatus.Loaded]: () => 'После успешной обработки данные отобразятся в разделе Операции',
    [LoadingStatus.Error]: (s?: string) => `Проверьте корректность загруженного файла ${s} и повторите загрузку`,
}

export const UploadXLSXStatementOFD = () => {
    const dp = useAppDispatch();
    const methods = useForm();

    const status = useAppSelector(
        (state) => state.sources.createAccountLinkMeta.status || LoadingStatus.Never,
    )

    const errorMessage = useAppSelector(
        (state) => state.sources.createAccountLinkMeta.message,
    )

    const [loaderText, setLoaderText] = useState('');

    const submit = (d: any) => {
        logger.info(d)
    }

    useEffect(() => {
        return () => {
            dp(business.sources.actions.setCreateAccountLinkMeta({status: LoadingStatus.Never}))
        }
    }, [dp]);

    useEffect(() => {
        return methods.watch((d, {name}) => {
            if (name === 'file') {
                const file: File = d[name] instanceof FileList ? d[name][0] : d[name];
                if (file) {
                    const id = sendFileToTmp(file);

                    setLoaderText(file.name);

                    dp(business.sources.actions.createXLSXOFDIntegration(id))
                }
            }
        }).unsubscribe
    }, [dp, methods])

    const isStatus = computeIsType(status, LoadingStatus);


    const closableStatus = isStatus.Error || isStatus.Loaded;

    const title = titles[status];
    const desc = errorMessage || descriptions[status](loaderText);

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submit)} className='d-flex flex-column gap-24 gap-md-32'>
                <UploadFile name='file' title={title} description={desc} currentState={status}/>
                <Button
                    className='w-100' {...(closableStatus ? actionBtnClose : actionBtnBack)}
                    onClick={() => {
                        if (closableStatus)
                            return closeModal();
                        return dp(business.sources.actions.prevStep())
                    }}
                />
            </form>
        </FormProvider>
    )
}