import commonStyles from "@/components/wrappers/Modals/components/common.module.scss";
import {Button} from "@/components/base/Button";
import {X} from "@/components/icons/X";
import clsx from "clsx";
import {useSafeCloseModal} from "@/utils/common/modals";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import {useEffect, useMemo, useRef} from "react";
import {IModalProps} from "@/components/wrappers/Modals/consts";
import {FormProvider, useForm} from "react-hook-form";
import {Typography} from "@/components/base/Typography";
import {Input} from "@/components/form/Input";
import {Select} from "@/components/form/Select";
import {DatePicker} from "@/components/form/DatePicker";
import {Tooltip} from "@/components/base/Tooltip";
import {Info20} from "@/components/icons/Info";
import {OperationType} from "@/services/openapi";
import {yupResolver} from "@hookform/resolvers/yup";
import {DateTime} from "luxon";
import {CreateTransactionExtendedType, createTransactionExtendedValidate} from "@/business/transactions/inits";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {business} from "@/business";
import {LoadingStatus} from "@/core/store/utils/sagas";


const operationTypeValues = [
    {value: OperationType._1, label: 'Доход'},
    {value: OperationType._3, label: 'Возврат покупателю'},
    {value: OperationType._2, label: 'Уплата налога'},
]

export const CreateTransactionRecordExtend = ({currentId}: IModalProps) => {
    const contentRef = useRef(null);
    const close = useSafeCloseModal(currentId);

    useOnClickOutside(
        close,
        contentRef,
    )
    
    const isLoading = useAppSelector(
        (state) => state.transactions.manualCreateTransactionMeta.status === LoadingStatus.Loading,
    )

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(createTransactionExtendedValidate),
    });

    const operationType = methods.watch('operationType');

    const taxSelected = useMemo(() => operationType === OperationType._2, [operationType])

    useEffect(() => {
        if (methods.formState.touchedFields.operationType) {
            if (taxSelected) {
                methods.setValue('counterpartyName', 'Казначейство России (ФНС России)', {shouldValidate: true});
                methods.setValue('purpose', 'Единый налоговый платеж', {shouldValidate: true});
            } else {
                methods.setValue('counterpartyName', '', {shouldValidate: true});
                methods.setValue('purpose', '', {shouldValidate: true});
            }
        }
    }, [methods, taxSelected]);

    const dp = useAppDispatch();

    const submit = (d: CreateTransactionExtendedType) => {
        dp(business.transactions.actions.manualCreateTransaction({
            ...d,
            date: DateTime.fromJSDate(d.date).toUnixInteger(),
        }))
    }

    return (
        <div className='position-absolute'>
            <div className={clsx(commonStyles.overlay, ('position-relative p-md-0 justify-content-md-end'))}>
                <div className={clsx(commonStyles.content, commonStyles.contentLeftAligned)}
                     ref={contentRef}>
                    <Button
                        type='TextButton'
                        className={clsx(commonStyles.closeBtn, 'd-md-none')}
                        onClick={close}
                    >
                        <X/>
                    </Button>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(submit)} className='d-flex flex-column gap-16 gap-md-24'>
                            <div className='d-flex flex-column gap-16 gap-md-8'>
                                <Typography
                                    type='LargeTextSemibold'
                                    typesWithMedia={['H4Headermd']}
                                >
                                    Добавление операции
                                </Typography>
                                <Typography
                                    color='base-additional-grey'
                                    type='SmallTextRegular'
                                    typesWithMedia={['NormalTextRegularmd']}
                                >
                                    Заполните информацию о совершенной операции. Данные будут отражены в Книге учёта
                                    доходов
                                </Typography>
                            </div>
                            <div className='d-flex flex-column gap-24'>
                                <Select name='operationType' label='Тип операции' required
                                        values={operationTypeValues}/>
                                <Input name='counterpartyName' disabled={taxSelected} label={(
                                    <Typography type='Inherit' className='d-flex gap-4 align-items-center'>
                                        <span>
                                            Контрагент
                                        </span>
                                        {taxSelected || (<>
                                            <Typography color='red-primary' as='span'>*</Typography>
                                            <Tooltip
                                                placement='top-start'
                                                floatingContent={() => 'Укажите "Физлица", если контрагент - несколько физических лиц'}
                                            >
                                                <Info20/>
                                            </Tooltip>
                                        </>)}
                                    </Typography>
                                )}/>
                                <Input name='purpose' required={!taxSelected} disabled={taxSelected}
                                       label='Назначение'/>
                                <div className='d-flex flex-column gap-24 flex-md-row'>
                                    <Input name='amount' required label='Сумма операции' className='w-100'/>
                                    <DatePicker
                                        name='date'
                                        required
                                        label='Дата операции'
                                        className='w-100'
                                        maxDate={DateTime.now()}
                                    />
                                </div>
                                <div>
                                    <Input name='docNumber' label='Номер платежного документа'/>
                                    <Typography type='SmallLabel' color='base-secondary-1'>
                                        Заполните, если доход получен в банке, по которому не подгружена выписка
                                    </Typography>
                                </div>
                            </div>
                            <Button className='mt-auto' htmlType='submit' disabled={!methods.formState.isValid || isLoading}>
                                Добавить данные
                            </Button>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    )
}