import {ModalTypes} from "@/components/wrappers/Modals/consts";
import Router, {useRouter} from "next/router";
import qs from "qs";
import fp from 'lodash/fp'
import {useCallback} from "react";

type ModalsType = typeof ModalTypes

export const useToModalRedirect = () => {
	const {push, query} = useRouter();

	return <K extends keyof ModalsType>(
		name: K,
		params?: Partial<Record<ModalsType[K]['eraseOnExit'][number], (string | number | string[] | number[])>>,
	) => () => {
		const prev = query.modal;
		const mergedModals = fp.isString(prev)
			? [prev, name]
			: fp.isArray(prev)
				? [...prev, name]
				: [name];

		return push(
			qs.stringify({...query, [`${name}`]: params || null, modal: mergedModals}, {
				addQueryPrefix: true,
				indices: false,
				skipNulls: true,
			}), undefined, {shallow: true},
		);
	}
}

export const openModalPure = (name: keyof ModalsType, subQuery?: any) => {
	return Router.push(
		qs.stringify({
			[`${name}`]: subQuery,
			...Router.query,
			modal: fp.compact([...fp.castArray(Router.query.modal), name]),
		}, { addQueryPrefix: true, indices: false, skipNulls: true }),
		undefined,
		{shallow: true},
	)
}

const removeLast = (query: Record<'modal' | string, any>) => {
	const qm = fp.castArray(query.modal);
	const newArr = fp.take(
		qm.length - 1, fp.filter(fp.isString, qm),
	);

	const queryPropsId = `${fp.last(qm)}`;

	switch (newArr.length) {
		case 0:
			return {...query, modal: null, [queryPropsId]: null};
		case 1:
			return {...query, modal: newArr[0], [queryPropsId]: null};
		default:
			return {...query, modal: newArr, [queryPropsId]: null};
	}
}

const computeUrlAfterClose = () =>
	`${window.location.pathname}${qs.stringify(removeLast(qs.parse(window.location.search, {ignoreQueryPrefix: true})), {
		addQueryPrefix: true,
		indices: false,
		skipNulls: true,
	})}`

const useModalClose = () => {
	const {push} = useRouter();

	const newUrl = computeUrlAfterClose()

	return useCallback(() => {
		return push(
			newUrl,
			undefined,
			{shallow: true},
		);
	}, [newUrl, push])
}

export const useSafeCloseModal = (currentId: number) => {
	const {query} = useRouter();
	const close = useModalClose();

	return useCallback(() => {
		const modals = fp.castArray(query.modal)

		if (modals.length === currentId + 1) return close();

		return fp.noop();
	}, [close, currentId, query.modal])
}

export const useIsCurrentLastOpened = (currentId: number) => {
	const {query} = useRouter();

	return fp.castArray(query.modal).length - 1 === currentId;
}


// Func to call an outside React tree (redux, saga and other utils func)
export const closeModal = () => {
	return Router.push(computeUrlAfterClose(), undefined, {shallow: true});
}

export const parseQueryProps = (currentId: number) => {
	const query = qs.parse(window?.location?.search, {ignoreQueryPrefix: true})

	const currentProps = query[fp.castArray(query.modal)[currentId] as string] as Record<string, string | number>

	return currentProps || null
}

export const useParseQueryProps = parseQueryProps;
