import React from "react";

import {useFormContext} from "react-hook-form";
import {Icon} from "@/components/form/Radio/components/Icon";

import styles from './style.module.scss';
import clsx from "clsx";


interface IRadioProps<V> {
    name: string;
    value: V;
    disabled?: boolean;
    label?: React.ReactNode
}


export const Radio = <V,>(
    {
        name,
        value,
        disabled = false,
        label,
    }: IRadioProps<V>,
) => {
    const {watch, setValue} = useFormContext();

    const observeValue = watch(name);

    const active = observeValue === value;

    return (
        <div
            role='radio'
            aria-checked={active}
            tabIndex={-1}
            className={clsx('d-flex align-items-start gap-8', styles.container)}
            onClick={() => disabled || setValue(name, value)}
        >
            <Icon state={active} disabled={disabled} />
            <div>
                {label}
            </div>
        </div>
    )
}
