import styles from './common.module.scss';
import clsx from "clsx";
import {BellRinging} from "@/components/icons/BellRinging";
import {Typography} from "@/components/base/Typography";
import {Highlighter} from "@/components/composite/Highlighter";
import {Button} from "@/components/base/Button";
import {X} from '@/components/icons/X';
import {UserInfoBanner} from "@/services/openapi";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {business} from "@/business";
import {LoadingStatus} from "@/core/store/utils/sagas";
import {Info20} from '@/components/icons/Info';

export enum FEBannerType {
	info = '__infoFE',
	default = '__defaultFE',
}

type IBannerItemProps = Omit<UserInfoBanner, 'banner_type' | 'id'> & {
	id?: string;
	type?: FEBannerType;
}

const icons = {
	[FEBannerType.info]: Info20,
	[FEBannerType.default]: BellRinging,
}

export const BannerItem = (
	{
		id,
		title,
		description,
		type = FEBannerType.default,
	}: IBannerItemProps,
) => {
	const status = useAppSelector(
		(s) => id
			? s.banners.list.updatingMeta[id]?.status === LoadingStatus.Loading
			: false,
	);
	const dp = useAppDispatch();

	const Icon = icons[type];

	return (
		<Typography
			color='brand-additional-dark'
			className={clsx("position-relative d-flex flex-column", styles.bannerContainer)}
		>
			{id && (
				<Button
					type='TextButton'
					className={clsx('position-absolute', styles.closeIcon)}
					onClick={() => dp(business.banners.actions.hideBanner(id))}
					loading={status}
				>
					<X/>
				</Button>
			)}
			<div>
				{Icon ? <Icon/> : <BellRinging/>}
			</div>
			<Typography type='NormalTextSemibold' color='inherit'>
				{title}
			</Typography>
			<Typography type='SmallTextRegular'>
				<Highlighter text={description}/>
			</Typography>
		</Typography>
	)
}