import {IModalProps} from "@/components/wrappers/Modals/consts";
import {useSafeCloseModal} from "@/utils/common/modals";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {useOnClickOutside} from "@/utils/common/hooks/useOnClickOutside";
import iternalStyles from "./styles.module.scss";
import commonStyles from "@/components/wrappers/Modals/components/common.module.scss";
import styles from "@/components/wrappers/Modals/components/ENSAnalyzeRequest/styles.module.scss";
import {Button} from "@/components/base/Button";
import {X} from "@/components/icons/X";
import {useEffect, useRef} from "react";
import {FormProvider, useForm} from "react-hook-form";
import clsx from "clsx";
import {yup} from "@/utils/yup/common";
import i18next from "i18next";
import {yupResolver} from "@hookform/resolvers/yup";
import {Input} from "@/components/form/Input";
import fp from "lodash/fp";
import {Typography} from "@/components/base/Typography";
import {CircleCheck} from "@/components/icons/CircleCkeck";
import {business} from "@/business";
import {asyncExecMetaSelector} from "@/business/common/selectors";
import {LoadingStatus} from "@/core/store/utils/sagas";

enum ErrorType {
    nothing = 'nothing',
    ltLength = 'ltLength',
    noNumber = 'noNumber',
    noSymbol = 'noSymbol',
    matchOld = 'matchOld',
}

const validationTypeText = {
    [ErrorType.nothing]: null,
    [ErrorType.ltLength]: '8 символов',
    [ErrorType.noNumber]: 'Цифры',
    [ErrorType.noSymbol]: 'Буквы',
    [ErrorType.matchOld]: null,
}


const schemaValidate = yup.object({
    oldPass: yup.string()
        .required(() => i18next.t('common.errors.yup.common.required')),
    newPass: yup.string()
        .required(() => ({message: i18next.t('common.errors.yup.common.required'), name: ErrorType.nothing}))
        .min(8, () => ({name: ErrorType.ltLength, message: 'Должно быть больше 8 символов'}))
        .matches(/\d/gmi, () => ({name: ErrorType.noNumber, message: 'Пароль должен содержать цифры'}))
        .matches(/[a-z]/gmi, () => ({name: ErrorType.noSymbol, message: 'Пароль должен содержать буквы'})),
    confirmPass: yup.string()
        .required(() => i18next.t('common.errors.yup.common.required'))
        .oneOf([yup.ref('newPass')], 'Пароли должны совпадать'),
    username: yup.mixed(),
})

const EmptyCircleIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" className='d-block'>
        <path
            d="M7 1.3125C5.87512 1.3125 4.7755 1.64607 3.8402 2.27102C2.90489 2.89597 2.17591 3.78423 1.74544 4.82349C1.31496 5.86274 1.20233 7.00631 1.42179 8.10958C1.64124 9.21284 2.18292 10.2263 2.97833 11.0217C3.77374 11.8171 4.78716 12.3588 5.89043 12.5782C6.99369 12.7977 8.13726 12.685 9.17651 12.2546C10.2158 11.8241 11.104 11.0951 11.729 10.1598C12.3539 9.2245 12.6875 8.12488 12.6875 7C12.6859 5.49207 12.0862 4.04636 11.0199 2.98009C9.95365 1.91382 8.50793 1.31409 7 1.3125ZM7 11.8125C6.04818 11.8125 5.11773 11.5303 4.32632 11.0014C3.53491 10.4726 2.91808 9.72103 2.55383 8.84166C2.18959 7.9623 2.09428 6.99466 2.27997 6.06113C2.46566 5.12759 2.92401 4.27009 3.59705 3.59705C4.27009 2.92401 5.1276 2.46566 6.06113 2.27997C6.99466 2.09428 7.9623 2.18958 8.84167 2.55383C9.72104 2.91808 10.4726 3.53491 11.0014 4.32632C11.5303 5.11773 11.8125 6.04818 11.8125 7C11.8111 8.27591 11.3036 9.49915 10.4014 10.4014C9.49915 11.3036 8.27591 11.8111 7 11.8125Z"
            fill='currentColor'/>
    </svg>
)


export const ChangePass = (
    {currentId}: IModalProps,
) => {
    const contentRef = useRef(null);
    const close = useSafeCloseModal(currentId);

    const meta = useAppSelector(
        asyncExecMetaSelector('users/changePass'),
        fp.isEqual,
    )

    const userEmail = useAppSelector(
        (state) => state.auth.user?.email,
    )
    
    const dp = useAppDispatch();

    useOnClickOutside(
        close,
        contentRef,
    )

    const methods = useForm({
        resolver: yupResolver(schemaValidate),
        mode: 'onChange',
        criteriaMode: 'all',
        reValidateMode: 'onChange',
    });

    useEffect(() => {
        if (userEmail) {
            methods.setValue('username', userEmail)
        }
    }, [methods, userEmail]);

    const {newPass: errorsNewPass} = methods.formState.errors;
    const {newPass: dirtyNewPassField} = methods.formState.dirtyFields;

    const errorInPass = fp.map(fp.get('name'), fp.flatten(fp.values(errorsNewPass?.types))) as string[];

    const attemptSkipReqCheck = fp.first(errorInPass) === ErrorType.nothing;

    const submit = methods.handleSubmit((data) => {
        dp(business.users.actions.changePass(fp.pick(['oldPass', 'newPass'], data)));
    });


    const ValidationType = ({name}: { name: ErrorType }) => {
        const passed = dirtyNewPassField && !errorInPass.includes(name);

        return (
            <Typography
                color={passed ? 'brand-primary': 'base-additional-grey'}
                className='d-flex gap-8 align-items-center'
            >
                <Typography
                    type='Inherit'
                    color={passed ? 'brand-primary': 'base-secondary-2'}
                    className={clsx('m-2', iternalStyles.validationTypeIcon)}
                >
                    {passed ? <CircleCheck/> : <EmptyCircleIcon/>}
                </Typography>
                {validationTypeText[name]}
            </Typography>
        );
    }


    return (
        <div className='position-absolute'>
            <div className={clsx(commonStyles.overlay, 'position-relative')}>
                <div
                    className={clsx(commonStyles.content, 'align-self-start align-self-md-center', styles.content)}
                    ref={contentRef}
                >
                    <Button
                        type='TextButton'
                        className={commonStyles.closeBtn}
                        onClick={close}
                    >
                        <X/>
                    </Button>
                    <FormProvider {...methods}>
                        <form onSubmit={submit} className='d-flex flex-column gap-32 w-100'>
                            <div className='vstack gap-8'>
                                <Typography type='LargeTextSemibold' typesWithMedia={['H4Headermd']}>
                                    Сменить пароль
                                </Typography>
                                <Typography>
                                    Новый пароль не должен совпадать с текущим
                                </Typography>
                            </div>
                            <div>
                                <Input name='username' hidden autoComplete='username email' />
                                <Input name='oldPass' label='Текущий пароль' required type='password' autoComplete='current-password'/>
                            </div>
                            <div style={{height: '1px', backgroundColor: 'var(--brand-secondary-pale)'}}/>
                            <div className='vstack gap-24'>
                                {/* kfc - key for caption */}
                                <Input
                                    customErrorPath={attemptSkipReqCheck ? 'newPass.message' : '__unused.kfc'}
                                    name='newPass'
                                    label='Новый пароль'
                                    required
                                    type='password'
                                    autoComplete='new-password'
                                />
                                <div className='vstack gap-8'>
                                    <ValidationType name={ErrorType.noNumber}/>
                                    <ValidationType name={ErrorType.noSymbol}/>
                                    <ValidationType name={ErrorType.ltLength}/>
                                </div>
                            </div>
                            <Input name='confirmPass' label='Повторите новый пароль' required type='password' autoComplete='new-password'/>
                            {meta.status === LoadingStatus.Error && <Typography color='red-primary'>
                                {meta.message}
                            </Typography>}
                            <div className='d-flex flex-column flex-md-row gap-8 gap-md-16'>
                                <Button
                                    type='Secondary'
                                    className='w-100'
                                    onClick={close}
                                >
                                    Отменить
                                </Button>
                                <Button
                                    className='w-100'
                                    htmlType='submit'
                                    disabled={!methods.formState.isValid}
                                >
                                    Сохранить
                                </Button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    )
}
