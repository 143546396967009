import { Null } from "@/components/base/Null";
import {Typography} from "@/components/base/Typography";
import * as icons from "@/components/icons";

import {SelectActionType} from "@/utils/data/sources/cms/types";
import fp from "lodash/fp";

import styles from './styles.module.scss'
import clsx from "clsx";
import {useAppDispatch} from "@/core/store/hooks";

export const CardFullWidth = (
    { name, description, icon, action }: SelectActionType,
) => {
    const Icon = fp.getOr(Null, icon as keyof typeof icons, icons);

    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch(
            action,
        );
    }

    return (
        <div className={clsx('d-flex flex-column flex-md-row align-items-md-center gap-8 gap-md-12 p-12 w-100', styles.fullWidthCard)} role='button' onClick={handleClick}>
            <Typography color='inherit' className={styles.fullWidthCardIcon}>
                <Icon />
            </Typography>
            <div className='d-flex flex-column gap-2'>
                <Typography type='NormalTextRegular' color='base-primary-dark' className={styles.fullWidthCardName}>
                    {name}
                </Typography>
                <Typography color='base-secondary-1'>
                    {description}
                </Typography>
            </div>
        </div>
    )
}