import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {initialState, namespace, State} from './inits'
import {IPayloadForAction} from "@/core/store/utils/sagas";
import {UUID} from "node:crypto";
import {LocalAddSources} from "@/utils/data/sources/cms/local";
import fp from "lodash/fp";
import {Source} from "@/services/openapi";
import {closeModal} from "@/utils/common/modals";

export const slice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		setCreateAccountLinkMeta: (state, action: PayloadAction<IPayloadForAction>) => {
			state.createAccountLinkMeta = action.payload;
		},
		setHideSourceMeta: (state, action: PayloadAction<IPayloadForAction>) => {
			state.disableSourceMeta = action.payload;
		},
		setLoadSourceHistoryMeta: (state, action: PayloadAction<IPayloadForAction>) => {
			state.disableSourceMeta = action.payload;
		},
		setWbCheckFileReportsMeta: (state, action: PayloadAction<IPayloadForAction>) => {
			state.disableSourceMeta = action.payload;
		},
		setWbAuthData: (state, action: PayloadAction<{ token: string, year: number }>) => {
			state.wb.accumulatedAuthData.token = action.payload.token;
			state.wb.accumulatedAuthData.year = action.payload.year;
		},
		clearWbAuthData: (state) => {
			state.wb.accumulatedAuthData.token = null;
			state.wb.accumulatedAuthData.year = null;
		},
		setSourceHistory: (state, action: PayloadAction<State['sourceHistory']>) => {
			state.sourceHistory = action.payload;
		},
		nextStep: (state, action: PayloadAction<{ id: UUID | string, meta: object }>) => {
			state.addSourceCtx.history.push(action.payload.id);

			if (action.payload.meta)
				state.addSourceCtx.ctx = action.payload.meta;
		},
		setOpenedInList: (state, action: PayloadAction<string | null>) => {
			const nw = action.payload;
			state.openedInList = state.openedInList === nw ? null : nw;
		},
		prevStep: (state) => {
			state.addSourceCtx.history = fp.dropRight(1, state.addSourceCtx.history);

			if (state.addSourceCtx.history.length < 1) {
				closeModal().then();
			}
		},
		openRoot: (state, action: PayloadAction<any>) => {
			const preId = action.payload

			if (fp.isString(preId)) {
				state.addSourceCtx.history.push(preId);
			} else {
				state.addSourceCtx.history.push(LocalAddSources.root_id);
			}
		},
		clearAddSourceData: (state) => {
			state.addSourceCtx = initialState.addSourceCtx;
		},
		setSources: (state, action: PayloadAction<Source[]>) => {
			state.sourceList = action.payload;
		},
	},
})