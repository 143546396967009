import {RootState} from "@/core/store";
import {DateTime} from "luxon";
import {reasonRegexp} from "@/utils/data/users/formats";
import {TaxSystemType} from "@/services/openapi";

export const getJwt = (state: RootState) => {
    const jwtString = state.auth.currentToken;

    return jwtString ? JSON.parse(atob(jwtString.split('.')[1])) : null;
}


export const getFormTaxInfo = (state: RootState) => {
    const user = state.auth.user

    const splitFullReason = new RegExp(reasonRegexp).exec(`${user?.rate_reason}`)

    return {
        year: DateTime.now().year,
        tax_system: (user?.tax_system as TaxSystemType),
        tax_rate: user?.tax_rate || undefined,
        reason_type: user?.reason_type || undefined,
        rate_reason_0: splitFullReason?.groups?.section,
        rate_reason_1: splitFullReason?.groups?.point,
        rate_reason_2: splitFullReason?.groups?.subPoint,
    }
}