import {CreateAccountResponse, MarkupModeCode, Operation, OperationCategory, OperationType} from "@/services/openapi";
import fp from "lodash/fp";
import i18next from "i18next";


export const displayAmount = ({markup}: Operation) => {
    return i18next.t('common.transactionFormat', {val: markup.amount})
}

export const displayTarget = ({account_number, short_name, source_name}: Operation) => {
    const name = `${short_name || source_name}`;

    const displayNum = account_number ? `*${fp.takeRight(4, account_number).join('')}` : null;

    return fp.compact([name, displayNum]).join(' ')
}

export const displayBankNameAccountResp = (
    entity?: CreateAccountResponse | null,
) => entity?.bank_short_name ? entity?.bank_short_name : entity?.bank_name

export const isEdited = ({markup_mode_code}: Operation) => (
    markup_mode_code === MarkupModeCode._2
    || markup_mode_code === MarkupModeCode._3
)

export const availableOperationTypeToChange = ({category}: Operation) => {
    switch (category) {
        case OperationCategory.debet:
            return [OperationType._1, OperationType._2];
        case OperationCategory.credit:
            return [OperationType._3, OperationType._2, OperationType._4];
    }
}