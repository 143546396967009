import * as yuppy from "yup";

export const yupEnum = <T extends Record<string, string | number>, K extends keyof T>(enumerate: T) =>
    yuppy.mixed<T[K]>().oneOf(Object.values(enumerate) as any);



export const yupPhone = yuppy.string()
    .matches((/^\+?\d{11}$/gm), { message: 'Некорректный номер телефона', excludeEmptyString: true })

export const yup = yuppy;