import {Wrapper} from "@/utils/common/functions";
import {logger} from "@/utils/logger";
import {select, take } from "redux-saga/effects";
import {RootState} from "@/core/store";
import {auth} from "@/business/auth/index";
import {JWTObject} from "@/business/auth/sagas";

export const mixinWithInitAwait =
	(): Wrapper =>
		(next) =>
			function* initAwaitWrapper(args) {
				logger.info(
					`Include mixin %cWithInitAwait%c -> %c${args?.type}`,
					'color: yellow; font-style: italic;',
					'color: #008cff; font-style: normal;',
					'color: green; font-style: italic;',
				)

				const initialized = yield select((state: RootState) => state.auth.user !== null);

				if (!initialized)
					yield take([auth.actions.setUser.type]);

				const currentToken: JWTObject | null = yield select((state: RootState) => {
					const jwtString = state.auth.currentToken;

					return jwtString ? JSON.parse(atob(jwtString.split('.')[1])) : null;
				});

				if (currentToken?.need_to_change_password)
					yield take([auth.actions.setToken.type]);

				yield* next();
			};