import {
    AccountIntegrationType,
    BodyWbIntegrationComplexSourcesMarketplaceWbPost, checkWbFileSourcesMarketplaceOzonFileCheckPost,
    checkWbFileSourcesMarketplaceWbFileCheckPost, checkWbIntegrationSourcesMarketplaceWbIntegrationCheckPost,
    CreateAccountIntegration,
    createClientAccountSourcesAccountPost,
    createClientOfdSourcesOfdPost,
    CreateClientOfdSourcesOfdPostData,
    createUserAccountIntegrationSourcesAccountIntegrationPost,
    deleteWbFilesSourcesMarketplaceWbFilesDelete,
    disableSourceSourcesDisablePut,
    getSourcesInfoSourcesGet,
    OfdType,
    Source, SourceFilesDetailed, sourceFilesDetailedSourcesFilesGet,
    wbIntegrationComplexSourcesMarketplaceWbPost,
    yandexIntegrationComplexSourcesMarketplaceYandexConfirmPost,
    yandexIntegrationComplexSourcesMarketplaceYandexPost,
} from "@/services/openapi";
import {mixinWithErrorHandler, mixinWithLoadingHandler} from "@/core/store/utils/sagas";
import {all, call, delay, Effect, put, select, takeEvery, takeLatest, throttle} from "redux-saga/effects";
import {ModalTypesKey} from "@/components/wrappers/Modals/consts";
import {closeModal, openModalPure} from "@/utils/common/modals";
import {mixinWithInitAwait} from "@/business/auth/mixins";
import {NotificationType} from "@/business/common/inits";
import {generatorWrap} from "@/utils/common/functions";
import {getFileFromTmp} from "@/utils/common/files";
import {common} from "@/business/common";
import {RootState} from "@/core/store";
import {users} from "@/business/users";
import {sagaActions, State} from "./inits";
import {DateTime} from "luxon";
import {slice} from "./slice";
import {mixinWithClearBefore, mixinWithErrorHandlerNext, mixinWithLoadingHandlerNext} from "@/business/common/mixins";
import {wbOnlyUtils} from "@/utils/data/sources/format";
import qs from "qs";
import Router from "next/router";
import {AxiosError} from "axios";


function* createAccountLink(action: ReturnType<typeof sagaActions.createAccountLink>): Generator<Effect> {
    yield call(
        createClientAccountSourcesAccountPost, {body: action.payload},
    )

    yield put(common.actions.sendNotification({
        text: 'Данные сохранены',
        type: NotificationType.Success,
        expire: DateTime.now().plus({second: 10}).toUnixInteger(),
    }))

    yield put(users.actions.getDebtBankAccounts())
    yield call(closeModal);
}

function* createAccountIntegration(action: ReturnType<typeof sagaActions.createAccountIntegration>): Generator<Effect> {
    const mappedData: CreateAccountIntegration = {
        integration_type: AccountIntegrationType._1,
        account_credentials: {
            login: action.payload.login,
            password: action.payload.password,
        },
        account_details: {
            bank_bik: action.payload.bank_bik,
            account_number: action.payload.account_number,
        },
    };

    yield call(createUserAccountIntegrationSourcesAccountIntegrationPost, {body: mappedData})

    yield put(common.actions.sendNotification({
        text: 'Данные сохранены',
        type: NotificationType.Success,
        expire: DateTime.now().plus({second: 10}).toUnixInteger(),
    }))
    yield call(closeModal);
}

function* openModal(action: ReturnType<typeof sagaActions.openModal>): Generator<Effect> {
    yield call(closeModal);
    yield put(slice.actions.clearAddSourceData());

    yield call(openModalPure, action.payload.name as ModalTypesKey);
}

function* createLoginPassOFDIntegration(action: ReturnType<typeof sagaActions.createLoginPassOFDIntegration>): Generator<Effect> {
    const meta = yield select(
        (s: RootState) => s.sources.addSourceCtx.ctx,
    )

    const body: CreateClientOfdSourcesOfdPostData['body'] = {
        ofd_type: OfdType._2,
        ...meta,
    };

    const query: CreateClientOfdSourcesOfdPostData['query'] = {
        login: action.payload.login,
        password: action.payload.password,
        date_begin: DateTime.fromSeconds(action.payload.date).toSQLDate(),
    }

    // logger.info({body, query})
    yield call(createClientOfdSourcesOfdPost, {body, query});

    yield put(common.actions.sendNotification({
        text: 'Данные сохранены',
        type: NotificationType.Success,
        expire: DateTime.now().plus({second: 10}).toUnixInteger(),
    }))

    yield call(closeModal);
}

function* createXLSXOFDIntegration(action: ReturnType<typeof sagaActions.createXLSXOFDIntegration>): Generator<Effect> {
    const meta = yield select(
        (s: RootState) => s.sources.addSourceCtx.ctx,
    )

    const body: CreateClientOfdSourcesOfdPostData['body'] = {
        ...meta,
        ofd_type: OfdType._1,
        ofd_file: getFileFromTmp(action.payload),
    };

    // logger.info({body, query})
    yield call(createClientOfdSourcesOfdPost, {body});
}

function* loadAllSources(): Generator<Effect> {
    const resp: Source[] = (yield call(getSourcesInfoSourcesGet)).data?.sources || [];

    yield put(slice.actions.setSources(resp));
}

function* hideSource(action: ReturnType<typeof sagaActions.hideSource>): Generator<Effect> {
    yield call(disableSourceSourcesDisablePut, {body: action.payload});

    yield put(sagaActions.loadAllSources());

    yield call(closeModal);
}

function* loadHistory(
    action: ReturnType<typeof sagaActions.loadHistory>,
): Generator<Effect> {
    const data: SourceFilesDetailed = (yield call(sourceFilesDetailedSourcesFilesGet, {query: {source_id: action.payload}})).data;

    yield put(slice.actions.setSourceHistory(
        data.files_requests?.map((d) => ({
            id: d.id,
            filename: `${d.message}`,
            date: d.created_at || '',
            state: d.status,
        })),
    ))
}

function* removeHistoryItem(action: ReturnType<typeof sagaActions.removeHistoryItem>): Generator<Effect> {
    yield call(deleteWbFilesSourcesMarketplaceWbFilesDelete, {body: {files_ids: [action.payload.id]}})

    yield put(sagaActions.loadHistory(action.payload.sourceId));
}

function* checkWBFile(action: ReturnType<typeof sagaActions.checkWBFile>): Generator<Effect> {
    yield all(action.payload.map((id) => {
        return call(checkWbFileSourcesMarketplaceWbFileCheckPost, {
            body: {
                wb_calculation_mode: 1,
                marketplace_file: getFileFromTmp(id) as File,
            },
        })
    }))
}


function* wbCompleteRegistration(action: ReturnType<typeof sagaActions.wbCompleteRegistration>): Generator<Effect> {
    const prevStep: State['wb']['accumulatedAuthData'] = yield select((s: RootState) =>
        s.sources.wb.accumulatedAuthData,
    )

    if (prevStep.token && prevStep.year) {
        const body: BodyWbIntegrationComplexSourcesMarketplaceWbPost = {
            password: prevStep.token,
            date_begin: wbOnlyUtils.toDateBegin(prevStep.year).toSQLDate() as string,
            processing_begin_date: wbOnlyUtils.toProcessingBeginDate(prevStep.year).toSQLDate() as string,
            wb_summary_flag: true,
            marketplace_files: action.payload.map(getFileFromTmp) as File[],
            wb_calculation_mode: 1,
        }

        yield call(wbIntegrationComplexSourcesMarketplaceWbPost, {body});

        yield put(slice.actions.clearWbAuthData());
        yield call(closeModal);
    }
}

function* wbSubmitFirstStep(action: ReturnType<typeof sagaActions.wbSubmitFirstStep>): Generator<Effect> {
    const baseData = {token: action.payload.token, year: action.payload.year};

    if (action.payload.sellExists) {
        yield put(slice.actions.setWbAuthData(baseData));

        yield put(slice.actions.nextStep({id: 'WBAuthReports', meta: {}}))
    } else {
        const body: BodyWbIntegrationComplexSourcesMarketplaceWbPost = {
            password: baseData.token,
            date_begin: wbOnlyUtils.toDateBegin(baseData.year).toSQLDate() as string,
            processing_begin_date: wbOnlyUtils.toProcessingBeginDate(baseData.year).toSQLDate() as string,
            wb_summary_flag: true,
        }

        yield call(wbIntegrationComplexSourcesMarketplaceWbPost, {body})

        yield call(closeModal)
    }
}

function* wbCheckTokenValidity(action: ReturnType<typeof sagaActions.wbCheckTokenValidity>): Generator<Effect> {
    try {
        yield call(checkWbIntegrationSourcesMarketplaceWbIntegrationCheckPost, {body: {password: action.payload}});
    } catch (error) {
        if (error instanceof AxiosError) {
            if (error.response?.data?.detail?.error_key === 'marketplace_integration_unauthorized') {
                throw new Error(error.response?.data?.detail?.message);
            }
        }

        throw error;
    }
}

function* yaMarketOpenAuth(action: ReturnType<typeof sagaActions.yaMarketOpenAuth>): Generator<Effect> {
    const date_begin = DateTime.fromSeconds(action.payload.date).toSQLDate();

    const respData = (yield call(yandexIntegrationComplexSourcesMarketplaceYandexPost, {
        body: {
            date_begin,
            redirect_url: `${window.location.origin}/dashboard/transactions${qs.stringify({
                AddSource: {rootId: 'YaMarketConfirm'},
                modal: 'AddSource',
            }, {addQueryPrefix: true, indices: false})}`,
        },
    })).data;

    window.open(respData.url, '_self');
}

function* yaMarketCheck(): Generator<Effect> {
    yield call(yandexIntegrationComplexSourcesMarketplaceYandexConfirmPost, {
        body: {request_id: Router.query.request_id as string},
    });
}

function* yaMarketRetryModal(): Generator<Effect> {
    yield call(closeModal);
    yield call(openModalPure, 'AddSource', {rootId: 'YaMarketAuth'})
}


function* ozonCheckFiles(action: ReturnType<typeof sagaActions.ozonCheckFiles>): Generator<Effect> {
    yield all(action.payload.map((fId) =>
        call(checkWbFileSourcesMarketplaceOzonFileCheckPost, {body: {marketplace_file: getFileFromTmp(fId) as File}}),
    ))
}

function* ozonSendFiles(action: ReturnType<typeof sagaActions.ozonSendFiles>): Generator<Effect> {
    yield call(closeModal);
    throw new Error(`biba boba ${JSON.stringify(action)}`);
}

function* ozonCheckToken(action: ReturnType<typeof sagaActions.ozonCheckToken>): Generator<Effect> {
    yield delay(150);
    throw new Error(`biba boba ${JSON.stringify(action)}`);
}



export const sagas = [
    takeLatest(sagaActions.createAccountLink.type,
        generatorWrap(
            createAccountLink,
            mixinWithErrorHandler(slice.actions.setCreateAccountLinkMeta),
            mixinWithLoadingHandler(slice.actions.setCreateAccountLinkMeta),
        ),
    ),
    takeLatest(sagaActions.createAccountIntegration.type,
        generatorWrap(
            createAccountIntegration,
            mixinWithErrorHandler(slice.actions.setCreateAccountLinkMeta),
            mixinWithLoadingHandler(slice.actions.setCreateAccountLinkMeta),
        ),
    ),
    takeLatest(sagaActions.createLoginPassOFDIntegration.type,
        generatorWrap(
            createLoginPassOFDIntegration,
            mixinWithErrorHandler(slice.actions.setCreateAccountLinkMeta),
            mixinWithLoadingHandler(slice.actions.setCreateAccountLinkMeta),
        ),
    ),
    takeLatest(sagaActions.createXLSXOFDIntegration.type,
        generatorWrap(
            createXLSXOFDIntegration,
            mixinWithErrorHandler(slice.actions.setCreateAccountLinkMeta),
            mixinWithLoadingHandler(slice.actions.setCreateAccountLinkMeta),
        ),
    ),
    takeLatest(sagaActions.hideSource.type,
        generatorWrap(
            hideSource,
            mixinWithErrorHandler(slice.actions.setHideSourceMeta),
            mixinWithLoadingHandler(slice.actions.setHideSourceMeta),
        ),
    ),
    takeLatest(sagaActions.loadHistory.type,
        generatorWrap(
            loadHistory,
            mixinWithErrorHandler(slice.actions.setLoadSourceHistoryMeta),
            mixinWithLoadingHandler(slice.actions.setLoadSourceHistoryMeta),
            mixinWithInitAwait(),
        ),
    ),
    takeLatest(sagaActions.removeHistoryItem.type,
        generatorWrap(
            removeHistoryItem,
            mixinWithErrorHandler(slice.actions.setLoadSourceHistoryMeta),
            mixinWithLoadingHandler(slice.actions.setLoadSourceHistoryMeta),
        ),
    ),
    takeLatest(sagaActions.loadAllSources.type,
        generatorWrap(
            loadAllSources,
            mixinWithErrorHandlerNext(),
            mixinWithLoadingHandlerNext(),
            mixinWithInitAwait(),
        ),
    ),
    takeEvery(sagaActions.checkWBFile.type,
        generatorWrap(
            checkWBFile,
            mixinWithErrorHandlerNext(),
            mixinWithLoadingHandlerNext(),
        ),
    ),
    takeLatest(sagaActions.wbCompleteRegistration.type,
        generatorWrap(
            wbCompleteRegistration,
            mixinWithErrorHandlerNext(),
            mixinWithLoadingHandlerNext(),
        ),
    ),
    takeLatest(sagaActions.wbSubmitFirstStep.type,
        generatorWrap(
            wbSubmitFirstStep,
            mixinWithErrorHandlerNext(),
            mixinWithLoadingHandlerNext(),
        ),
    ),
    throttle(750, sagaActions.wbCheckTokenValidity.type,
        generatorWrap(
            wbCheckTokenValidity,
            mixinWithErrorHandlerNext(),
            mixinWithLoadingHandlerNext(),
        ),
    ),
    takeLatest(sagaActions.yaMarketOpenAuth.type,
        generatorWrap(
            yaMarketOpenAuth,
            mixinWithErrorHandlerNext(),
            mixinWithLoadingHandlerNext(),
        ),
    ),
    takeLatest(sagaActions.yaMarketCheck.type,
        generatorWrap(
            yaMarketCheck,
            mixinWithErrorHandlerNext(),
            mixinWithLoadingHandlerNext(),
        ),
    ),
    takeLatest(sagaActions.ozonCheckFiles.type,
        generatorWrap(
            ozonCheckFiles,
            mixinWithErrorHandlerNext(),
            mixinWithLoadingHandlerNext(),
        ),
    ),
    takeLatest(sagaActions.ozonSendFiles.type,
        generatorWrap(
            ozonSendFiles,
            mixinWithErrorHandlerNext(),
            mixinWithLoadingHandlerNext(),
        ),
    ),
    takeLatest(sagaActions.ozonCheckToken.type,
        generatorWrap(
            ozonCheckToken,
            mixinWithClearBefore(),
            mixinWithErrorHandlerNext(),
            mixinWithLoadingHandlerNext(),
        ),
    ),
    takeLatest(sagaActions.openModal.type, openModal),
    takeLatest(sagaActions.yaMarketRetryModal.type, yaMarketRetryModal),
];
