import i18next from "i18next";
import * as yup from "yup";


export const defaultMoney = yup.number()
    .transform((casted, v) => (v === '' ? undefined : casted))
    .required(() => i18next.t('common.errors.yup.common.required'))
    .typeError(() => i18next.t('common.errors.yup.common.incorrect'))
    .moreThan(0, () => i18next.t('common.errors.yup.common.incorrect'))
    .test('isMoneyDecimal', () => i18next.t('common.errors.yup.common.incorrect'), (v) => (v?.toString()?.split('.')[1]?.length || 0) < 3)
    .default(undefined as unknown as number)
