import {Typography} from "@/components/base/Typography";
import {PropsWithChildren} from "react";
import {MinusCircleOutlined12} from "@/components/icons/MinusCircleOutlined";
import clsx from "clsx";

import {BlankLabelClose12} from "@/components/icons/BlankLabelClose";
import styles from './styles.module.scss';
import {Reload12} from "@/components/icons/Reload";
import {ExclamationCircleOutlined12} from "@/components/icons/ExclamationCircleOutlined";
import {BlankLabelCompleted12} from "@/components/icons/BlankLabelCompleted";


export enum TagLabelType {
    default,
    error,
    info,
    success,
    warn,
}

const icons = {
    [TagLabelType.default]: MinusCircleOutlined12,
    [TagLabelType.error]: BlankLabelClose12,
    [TagLabelType.info]: Reload12,
    [TagLabelType.success]: BlankLabelCompleted12,
    [TagLabelType.warn]: ExclamationCircleOutlined12,
}

interface ITagLabelProps {
    type?: TagLabelType
}

export const TagLabel = (
    {type = TagLabelType.warn, children}: PropsWithChildren<ITagLabelProps>,
) => {
    const Icon = icons[type];

    return (
        <Typography
            type='LabelRegular'
            className={clsx('d-flex gap-4 align-items-center px-8', styles.label, styles[`label${TagLabelType[type]}`])}
        >
            <Icon/>
            <Typography type='Inherit' color='inherit' className='d-flex align-items-center'>
                {children}
            </Typography>
        </Typography>
    );
}