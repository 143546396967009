import {sagaActions} from "./inits";
import {call, CallEffect, Effect, put, PutEffect, select, take, takeLatest} from "redux-saga/effects";
import {
    client,
    createUserUsersRegistrationPost,
    currentUserUsersGet,
    getInnInfoUsersRegistrationInnInfoGet,
    InnInfo,
    loginAuthPost,
    passwordChangeUsersPasswordChangePut,
    passwordResetUsersPasswordResetPost,
    retryEmailSendUsersRegistrationRetryPost,
    saveLeadInfoUsersRegistrationLeadInfoPut,
    saveTaxInfoUsersRegistrationTaxInfoPut,
    TaxSystemType,
    Token,
    User,
} from "@/services/openapi";
import cookie from "cookiejs";
import {slice} from "@/business/auth/slice";
import {computeIsType, generatorWrap, sleep} from "@/utils/common/functions";
import {LoadingStatus, mixinWithErrorHandler, mixinWithLoadingHandler} from "@/core/store/utils/sagas";
import {logger} from "@/utils/logger";

import Router from "next/router";
import {strictKickUnlikeUser} from "@/utils/routing";
import {AxiosError} from "axios";
import {RootState} from "@/core/store";
import {DateTime} from "luxon";
import {closeModal, openModalPure} from "@/utils/common/modals";
import {UUID} from "node:crypto";
import {maskString} from "@/utils/data/common/format";
import fp from "lodash/fp";
import {getJwt} from "@/business/auth/selectors";
import {IAppConfigProps, isTestEnv} from "@/utils/config";
import {storeSocketGlobal} from "@/utils/common/socket";
import {isAction} from "@reduxjs/toolkit";


function* getToken(action: ReturnType<typeof sagaActions.getToken>): Generator<Effect> {
    yield put(slice.actions.setMeta({status: LoadingStatus.Loading, key: 'user'}));

    try {
        const {data} = yield call(loginAuthPost, {body: action.payload})
        yield put(slice.actions.setToken({token: data.access_token, cookieSet: true}));

        logger.info('success authorize');

        yield put(sagaActions.getUserInfo());
        yield take([slice.actions.setUser.type]);

        const toPath = Router.query.redirectUrl;

        const inn = yield select((s: RootState) => s.auth.user?.inn)

        if (inn) {
            yield call(
                Router.push,
                typeof toPath === 'string'
                    ? toPath
                    : '/dashboard/actions',
            );
        } else {
            yield call(Router.push, '/auth/registration/step3');
        }

        const jwt: JWTObject | null = yield select(getJwt)

        if (jwt?.need_to_change_password && inn) {
            yield call(openModalPure, 'AtFirstChangePass');
        }

        yield put(slice.actions.setMeta({status: LoadingStatus.Loaded, key: 'user'}));
    } catch (error) {
        logger.info(error);

        if (error instanceof AxiosError) {
            yield put(slice.actions.setMeta({
                status: LoadingStatus.Error,
                key: 'user',
                message: error.response?.data?.detail?.message,
            }));
        }
    }
}

function* getUserInfo(): Generator<PutEffect | CallEffect, void> {
    try {
        const userInfo: User = (yield call(currentUserUsersGet)).data;
        yield put(slice.actions.setUser(userInfo));

        yield put(sagaActions.openActionListener());

        if (window.carrotquest) {
            window.carrotquest.onReady(() => {
                if (window.carrotquest) {

                    window.carrotquest.auth(userInfo.id, userInfo.hashed_id);

                    const tags = fp.compact([isTestEnv() ? 'Тестовые пользователи' : null])

                    window.carrotquest.identify({
                        $name: userInfo.full_name,
                        $email: userInfo.email,
                        $phone: userInfo.phone_number,
                        inn: userInfo.inn,
                        ...(tags.length > 0 ? {tags} : {}),
                    })
                }
            })
        }

    } catch (error) {
        if (error instanceof AxiosError) {
            if ([401, 422].some((s) => s === error.status)) {
                client.setConfig({...client.getConfig(), auth: undefined});
                strictKickUnlikeUser();
            }
        }
    }
}

function* tryInitialize(action: ReturnType<typeof sagaActions.tryInitialize>) {
    if (typeof action.payload === "string") {
        const token = cookie.get('accessToken');

        client.setConfig({...client.getConfig(), baseURL: action.payload, throwOnError: true});

        if (typeof token === 'string') {
            yield put(slice.actions.setToken({token}));
            yield put(sagaActions.getUserInfo())

            const jwtParsed: JWTObject = JSON.parse(atob(token.split('.')[1]));

            const modal = Router.query.modal;

            if (
                jwtParsed?.need_to_change_password &&
                !fp.castArray(modal).includes('AtFirstChangePass') &&
                !Router.pathname.startsWith('/auth/registration')
            ) {
                yield call(openModalPure, 'AtFirstChangePass');
            }
        }

        if (typeof token !== 'string') {
            strictKickUnlikeUser();
        }

        return;
    }

    logger.info('maybe it\'s reinit, emptyPayload action:', action);
}

function* sendFirstRegistrationDataPackage(
    action: ReturnType<typeof sagaActions.sendFirstRegistrationDataPackage>,
): Generator<Effect> {
    yield call(createUserUsersRegistrationPost, {body: action.payload})

    yield put(slice.actions.setRegistrationEmail(action.payload.email));

    yield call(Router.push, '/auth/registration/step2');
}

export interface JWTObject {
    sub: string, // email
    iat: number,
    nbf: number,
    jti: UUID,
    exp: number,
    type: 'access' | string,
    fresh: boolean,
    need_to_change_password: boolean,
}

function* checkNeedChangePass(): Generator<Effect> {
    const userInfo: User | null = yield select((s: RootState) => s.auth.user);

    const token: string | null = yield select((s: RootState) => s.auth.currentToken);
    const jwt = JSON.parse(atob(token || 'null')) as JWTObject | null;

    if (userInfo?.inn && jwt?.need_to_change_password) {
        return yield call(openModalPure, ('ChangePass' as any));
    }
}


function* sendSecondRegistrationDataPackage(
    action: ReturnType<typeof sagaActions.sendSecondRegistrationDataPackage>,
): Generator<Effect> {
    yield put(slice.actions.setMeta({
        key: 'registerStep2pass',
        status: LoadingStatus.Loading,
    }));

    const username: string = yield select((state: RootState) => state.auth.accumulatedRegisterData.email)


    try {
        const {data} = yield call(loginAuthPost, {body: {password: action.payload, username}});

        yield put(slice.actions.setToken({token: data.access_token, cookieSet: true}));

        const userInfo: User = (yield call(currentUserUsersGet)).data;

        yield put(slice.actions.setMeta({
            key: 'registerStep2pass',
            status: LoadingStatus.Loaded,
        }));

        yield put(slice.actions.setUser(userInfo))

        yield call(
            Router.push,
            userInfo?.inn
                ? '/dashboard/actions'
                : '/auth/registration/step3',
        );

        yield call(checkNeedChangePass);
    } catch (error) {
        if (error instanceof AxiosError) {
            if (error.status === 401) {
                yield put(slice.actions.setMeta({
                    key: 'registerStep2pass',
                    message: error.response?.data?.detail?.message,
                    status: LoadingStatus.Error,
                }));
            }
        }
    }
}


function* retryRegistrationFirstStep(): Generator<Effect> {
    const email: string = yield select(
        (state: RootState) => state.auth.accumulatedRegisterData.email,
    )

    yield call(retryEmailSendUsersRegistrationRetryPost, {body: {email}})

    const expireDate = DateTime.now().plus({minute: 1});

    while (expireDate > DateTime.now()) {
        yield put(slice.actions.setRegistrationRetryExpire(
            Math.floor(expireDate.diffNow('second').seconds),
        ));

        yield call(sleep, 499);
    }

    yield put(slice.actions.setRegistrationRetryExpire(null));
}

function* searchINN(action: ReturnType<typeof sagaActions.searchINN>): Generator<Effect> {
    const info: InnInfo = (yield call(getInnInfoUsersRegistrationInnInfoGet, {query: {inn: action.payload}})).data

    yield put(slice.actions.setInnInfo(info));
}

function* sendThirdRegistrationDataPackage(action: ReturnType<typeof sagaActions.sendThirdRegistrationDataPackage>) {
    const isTaxSys = computeIsType(action.payload.tax_system, TaxSystemType);

    const r0 = maskString(action.payload.rate_reason_0, '0000');
    const r1 = maskString(action.payload.rate_reason_1, '0000');
    const r2 = maskString(action.payload.rate_reason_2, '0000');

    const body = {
        inn: action.payload.inn,
        tax_system: action.payload.tax_system,
        tax_rate: action.payload.tax_rate as number,
        rate_reason: action.payload.rate_reason_0
            ? `${r0}${r1}${r2}`
            : null,
        reason_type: action.payload.reason_type,
        start_year: action.payload.start_year,
    }

    yield put(slice.actions.setPreSaveLead(body))

    if (isTaxSys.usnDR || isTaxSys.usnD) {
        yield call(openModalPure, 'ConfirmRegConditions');
    } else {
        yield Router.push('/auth/registration/unsupported');
    }
}

function* sendUnsupportedLead(action: ReturnType<typeof sagaActions.sendUnsupportedLead>): Generator<Effect> {
    const regData = yield select(
        (state: RootState) => state.auth.leadPreSave,
    )

    yield call(saveLeadInfoUsersRegistrationLeadInfoPut, {
        body: {
            ...regData,
            phone_number: action.payload,
        },
    });

    yield call(Router.push, '/auth/login');
}

function* completeRegistration(): Generator<Effect> {
    const body = yield select(
        (state: RootState) => state.auth.leadPreSave,
    );

    yield call(saveTaxInfoUsersRegistrationTaxInfoPut, {
        body,
    });

    yield put(sagaActions.getUserInfo());

    yield call(closeModal);

    yield call(Router.push, '/dashboard/actions');

    const jwt: JWTObject | null = yield select(getJwt);

    if (jwt?.need_to_change_password) {
        yield call(openModalPure, 'AtFirstChangePass');
    }
}

function* changePass(action: ReturnType<typeof sagaActions.changePass>): Generator<Effect> {
    const resp: Token = (yield call(
        passwordChangeUsersPasswordChangePut,
        {body: {new_password: action.payload}},
    )).data;

    yield put(slice.actions.setToken({token: resp.access_token, cookieSet: true}));

    yield call(closeModal);
}

function* askRestorePass(action: ReturnType<typeof sagaActions.askRestorePass>): Generator<Effect> {
    yield call(passwordResetUsersPasswordResetPost, {query: {email: action.payload}});

    yield put(slice.actions.setMeta({key: 'askRestorePass', status: LoadingStatus.Loaded}));

    const expireDate = DateTime.now().plus({minute: 1});

    while (expireDate > DateTime.now()) {
        yield put(slice.actions.setRestoreCooldownExpire(
            Math.floor(expireDate.diffNow('second').seconds),
        ));

        yield call(sleep, 499);
    }

    yield put(slice.actions.setRestoreCooldownExpire(null));
}

function createSocketIterator(jwt: string) {
    const env: Partial<IAppConfigProps['storeConfig']> = fp.getOr({}, '__NEXT_DATA__.props.storeConfig', window);

    if (env.NEXT_PUBLIC_BACKEND_WS) {
        const baseUrl = new URL(env.NEXT_PUBLIC_BACKEND_WS);
        const isSecure = ['https:', 'wss:'].includes(baseUrl.protocol);

        const wsUrl = `${isSecure ? 'wss' : 'ws'}://${baseUrl.host}${baseUrl.pathname}/callbacks?jwt_token=${jwt}`

        const socket = new WebSocket(wsUrl);
        const abortController = new AbortController();

        socket.onopen = () => {
            storeSocketGlobal(socket);

            new Promise(async (resolve, reject) => {
                while (!abortController.signal.aborted) {
                    try {
                        socket.send(DateTime.now().toISO());
                    } catch (e) {
                        reject(e);
                    }

                    await sleep(20_000);
                }
                resolve(null);
            }).then()
        }

        return {
            [Symbol.iterator]: () => ({
                next: () => ({
                    done: false,
                    value: () => new Promise<MessageEvent>((resolve, reject) => {
                        socket.onmessage = resolve;
                        socket.onclose = (...all) => {
                            abortController.abort();
                            reject(all);
                        };
                    }),
                }),
            }),
        }
    }

    return null;
}

function* openActionListener(): Generator<Effect> {
    let token = yield select((state) => state.auth.currentToken);

    while (token) {
        const queue = createSocketIterator(token);

        try {
            if (queue) {
                for (const messages of queue) {
                    const event: MessageEvent = yield call(messages);
                    const msg = event.data;

                    try {
                        const parsed = JSON.parse(msg);

                        if (isAction(parsed)) {
                            yield put(parsed);
                        } else {
                            logger.warn('server send non action(invalid action):', parsed);
                        }
                    } catch (e) {
                        if (fp.startsWith('Message text was: ', msg)) {
                            const prevD = DateTime.fromISO(msg.split('Message text was: ')[1])

                            if (prevD.isValid) {
                                logger.info(`server ping: ${-prevD.diffNow().milliseconds}`)
                            } else {
                                const hint = e instanceof SyntaxError;

                                logger.warn(`server send non action(hint: ${hint ? 'probably json parse error' : 'none'}):`, event, e);
                            }
                        } else {
                            logger.warn(`server send non action:`, event, e);
                        }
                    }
                }
            }

        } catch (e) {
            logger.warn(e, 'sleep 10s');
        }

        yield call(sleep, 10_000);

        token = yield select((state) => state.auth.currentToken);
    }

    logger.info('maybe token rotten');
}


function* changeOnRestorePass(action: ReturnType<typeof sagaActions.changeOnRestorePass>): Generator<Effect> {
    client.setConfig({...client.getConfig(), auth: action.payload.token})

    yield call(passwordChangeUsersPasswordChangePut, {body: {new_password: action.payload.pass}})

    yield call(Router.push, '/auth/login');
}


function* logoutModal(): Generator<Effect> {
    yield call(closeModal);
    yield put(slice.actions.logout());
}


export const sagas = [
    takeLatest(sagaActions.getToken.type,
        getToken,
    ),
    takeLatest(sagaActions.sendFirstRegistrationDataPackage.type,
        generatorWrap(
            sendFirstRegistrationDataPackage,
            mixinWithErrorHandler(slice.actions.setMeta, 'registerStep1'),
            mixinWithLoadingHandler(slice.actions.setMeta, 'registerStep1'),
        ),
    ),
    takeLatest(sagaActions.sendSecondRegistrationDataPackage.type,
        sendSecondRegistrationDataPackage,
    ),
    takeLatest(sagaActions.sendThirdRegistrationDataPackage.type,
        sendThirdRegistrationDataPackage,
    ),
    takeLatest(sagaActions.sendUnsupportedLead.type,
        generatorWrap(
            sendUnsupportedLead,
            mixinWithErrorHandler(slice.actions.setMeta, 'unsupportedLead'),
            mixinWithLoadingHandler(slice.actions.setMeta, 'unsupportedLead'),
        ),
    ),
    takeLatest(sagaActions.completeRegistration.type,
        generatorWrap(
            completeRegistration,
            mixinWithErrorHandler(slice.actions.setMeta, 'completeRegistration'),
            mixinWithLoadingHandler(slice.actions.setMeta, 'completeRegistration'),
        ),
    ),
    takeLatest(sagaActions.changePass.type,
        generatorWrap(
            changePass,
            mixinWithErrorHandler(slice.actions.setMeta, 'changePass'),
            mixinWithLoadingHandler(slice.actions.setMeta, 'changePass'),
        ),
    ),
    takeLatest(sagaActions.retryRegistrationFirstStep.type,
        generatorWrap(
            retryRegistrationFirstStep,
            mixinWithErrorHandler(slice.actions.setMeta, 'registerStep2retry'),
            mixinWithLoadingHandler(slice.actions.setMeta, 'registerStep2retry'),
        ),
    ),
    takeLatest(sagaActions.searchINN.type,
        generatorWrap(
            searchINN,
            mixinWithErrorHandler(slice.actions.setMeta, 'registerStep3inn'),
            mixinWithLoadingHandler(slice.actions.setMeta, 'registerStep3inn'),
        ),
    ),
    takeLatest(sagaActions.askRestorePass.type,
        generatorWrap(
            askRestorePass,
            mixinWithErrorHandler(slice.actions.setMeta, 'askRestorePass'),
            mixinWithLoadingHandler(slice.actions.setMeta, 'askRestorePass'),
        ),
    ),
    takeLatest(sagaActions.changeOnRestorePass.type,
        generatorWrap(
            changeOnRestorePass,
            mixinWithErrorHandler(slice.actions.setMeta, 'changeOnRestorePass'),
            mixinWithLoadingHandler(slice.actions.setMeta, 'changeOnRestorePass'),
        ),
    ),
    takeLatest(sagaActions.tryInitialize.type, tryInitialize),
    takeLatest(sagaActions.getUserInfo.type, getUserInfo),
    takeLatest(sagaActions.openActionListener.type, openActionListener),
    takeLatest(sagaActions.logoutModal.type, logoutModal),
];
