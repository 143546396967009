import {createNamespace} from "@/core/store/actions";
import {IPayloadForAction, LoadingStatus} from "@/core/store/utils/sagas";
import { BankReferenceResponse } from "@/services/openapi/types.gen";

export const namespace = 'references';

const actionCreator = createNamespace(namespace);

export interface State {
  getBankInfoByBikMeta: Partial<IPayloadForAction>
  bankInfoByBik: BankReferenceResponse | null;
}

export const initialState: State = {
  getBankInfoByBikMeta: {
    status: LoadingStatus.Never,
  },
  bankInfoByBik: null,
}


export const sagaActions = {
  getBankInfoByBik: actionCreator<string>('getBankInfoByBik'),
}
