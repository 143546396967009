import {createNamespace} from "@/core/store/actions";
import {IPayloadForAction, LoadingStatus} from "@/core/store/utils/sagas";
import {
    CreateAccountResponse,
    GetKudirReportReportsKudirGetData,
    GetOperationsRequest,
    Operation,
    OperationType,
} from "@/services/openapi";
import {uuid} from "@/utils/common/uuid";
import {UUID} from "node:crypto";
import {yup, yupEnum} from "@/utils/yup/common";
import {defaultMoney} from "@/utils/yup/money";
import {bankDocNumber} from "@/utils/yup/banks";
import i18next from "i18next";
import {InferType} from "yup";

export const namespace = 'transactions';

const actionCreator = createNamespace(namespace);

export interface State {
    groupedList: {
        entities: Record<string, Operation[]>
        pageCount: number;
        filters: GetOperationsRequest,
        meta: IPayloadForAction
    }
    removeTransactionMeta: IPayloadForAction
    downloadKudirMeta: IPayloadForAction
    manualCreateTransactionMeta: IPayloadForAction
    upload1CBankStatement: {
        data: CreateAccountResponse | null
        meta: IPayloadForAction
    }
}

export const perPageCount = 30;

export const initialState = (): State => ({
    groupedList: {
        entities: {},
        pageCount: Infinity,
        filters: {
            pagination: {
                request_id: uuid.v4(),
                page_number: 1,
                row_count: perPageCount,
            },
        },
        meta: {status: LoadingStatus.Never},
    },
    removeTransactionMeta: {status: LoadingStatus.Never},
    downloadKudirMeta: {status: LoadingStatus.Never},
    manualCreateTransactionMeta: {status: LoadingStatus.Never},
    upload1CBankStatement: {
        data: null,
        meta: {status: LoadingStatus.Never},
    },
})

export interface IUpdateType {
    id: UUID,
    newType: OperationType
}

export const createTransactionExtendedValidate = yup.object({
    operationType: yupEnum(OperationType)
        .required(() => i18next.t('common.errors.yup.common.required')),
    counterpartyName: yup
        .string()
        .required(() => i18next.t('common.errors.yup.common.required'))
        .min(1, () => i18next.t('common.errors.yup.common.incorrect'))
        .max(255, () => i18next.t('common.errors.yup.common.incorrect')),
    purpose: yup
        .string()
        .required(() => i18next.t('common.errors.yup.common.required'))
        .min(1, () => i18next.t('common.errors.yup.common.incorrect'))
        .max(255, () => i18next.t('common.errors.yup.common.incorrect')),
    amount: defaultMoney.lessThan(10_000_000_000_000, () => i18next.t('common.errors.yup.common.incorrect')),
    date: yup
        .date()
        .required(() => i18next.t('common.errors.yup.common.required')),
    docNumber: bankDocNumber,
})

export type CreateTransactionExtendedType = InferType<typeof createTransactionExtendedValidate>

export const sagaActions = {
    loadTransactions: actionCreator('loadTransactions'),
    removeTransaction: actionCreator<UUID>('removeTransaction'),
    updateOperationType: actionCreator<IUpdateType>('updateOperationType'),
    manualCreateTransaction: actionCreator<Omit<CreateTransactionExtendedType, 'date'> & {
        date: number
    }>('manualCreateTransaction'),
    upload1CBankStatementFile: actionCreator<UUID>('upload1CBankStatementFile'),
    downloadKudir: actionCreator<Pick<GetKudirReportReportsKudirGetData['query'], 'period_year' | 'kudir_format'>>('downloadKudir'),
}
