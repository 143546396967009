import fp from "lodash/fp";


export const mergeWithConcatArray = fp.mergeWith(
    fp.cond([
        [fp.isArray, fp.concat as any],
    ]),
);


export const toSimpleJSObjectRigid = (o: any) => JSON.parse(JSON.stringify(o));