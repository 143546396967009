import {FormProvider, useForm} from "react-hook-form";
import {Input} from "@/components/form/Input";
import {Select} from "@/components/form/Select";
import {Checkbox} from "@/components/form/Checkbox";
import {Typography} from "@/components/base/Typography";
import {Highlighter} from "@/components/composite/Highlighter";
import {Button} from "@/components/base/Button";
import {business} from "@/business";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {DateTime} from "luxon";
import {useEffect} from "react";
import {yup} from "@/utils/yup/common";
import i18next from "i18next";
import {yupResolver} from "@hookform/resolvers/yup";
import {asyncExecMetaSelector} from "@/business/common/selectors";
import fp from "lodash/fp";
import {LoadingStatus} from "@/core/store/utils/sagas";
import {Spin} from "@/components/icons/Spin";
import {useSubscribeError} from "@/utils/forms/hooks";

const years = () => {
    const now = DateTime.now().startOf('year');
    const y2024 = DateTime.now().set({year: 2024}).startOf('year');

    return Array(now.diff(y2024, 'years').years + 1)
        .fill(null)
        .map((_, i) => y2024.year + i);
}

const schemaValidate = yup.object().shape({
    year: yup.number()
        .required(() => i18next.t('common.errors.yup.common.required')),
    token: yup.string()
        .required(() => i18next.t('common.errors.yup.common.required')),
    sellExists: yup.boolean()
        .nullable(),
})

export const WBAuth = () => {
    const dp = useAppDispatch();

    const meta = useAppSelector(asyncExecMetaSelector('sources/wbAuthFirstStepProcessing'), fp.isEqual);
    const validateTokenMeta = useAppSelector(asyncExecMetaSelector('sources/wbCheckTokenValidity'), fp.isEqual);
    const prevData = useAppSelector(
        (s) => s.sources.wb.accumulatedAuthData,
        fp.isEqual,
    );

    const methods = useForm({
        resolver: yupResolver(schemaValidate),
    });

    useEffect(() => {
        if (prevData.token) {
            methods.setValue('token', prevData.token);
        }

        if (prevData.year) {
            methods.setValue('year', prevData.year);
        }

        return () => {
            dp(business.common.actions.clearAsyncExecuteMeta('sources/wbCheckTokenValidity'))
        }
    }, [dp, methods, prevData]);

    const year = methods.watch('year');
    const token = methods.watch('token');


    useSubscribeError(methods, 'token', validateTokenMeta.message);

    useEffect(() => {
        if (token)
            dp(business.sources.actions.wbCheckTokenValidity(token))
    }, [dp, token]);

    useEffect(() => {
        if (year !== 2024) {
            methods.setValue('sellExists', false, {shouldValidate: true});
        }
    }, [methods, year])

    const submit = methods.handleSubmit((d) => {
        dp(business.sources.actions.wbSubmitFirstStep(d, 'sources/wbAuthFirstStepProcessing'))
    })

    const isLoading = meta.status === LoadingStatus.Loading;

    const blockSubmit = !methods.formState.isValid
        || isLoading
        || validateTokenMeta.status !== LoadingStatus.Loaded

    return (
        <FormProvider {...methods}>
            <form onSubmit={submit} className='vstack gap-32'>
                <div className='vstack gap-24'>
                    <Select name='year' required label='С какого года учитывать операции' values={years()}/>
                    <Input name='token' required label='Ключ (токен подключения)'/>
                    {year === 2024 && <div className='vstack gap-8'>
                        <Checkbox label='Я делал продажи на маркетплейсе Wildberries' name='sellExists'/>
                        <Typography>
                            Если вы делали продажи в 2024 году, на следующем шаге потребуется загрузить отчет о пожарах
                            и еженедельные отчеты за январь
                        </Typography>
                    </div>}
                </div>
                <div>
                    <Highlighter
                        text='Ключ для подключения через API необходимо сгенерировать в ЛК Wildberries. [Инструкция →](/404)'
                    />
                </div>
                {meta.status === LoadingStatus.Error && (
                    <Typography type='NormalTextSemibold' color='red-primary'>
                        {meta.message}
                    </Typography>
                )}
                <div className='d-flex flex-column flex-md-row gap-16'>
                    <Button
                        type='Secondary'
                        className='w-100'
                        onClick={() => {
                            dp(business.sources.actions.clearWbAuthData());
                            dp(business.sources.actions.prevStep());
                        }}
                    >
                        ← Назад
                    </Button>
                    <Button
                        className='w-100'
                        htmlType='submit'
                        disabled={blockSubmit}
                    >
                        {isLoading && <Spin />}
                        Подключить
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}
