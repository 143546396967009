import {Provider} from "react-redux";
import NextApp from "next/app";
import type {AppContext, AppInitialProps, AppProps} from "next/app";

import {store} from "@/core/store";

import '../styles/root.scss'
import {cleanedConfig, IAppConfigProps} from "@/utils/config";

import '@/core/i18n';
import {useEffect} from "react";
import {business} from "@/business";
import {Modals} from "@/components/wrappers/Modals";
import {AnimatePresence} from "motion/react";
import {useRouter} from "next/router";
import {logger} from "@/utils/logger";


export default function App({Component, pageProps, storeConfig}: AppProps & IAppConfigProps) {
    const {asPath} = useRouter();

    useEffect(() => {
        logger.info('user agent lang:', window?.navigator?.language || (window?.navigator as any)?.userLanguage);
        logger.info('intl default resolved args', Intl.DateTimeFormat().resolvedOptions());

        store.dispatch(
            business.auth.actions.tryInitialize(storeConfig.NEXT_PUBLIC_BACKEND_URI),
        )
    }, [storeConfig])

    const withoutQueryAsPath = asPath.split('?')[0];

    return (
        <Provider store={store}>
            <AnimatePresence mode='wait'>
                <Component {...pageProps} config={storeConfig} key={withoutQueryAsPath}/>
            </AnimatePresence>
            <Modals/>
        </Provider>
    )
}

App.getInitialProps = async (
    context: AppContext,
): Promise<AppInitialProps & IAppConfigProps> => {
    const ctx = await NextApp.getInitialProps(context)

    return {...ctx, storeConfig: cleanedConfig()}
}
