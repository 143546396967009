import i18next from "i18next";
import {yup} from "@/utils/yup/common";

export const bankBikValidate = yup.string()
    .required(() => i18next.t('common.errors.yup.common.required'))
    .length(9, () => i18next.t('common.errors.yup.common.incorrect'))
    .matches(/^\d+$/g, {message: 'Неверный БИК'})

export const bankAccountNumber = yup.string()
    .required(() => i18next.t('common.errors.yup.common.required'))
    .length(20, () => i18next.t('common.errors.yup.common.incorrect'))
    .matches(/^\d+$/g, {message: () => i18next.t('common.errors.yup.common.incorrect')})
    .matches(/^(407|408)/g, {message: () => i18next.t('common.errors.yup.common.incorrect')})

export const bankDocNumber = yup.string()
    .max(20, 'Неверный номер')
    .matches(/^[\wА-Я0-9\-.\/]*$/gmi, { message: 'Неверный номер' })
    .default('');