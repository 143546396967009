import {TaxSystemType, User} from "@/services/openapi";
import i18next from "i18next";
import fp from "lodash/fp";


export const displayName = (
	user: User | null,
) => {
	if (!user) return null;

	if (user.inn?.length === 12) {
		return i18next.t('common.formats.users.IEDisplayName', {
			lastname: user.lastname,
			fInitials: user.firstname && user.firstname[0],
			pInitials: user.patronymic && user.patronymic[0],
		})
	}

	if (user.inn?.length === 10) {
		return user.full_name;
	}

	return null;
}

export const displayTaxInfo = (
	user: User | null,
) => {
	if (!user) return null;

	switch (user.tax_system) {
		case 'usn_d':
		case 'usn_d_r':
			return i18next.t('common.formats.users.usnTax', {rate: user.tax_rate})
		default:
			return null;
	}
}


export const taxSystemValues = [
	{value: TaxSystemType.usnD, label: 'УСН Доходы'},
	{value: TaxSystemType.usnDR, label: 'УСН Доходы - Расходы'},
	{value: TaxSystemType.patent, label: 'Патент'},
	{value: TaxSystemType.eshn, label: 'ЕСХН'},
	{value: TaxSystemType.osn, label: 'ОСНО'},
]

export const displayTaxSystem = (user: User | null) => {
	const found = fp.find({value: user?.tax_system}, taxSystemValues) as { value: any, label: string } | undefined;
	
	return found ? found.label : null;
};

const reasonMatch: Record<string, string | null> = {
	'3462010': null,
	'3462020': null,
    '3462030': 'Предприниматель Крыма или Севастополя',
	'3462040': 'Налоговые каникулы',
}

export const reasonRegexp = /^(?<reason>.{7})\/(?<section>.{4})(?<point>.{4})(?<subPoint>.{4})$/gmi;

export const displayRateSystemJustification = (user: User | null) => {
	const rr = user?.rate_reason;

	if (rr) {
		const splitFullReason = new RegExp(reasonRegexp).exec(rr)

		const reasonType = reasonMatch[splitFullReason?.groups?.reason as string];

		const fullSection = splitFullReason?.groups?.section;
		const section = fullSection ? parseInt(fullSection) : null;

		const fullPoint = splitFullReason?.groups?.point;
		const point = fullPoint ? parseInt(fullPoint) : null

		const fullSubPoint = splitFullReason?.groups?.subPoint;
		const subPoint = fullSubPoint ? parseInt(fullSubPoint) : null

		const acc = fp.compact([
			reasonType ? reasonType : null,
			section ? `статья ${section}`: null,
			point ? `пункт ${point}` : null,
			subPoint ? `подпункт ${subPoint}` : null,
		]);

		const reasonStr = new Intl.ListFormat(i18next.language, {style: 'narrow'}).format(acc);

		return `Обоснование сниженной ставки: ${reasonStr}`
	}

	return null;
};

export const displayIFNSByUser = (e: User | null) =>
	`ИФНС **${e?.fns_code}** - ${e?.fns_description}`