import {configureStore} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import {isBrowser} from "@/utils/common/next";
import {business} from "@/business";
import {all} from 'redux-saga/effects';


const sliceKeys = Object.keys(business) as (keyof typeof business)[];

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

function* allSagas() {
	yield all(
		sliceKeys
			.map((key) => business[key].sagas)
			.reduce(
				(pv, cv) => [...pv, ...cv],
				[],
			),
	)
}

export const store = configureStore({
	reducer: {
		common: business.common.slice.reducer,
		auth: business.auth.slice.reducer,
		actions: business.actions.slice.reducer,
		banners: business.banners.slice.reducer,
		users: business.users.slice.reducer,
		taxes: business.taxes.slice.reducer,
		references: business.references.slice.reducer,
		sources: business.sources.slice.reducer,
		transactions: business.transactions.slice.reducer,
	},
	devTools: {
		name: 'AKBStore',
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(middleware),
})

if (isBrowser()) {
	sagaMiddleware.run(allSagas)
}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
