export const Pen = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className='d-block'>
        <path
            d="M12.431 4.01284 L9.98758 1.56885C9.90633 1.48758 9.80986 1.42311 9.70369 1.37912C9.59751 1.33514 9.48372 1.3125 9.36879 1.3125C9.25387 1.3125 9.14007 1.33514 9.0339 1.37912C8.92773 1.42311 8.83126 1.48758 8.75 1.56885L2.00649 8.31291C1.92488 8.39387 1.86019 8.49024 1.81615 8.59642C1.77212 8.70261 1.74964 8.81648 1.75 8.93143V11.3754C1.75 11.6075 1.84219 11.83 2.00629 11.9941C2.17038 12.1582 2.39294 12.2504 2.625 12.2504H5.06899C5.18394 12.2508 5.29781 12.2283 5.404 12.1843C5.51018 12.1402 5.60655 12.0755 5.6875 11.9939L12.431 5.25041C12.5123 5.16916 12.5768 5.07269 12.6207 4.96652C12.6647 4.86035 12.6874 4.74655 12.6874 4.63163C12.6874 4.5167 12.6647 4.4029 12.6207 4.29673C12.5768 4.19056 12.5123 4.09409 12.431 4.01284ZM5.06899 11.3754H2.625V8.93143L7.4375 4.11893L9.88149 6.56291L5.06899 11.3754ZM10.5 5.94385L8.05602 3.50041L9.36852 2.18791L11.8125 4.63135L10.5 5.94385Z"
            fill='currentColor'/>
    </svg>
)

export const Pen24 = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21.3103 6.87915L17.1216 2.68946C16.9823 2.55014 16.8169 2.43962 16.6349 2.36421C16.4529 2.28881 16.2578 2.25 16.0608 2.25C15.8638 2.25 15.6687 2.28881 15.4867 2.36421C15.3047 2.43962 15.1393 2.55014 15 2.68946L3.43969 14.2507C3.2998 14.3895 3.18889 14.5547 3.11341 14.7367C3.03792 14.9188 2.99938 15.114 3.00001 15.311V19.5007C3.00001 19.8985 3.15804 20.2801 3.43935 20.5614C3.72065 20.8427 4.10218 21.0007 4.50001 21.0007H8.6897C8.88675 21.0013 9.08197 20.9628 9.26399 20.8873C9.44602 20.8118 9.61122 20.7009 9.75001 20.561L21.3103 9.00071C21.4496 8.86142 21.5602 8.69604 21.6356 8.51403C21.711 8.33202 21.7498 8.13694 21.7498 7.93993C21.7498 7.74292 21.711 7.54784 21.6356 7.36582C21.5602 7.18381 21.4496 7.01844 21.3103 6.87915ZM8.6897 19.5007H4.50001V15.311L12.75 7.06102L16.9397 11.2507L8.6897 19.5007ZM18 10.1895L13.8103 6.00071L16.0603 3.75071L20.25 7.93946L18 10.1895Z"
            fill='currentColor'/>
    </svg>
)
