import {useCallback, useEffect} from "react"
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {business} from "@/business";
import {BannerItem} from "./BannerItem";

import styles from './common.module.scss'
import clsx from "clsx";
import {StatusCondition} from "@/utils/networkMeta/StatusCondition";
import fp from "lodash/fp";
import {Skeleton} from "@/components/wrappers/DashboardBase/components/BannerList/Skeleton";

export const BannerList = () => {
	const dp = useAppDispatch();
	const banners = useAppSelector((s) => s.banners.list.entity, fp.isEqual)
	const status = useAppSelector((s) => s.banners.list.meta.status);

	useEffect(() => {
		dp(business.banners.actions.getBannerList())
	}, [dp])

	const List = useCallback(
		() => banners?.map((banner) => (<BannerItem key={banner.id} {...banner} />)),
		[banners],
	)

	return (
		<div className={clsx('d-flex flex-column h-100 overflow-auto scrollbar-stable', styles.listContainer)}>
			<StatusCondition
				status={status}
				onLoaded={List}
				onLoading={Skeleton}
				fallback={() => <></>}
			/>
		</div>
	)
}