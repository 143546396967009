import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {initialState, namespace} from './inits'
import {client, InnInfo, LeadInfoToSave, User} from "@/services/openapi";
import cookie from "cookiejs";
import {IPayloadForAction, LoadingStatus} from "@/core/store/utils/sagas";
import {logger} from "@/utils/logger";
import {strictKickUnlikeUser} from "@/utils/routing";
import fp from "lodash/fp";

// TODO: добавить рефреш и запоминание пользователя
export const slice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		setToken: (state, action: PayloadAction<{ token: string, cookieSet?: boolean }>) => {
			window.ym=undefined;
			state.currentToken = action.payload.token;

			client.setConfig({...client.getConfig(), auth: state.currentToken});

			if (action.payload.cookieSet) cookie.set("accessToken", state.currentToken);
		},
		addToStepProgress: (state, action: PayloadAction<number>) => {
			state.accumulatedRegisterData.stepProgress = fp.min([action.payload, 100]) as number;
		},
		clearStepProgress: (state) => {
			state.accumulatedRegisterData.stepProgress = 0;
		},
		setUser: (state, action: PayloadAction<User>) => {
			state.user = action.payload;
		},
		setInnInfo: (state, action: PayloadAction<InnInfo>) => {
			state.innInfo = action.payload;
		},
		setRegistrationEmail: (state, action: PayloadAction<string>) => {
			state.accumulatedRegisterData.email = action.payload;
		},
		setPreSaveLead: (state, action: PayloadAction<Omit<LeadInfoToSave, 'phone_number'>>) => {
			state.leadPreSave = action.payload;
		},
		setRegistrationRetryExpire: (state, action: PayloadAction<number | null>) => {
			state.accumulatedRegisterData.step2RetryCooldown = action.payload;
		},
		setRestoreCooldownExpire: (state, action: PayloadAction<number | null>) => {
			state.restorePassCooldown = action.payload;
		},
		setMeta: (state, action: PayloadAction<IPayloadForAction>) => {
			const key = action.payload.key
			if (key)
				state.states[key] = action.payload;
			else
				logger.warn('key ', key, 'unrecognized');
		},
		clearMeta: (state, action: PayloadAction<string>) => {
			state.states[action.payload] = { status: LoadingStatus.Never };
		},
		logout: () => {
			cookie.remove("accessToken");
			strictKickUnlikeUser();
		},
		clearSlice: () => {
			return initialState;
		},
	},
})
