import {createNamespace} from "@/core/store/actions";
import {IPayloadForAction, LoadingStatus} from "@/core/store/utils/sagas";
import {AccountDetails, RequestState, Source} from "@/services/openapi";
import {EntityIdType} from "@/utils/data/sources/cms/types";
import {bankAccountNumber, bankBikValidate} from "@/utils/yup/banks";
import i18next from "i18next";
import {yup} from '@/utils/yup/common'
import {InferType} from "yup";
import {UUID} from "node:crypto";
import {generateExecId} from "@/business/common/utils/actionPreparer";

export const namespace = 'sources';

const actionCreator = createNamespace(namespace);

export interface State {
  createAccountLinkMeta: Partial<IPayloadForAction>;
  disableSourceMeta: Partial<IPayloadForAction>;
  sourceList: Source[],
  sourceHistory: {
    id: string,
    date: string,
    filename: string,
    state: RequestState
  }[]
  openedInList: string | null,
  addSourceCtx: {
    history: EntityIdType[],
    ctx: object
  }
  // WildBerries FLOW
  wb: {
    accumulatedAuthData: {
      year: number | null,
      token: string | null,
    }
    wbCheckFileReportsMeta: Partial<IPayloadForAction>;
  }
}

export const initialState: State = {
  disableSourceMeta: { status: LoadingStatus.Never },
  createAccountLinkMeta: {
    status: LoadingStatus.Never,
  },
  sourceHistory: [],
  openedInList: null,
  sourceList: [],
  addSourceCtx: {
    history: [],
    ctx: {},
  },
  wb: {
    accumulatedAuthData: {
      year: null,
      token: null,
    },
    wbCheckFileReportsMeta: { status: LoadingStatus.Never },
  },
}

export const DirectBankRegisterSchemaValidate = yup.object().shape({
  login: yup.string()
      .required(() => i18next.t('common.errors.yup.common.required'))
      .max(255, () => i18next.t('common.errors.yup.common.incorrect')),
  password: yup.string()
      .required(() => i18next.t('common.errors.yup.common.required'))
      .max(255, () => i18next.t('common.errors.yup.common.incorrect')),
  bank_bik: bankBikValidate,
  bank_name: yup.string().required().min(1),
  account_number: bankAccountNumber,
})

export type DirectBankRegisterSchemaValidateType = InferType<typeof DirectBankRegisterSchemaValidate>

export const LoginPassSchemaValidate = yup.object({
  login: yup.string()
      .required(() => i18next.t('common.errors.yup.common.incorrect'))
      .max(255, () => i18next.t('common.errors.yup.common.incorrect')),
  password: yup.string()
      .required(() => i18next.t('common.errors.yup.common.incorrect'))
      .max(255, () => i18next.t('common.errors.yup.common.incorrect')),
  date: yup.date()
      .required(() => i18next.t('common.errors.yup.common.required')),
})

export type LoginPassSchemaValidateType = InferType<typeof LoginPassSchemaValidate>

export const sagaActions = {
  createAccountLink: actionCreator<AccountDetails>('createAccountLink'),
  createAccountIntegration: actionCreator<DirectBankRegisterSchemaValidateType>('createAccountIntegration'),
  createLoginPassOFDIntegration: actionCreator<Omit<LoginPassSchemaValidateType, 'date'> & { date: number }>('createLoginPassOFDIntegration'),
  createXLSXOFDIntegration: actionCreator<UUID>('createXLSXOFDIntegration'),
  openModal: actionCreator<{ name: string }>('openModal'),
  loadAllSources: actionCreator('loadAllSources', generateExecId('sources/loadAllSources')),
  hideSource: actionCreator<{ source_id: string, disable_date: string }>('hideSource'),
  loadHistory: actionCreator<string>('loadHistory'),
  removeHistoryItem: actionCreator<{ id: string, sourceId: string }>('removeHistoryItem'),
  checkWBFile: actionCreator('checkWBFile', generateExecId<UUID[]>()),
  wbCompleteRegistration: actionCreator('wbCompleteRegistration', generateExecId<UUID[]>()),
  wbSubmitFirstStep: actionCreator('wbSubmitFirstStep', generateExecId<{ token: string, year: number, sellExists?: boolean | null }>()),
  wbCheckTokenValidity: actionCreator('wbCheckTokenValidity', generateExecId<string>('sources/wbCheckTokenValidity')),
  yaMarketOpenAuth: actionCreator('yaMarketOpenAuth', generateExecId<{ date: number }>('sources/yaMarketAuthProcessing')),
  yaMarketCheck: actionCreator('yaMarketCheck', generateExecId('sources/yaMarketAuthCheck')),
  yaMarketRetryModal: actionCreator('yaMarketRetryModal'),
  ozonCheckFiles: actionCreator('ozonCheckFiles', generateExecId<UUID[]>('sources/ozonCheckFiles')),
  ozonSendFiles: actionCreator('ozonSendFiles', generateExecId<UUID[]>('sources/ozonSendFiles')),
  ozonCheckToken: actionCreator('ozonCheckToken', generateExecId<{ id: string, token: string }>('sources/ozonCheckToken')),
}
