import styles from "@/components/wrappers/Modals/components/AddSource/components/List/styles.module.scss";
import {Typography} from "@/components/base/Typography";
import {IconModifier, SelectActionType} from "@/utils/data/sources/cms/types";
import clsx from "clsx";
import {useAppDispatch} from "@/core/store/hooks";

export const CardShrink = (
    {name, logo, action, iconModifier}: SelectActionType,
) => {
    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch(
            action,
        );
    }

    return (
        <div
            className={clsx('d-inline-flex flex-column align-items-center gap-8 py-16 px-16', styles.fullWidthCard)}
            role='button'
            onClick={handleClick}
        >
            <img 
                src={logo}
                alt={name}
                className={clsx(
                    styles.logo48,
                    iconModifier === IconModifier.round && styles.logo48Rounded,
                )}
            />
            <Typography type='NormalTextSemibold' color='base-primary-dark' className={styles.fullWidthCardName}>
                {name}
            </Typography>
        </div>
    )
}