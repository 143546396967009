import {createNamespace} from "@/core/store/actions";
import {UUID} from "node:crypto";
import {IMetaInfoError, IMetaInfoInitial, IMetaInfoSimple, NetworkMetaMapKeysType} from "@/business/common/types";

export const namespace = 'common';

const actionCreator = createNamespace(namespace);

export enum NotificationType {
	Info,
	Loading,
	Warn,
	Success,
	Error,
}

export interface INotification {
	text: string
	type?: NotificationType
	expire?: number // Unix time
	closeable?: boolean
}


export interface State {
	counters: Record<
		'actions' | 'transactions' | 'reports' | 'settings' | 'billing',
		number | null
	>;
	notifications: Record<UUID, INotification>;
	asyncExecuteMetaMap: Partial<Record<NetworkMetaMapKeysType, IMetaInfoInitial | IMetaInfoSimple | IMetaInfoError>>;
}

export const initialState: State = {
	counters: {
		actions: null,
		transactions: null,
		reports: null,
		settings: null,
		billing: null,
	},
	notifications: {},
	asyncExecuteMetaMap: {},
}


export const sagaActions = {
	sendNotification: actionCreator<Partial<INotification> & { id?: UUID }>('sendNotification'),
	closeTwoModals: actionCreator('closeTwoModals'),
}
