import {RootState} from "@/core/store";
import {
    IMetaInfoError,
    IMetaInfoInitial,
    IMetaInfoSimple,
    initialMetaInfoValue,
    NetworkMetaMapKeysType,
} from "@/business/common/types";
import fp from "lodash/fp";


export const asyncExecMetaSelector = (key: NetworkMetaMapKeysType) =>
    (s: RootState) =>
        s.common.asyncExecuteMetaMap[key] || initialMetaInfoValue

type Substring<
    S extends string,
    T extends string,
> = S extends `${T}/${string | number}` ? T : never

export const asyncSomeExecMetaSelector = <T extends string, M = void>(
    keyPart: Substring<NetworkMetaMapKeysType, T>,
) =>
    (s: RootState) => {
        const map = s.common.asyncExecuteMetaMap;

        return fp.pick(fp.filter(fp.startsWith(`${keyPart}/`), fp.keys(map)), map) as Partial<Record<string, IMetaInfoInitial | IMetaInfoSimple<M> | IMetaInfoError<M>>>;
    }
