export const BlankLabelCompleted12 = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className='d-block'>
        <mask id="mask0_1845_5866" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="12"
              height="12">
            <rect width="12" height="12" fill='currentColor'/>
        </mask>
        <g mask="url(#mask0_1845_5866)">
            <path
                d="M5.29038 7.07308L4.12883 5.91154C4.0596 5.84231 3.97258 5.80689 3.86778 5.80529C3.76297 5.80368 3.67435 5.8391 3.60191 5.91154C3.52948 5.98397 3.49326 6.07179 3.49326 6.17499C3.49326 6.27819 3.52948 6.36601 3.60191 6.43844L4.97403 7.81055C5.06441 7.90093 5.16986 7.94613 5.29038 7.94613C5.41088 7.94613 5.51633 7.90093 5.60671 7.81055L8.38844 5.02883C8.45767 4.9596 8.49309 4.87258 8.49469 4.76778C8.4963 4.66297 8.46088 4.57435 8.38844 4.50191C8.31601 4.42948 8.22819 4.39326 8.12499 4.39326C8.02179 4.39326 7.93397 4.42948 7.86154 4.50191L5.29038 7.07308ZM6.00083 10.75C5.34386 10.75 4.72634 10.6253 4.14828 10.376C3.5702 10.1266 3.06736 9.78827 2.63975 9.36085C2.21213 8.93343 1.8736 8.43081 1.62416 7.85299C1.37472 7.27518 1.25 6.65779 1.25 6.00083C1.25 5.34386 1.37467 4.72634 1.624 4.14828C1.87333 3.5702 2.21171 3.06736 2.63913 2.63975C3.06655 2.21213 3.56917 1.8736 4.14699 1.62416C4.7248 1.37472 5.34218 1.25 5.99915 1.25C6.65612 1.25 7.27364 1.37467 7.8517 1.624C8.42978 1.87333 8.93262 2.21171 9.36023 2.63913C9.78784 3.06655 10.1264 3.56917 10.3758 4.14699C10.6253 4.7248 10.75 5.34218 10.75 5.99915C10.75 6.65612 10.6253 7.27363 10.376 7.8517C10.1266 8.42978 9.78827 8.93262 9.36085 9.36023C8.93343 9.78784 8.43081 10.1264 7.85299 10.3758C7.27518 10.6253 6.65779 10.75 6.00083 10.75ZM5.99999 9.99999C7.11666 9.99999 8.06249 9.61249 8.83749 8.83749C9.61249 8.06249 9.99999 7.11666 9.99999 5.99999C9.99999 4.88332 9.61249 3.93749 8.83749 3.16249C8.06249 2.38749 7.11666 1.99999 5.99999 1.99999C4.88332 1.99999 3.93749 2.38749 3.16249 3.16249C2.38749 3.93749 1.99999 4.88332 1.99999 5.99999C1.99999 7.11666 2.38749 8.06249 3.16249 8.83749C3.93749 9.61249 4.88332 9.99999 5.99999 9.99999Z"
                fill='currentColor'/>
        </g>
    </svg>
)