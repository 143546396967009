import {AxiosResponse} from "axios";


export const downloadFile = (
	resp: AxiosResponse,
	customFileName?: string,
) => {
	const href = URL.createObjectURL(resp.data);
	const link = document.createElement('a');

	link.href = href;

	const head = resp.headers["Content-Disposition"] || resp.headers["content-disposition"];

	const name = customFileName ? customFileName : /filename="(?<fn>(.(?<!"))*)".*/gmi.exec(head)?.groups?.fn as string

	link.setAttribute('download', name);

	document.body.appendChild(link);
	link.click();

	document.body.removeChild(link);
	URL.revokeObjectURL(href);
}