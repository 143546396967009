import {Typography} from "@/components/base/Typography";
import Link from "next/link";
import {HTMLAttributeAnchorTarget, PropsWithChildren} from "react";

import styles from './styles.module.scss';
import { VarietyType } from "../Typography/types";

export const LinkButton = (
	{
		href,
		type = 'SmallTextSemibold',
		children,
		target,
	}: PropsWithChildren<{ href: string, type?: VarietyType, target?: HTMLAttributeAnchorTarget, }>,
) => {
	const isInternal = href && (href.startsWith('/') || href.startsWith('#') || href.startsWith('?'));

	const realTarget = target
		? target
		: isInternal ? '_self' : '_blank';

	return (
		<Typography
			className={styles.base}
			as={Link}
			additionalProps={{href, target: realTarget}}
			type={type}
			color='brand-primary'
		>
			{children}
		</Typography>
	)
}
