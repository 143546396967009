import {CreateTransactionRecord} from "@/components/wrappers/Modals/components/CreateTransactionRecord";
import {ConfirmSubmitReport} from "@/components/wrappers/Modals/components/ConfirmSubmitReport";
import {CreatePaymentOrder} from "@/components/wrappers/Modals/components/CreatePaymentOrder";
import {ENSAnalyzeRequest} from "@/components/wrappers/Modals/components/ENSAnalyzeRequest";
import {CreateTransactionRecordExtend} from "./components/CreateTransactionRecordExtend";
import {DownloadKudir} from "@/components/wrappers/Modals/components/DownloadKudir";
import {AddAccount} from "@/components/wrappers/Modals/components/AddAccount";
import {AddSource} from "@/components/wrappers/Modals/components/AddSource";
import {ActionsNotifications} from "./components/ActionsNotifications";
import {RemoveTransaction} from "./components/RemoveTransaction";
import {CloseAttention} from "@/components/wrappers/Modals/components/CloseAttention";
import {ConfirmRegConditions} from "@/components/wrappers/Modals/components/ConfirmRegConditions";
import {AtFirstChangePass} from "@/components/wrappers/Modals/components/AtFirstChangePass";
import {OffSource} from "@/components/wrappers/Modals/components/OffSource";
import {SourceHistory} from "@/components/wrappers/Modals/components/SourceHistory";
import {LogOut} from "@/components/wrappers/Modals/components/LogOut";
import {EditTaxSystem} from "@/components/wrappers/Modals/components/EditTaxSystem";
import {ChangePass} from "@/components/wrappers/Modals/components/ChangePass";
import {GoodbyeService} from "@/components/wrappers/Modals/components/GoodbyeService";

export const modalWrapperPortalId = 'modalsHere';

export interface IModalProps {
    currentId: number;
}

export const ModalTypes = {
    ActionsNotifications: {
        Component: ActionsNotifications,
        eraseOnExit: [],
    },
    ENSAnalyzeRequest: {
        Component: ENSAnalyzeRequest,
        eraseOnExit: [],
    },
    CreatePaymentOrder: {
        Component: CreatePaymentOrder,
        eraseOnExit: ['amount'],
    },
    AddAccount: {
        Component: AddAccount,
        eraseOnExit: [],
    },
    CreateTransactionRecord: {
        Component: CreateTransactionRecord,
        eraseOnExit: ['taxPeriod'],
    },
    CreateTransactionRecordExtend: {
        Component: CreateTransactionRecordExtend,
        eraseOnExit: ['taxPeriod'],
    },
    ConfirmSubmitReport: {
        Component: ConfirmSubmitReport,
        eraseOnExit: [],
    },
    RemoveTransaction: {
        Component: RemoveTransaction,
        eraseOnExit: [],
    },
    DownloadKudir: {
        Component: DownloadKudir,
        eraseOnExit: [],
    },
    AddSource: {
        Component: AddSource,
        eraseOnExit: [],
    },
    CloseAttention: {
        Component: CloseAttention,
        eraseOnExit: [],
    },
    ConfirmRegConditions: {
        Component: ConfirmRegConditions,
        eraseOnExit: [],
    },
    AtFirstChangePass: {
        Component: AtFirstChangePass,
        eraseOnExit: [],
    },
    OffSource: {
        Component: OffSource,
        eraseOnExit: [],
    },
    SourceHistory: {
        Component: SourceHistory,
        eraseOnExit: [],
    },
    LogOut: {
        Component: LogOut,
        eraseOnExit: [],
    },
    EditTaxSystem: {
        Component: EditTaxSystem,
        eraseOnExit: [],
    },
    ChangePass: {
        Component: ChangePass,
        eraseOnExit: [],
    },
    GoodbyeService: {
        Component: GoodbyeService,
        eraseOnExit: [],
    },
}

export type ModalTypesKey = keyof typeof ModalTypes;

export const modalKeys = Object.keys(ModalTypes) as ModalTypesKey[];
