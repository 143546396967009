import {auth} from "./auth";
import {banners} from "./banners";
import {common} from "./common";
import {actions} from "./actions";
import {users} from "./users";
import {taxes} from "./taxes";
import {references} from "./references";
import {sources} from "./sources";
import {transactions} from "./transactions";


export const business = {
    common,
    auth,
    actions,
    banners,
    users,
    taxes,
    references,
    sources,
    transactions,
}